import {Reducer} from "redux";
import {ActionTypes, KnownHotelsSearchAction} from "./actions";
import {IHotelSearchModel} from "../../api/hotelSearch";

export interface IHotelsSearchState {
    isLoading: boolean;
    hotels: IHotelSearchModel[];
    error?: Error;
}

const initialState = {
    isLoading: false,
    error: null,
    hotels: null,
} as IHotelsSearchState;

export const reducer: Reducer<IHotelsSearchState> = (state: IHotelsSearchState = initialState, action: KnownHotelsSearchAction) => {
    switch (action.type) {
        case (ActionTypes.SEARCH_REQUEST):
            return {
                ...state,
                isLoading: true
            };

        case (ActionTypes.SEARCH_ERROR):
            return {
                ...state,
                isLoading: false,
                error: action.error,
                hotels: []
            };

        case (ActionTypes.SEARCH_SUCCESS):
            return {
                ...state,
                isLoading: false,
                hotels: action.response
            };

        default:
            return state;
    }
};
