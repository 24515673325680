import styles from "../BookingMakeOrder.scss";
import {Gapped} from "@skbkontur/react-ui";
import {useTranslation} from "@skbkontur/i18n";
import cn from "classnames";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import {PaymentContext} from "../../../providers/Payment/PaymentContext";
import {WidgetClientGlobalStyles} from "../../../widget/WidgetClientGlobalStyles";
import WidgetStore from "../../../widget/WidgetStore";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";

export const TBankSbpMobilePayment = () => {
    const {paymentUrl} = React.useContext(PaymentContext);

    const {t} = useTranslation(TranslationNamespaces.BookingModule);
    const buttonWidgetContainerClass = cn({[WidgetClientGlobalStyles.WidgetBookingFormButtonContainer]: WidgetStore.isWidget()});

    const handlePay = () => {
        document.location.href = paymentUrl;
    };

    return (
        <Gapped gap={30} vertical>
            <ul className={styles.list}>
                <li>{t("tBankMobileActions.goToPay")}</li>
                <li>{t("tBankMobileActions.openOrSelectApp")}</li>
                <li>{t("tBankMobileActions.confirm")}</li>
            </ul>
            <div className={buttonWidgetContainerClass}>
                <PrimaryButton onClick={handlePay} data-tid="PayButton">
                    {t("goToPay")}
                </PrimaryButton>
            </div>
        </Gapped>
    );
};

TBankSbpMobilePayment.displayName = "TBankSbpMobilePayment";
