import {THEME_2022_UPDATE_2024, ThemeFactory} from "@skbkontur/react-ui";

const linkColor = "#1874CF";

const HOTEL_THEME = ThemeFactory.create({
    linkColor,
    linkHoverColor: linkColor,
    linkLineBorderBottomStyle: "none",
    linkLineHoverBorderBottomStyle: "solid",
    btnLinkHoverTextDecoration: "underline",
    dateSelectLinkColor: "#222222"
}, THEME_2022_UPDATE_2024);

export default HOTEL_THEME;
