import {IImage, IImageMeta} from "@skbkontur/hotel-data/image";

export class ImageApi {
    static getImagesFromMetas = (imageMetas: IImageMeta[]): IImage[] => (
        imageMetas.map(image => ({
            url: image.photoUrl,
            id: image.id
        }))
    );

    static getThumbnailsFromMetas = (imageMetas: IImageMeta[]): IImage[] => (
        imageMetas.map(image => ({
            url: image.thumbnailUrl,
            id: image.id
        }))
    );

    static sortMetas = (imageMetas: IImageMeta[]): IImageMeta[] => (
        imageMetas.sort((a, b) => a.order - b.order)
    );
}
