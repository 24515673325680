import {People1Icon16Light} from "@skbkontur/icons/People1Icon16Light";
import {People1PlusIcon16Light} from "@skbkontur/icons/People1PlusIcon16Light";
import styles from "./Accommodation.scss";

interface IAccommodationProps {
    mainAccommodation: number;
    additionalAccommodation: number;
}

const Accommodation = (props: IAccommodationProps) => {
    const {mainAccommodation, additionalAccommodation} = props;

    return (
        <div className={styles.root}>
            {mainAccommodation}
            <People1Icon16Light />
            {!!additionalAccommodation && (
                <>
                    <span className={styles.plus}>+</span>
                    {additionalAccommodation}
                    <People1PlusIcon16Light />
                </>
            )}
        </div>
    );
};
Accommodation.displayName = "Accommodation";
export default Accommodation;
