import {FullDateDayFirstString} from "../Date";

type DayNumberType = number;

export type CalendarDataMapType = Record<string, CalendarMonthDataMapType>;

export type CalendarMonthDataMapType = Record<DayNumberType, ICalendarDayData>;

export interface ICalendarDayData {
    description: string;
}

export interface ICalendarDatesPeriod {
    fromDate: FullDateDayFirstString;
    toDate: FullDateDayFirstString;
}

export enum CalendarMode {
    TwoMonthsRegular,
    OneMonthOnHotelPage,
    TwoMonthsWidget,
    OneMonthWidget
}

export enum CalendarDayOfWeek {
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Sunday
}

export enum CalendarMonthNames {
    January,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December
}
