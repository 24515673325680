import {Reducer} from "redux";

import {ActionTypes, KnownErrorAction} from "./actions";

export interface IGlobalError {
    ExceptionNumber: string;
    hotelNotFound: boolean;
    widgetSettingsError: boolean;
}

const defaultState: IGlobalError = {
    ExceptionNumber: null,
    hotelNotFound: false,
    widgetSettingsError: false
};

export const reducer: Reducer<IGlobalError> = (state: IGlobalError = defaultState, action: KnownErrorAction) => {
    switch (action.type) {
        case ActionTypes.GET_GLOBAL_ERROR:
            return {
                ...state,
                ExceptionNumber: action.data.ExceptionNumber
            };
        case ActionTypes.CLEAR_GLOBAL_ERROR:
            return {
                ...state,
                ExceptionNumber: null
            };
        case ActionTypes.SET_HOTEL_NOT_FOUND:
            return {
                ...state,
                hotelNotFound: true
            };
        case ActionTypes.SET_WIDGET_SETTINGS_ERROR:
            return {
                ...state,
                widgetSettingsError: true
            };
        default:
            return state;
    }
};
