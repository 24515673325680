import {text, ValidationInfo, ValidationWrapper} from "@skbkontur/react-ui-validations";
import styles from "./OrderFormRow.scss";

interface IOrderFormRowProps {
    caption: React.ReactNode;
    children: JSX.Element;
    width?: number | string;
    required?: boolean;
    validationInfo?: ValidationInfo;
}

const OrderFormRow = (props: IOrderFormRowProps) => {
    const {caption, required = false, width = "100%", children, validationInfo} = props;

    return (
        <div className={styles.container}>
            <div className={styles.caption}>
                {caption}
                {required && <span className={styles.required}> *</span>}
            </div>
            <div className={styles.wrapper} style={{width}}>
                <ValidationWrapper validationInfo={validationInfo} renderMessage={text("bottom")}>
                    {children}
                </ValidationWrapper>
            </div>
        </div>
    );
};
OrderFormRow.displayName = "OrderFormRow";
export default OrderFormRow;
