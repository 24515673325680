import WidgetBookingControl from "../WidgetBookingControl/WidgetBookingControl";
import WidgetRoomList from "../WidgetRoomList/WidgetRoomList";
import styles from "../../WidgetApp.scss";
import CssHelper from "../../../helpers/CssHelper";
import WidgetStore from "../../WidgetStore";
import {IAppState} from "../../../store/AppState";
import {useSelector} from "react-redux";
import AllBookingProviders from "../../../providers/AllBookingProviders";
import {RoomCategorySearchParamsProvider, HourlyObjectsSearchParamsProvider} from "../../../providers/SearchParams/SearchParamsProvider";
import {IWidget, WidgetType} from "../../settings/WidgetSettingsTypes";
import WidgetCalendar from "../WidgetCalendar/WidgetCalendar";
import {captureSentryError, SentryErrorType} from "@skbkontur/hotel-sentry";
import WidgetHourlyObjectsList from "../WidgetHourlyObjectsList/WidgetHourlyObjectsList";
import WidgetAvailabilityButtonForMobileDevices from "../WidgetAvailabilityButtonForMobileDevices/WidgetAvailabilityButtonForMobileDevices";
import {useInitVkBridge} from "../../../hooks/useInitVkBridge";
import {useIframeEventAnalytics} from "../../../hooks/useIframeEventAnalytics";
import {useMount} from "@skbkontur/hotel-hooks/react";

let needSetReactUIPortalStyle = true;

const Widget = () => {
    const {hotelId} = WidgetStore.settings;

    const widgetSettingsError = useSelector((state: IAppState) => state.globalError?.widgetSettingsError);

    useInitVkBridge();
    useIframeEventAnalytics();

    useMount(() => {
        if (needSetReactUIPortalStyle) {
            CssHelper.copyCssRule(styles.container, "react-ui");
            needSetReactUIPortalStyle = false;
        }
    });

    const getWidget = (widget: IWidget): JSX.Element => {
        const {id, type, ref} = widget;

        switch (type) {
            case WidgetType.VerticalBookingForm:
            case WidgetType.HorizontalBookingForm: {
                return (
                    <WidgetBookingControl
                        ref={ref}
                        hotelId={hotelId}
                        widget={widget}
                        key={id}
                    />
                );
            }
            case WidgetType.RoomsList: {
                return (
                    <WidgetRoomList
                        ref={ref}
                        hotelId={hotelId}
                        widget={widget}
                        key={id}
                    />
                );
            }
            case WidgetType.HourlyObjectsList: {
                return (
                    <WidgetHourlyObjectsList
                        ref={ref}
                        hotelId={hotelId}
                        widget={widget}
                        key={id}
                    />
                );
            }
            case WidgetType.VerticalAvailabilityCalendar:
            case WidgetType.HorizontalAvailabilityCalendar: {
                return (
                    <WidgetCalendar
                        ref={ref}
                        hotelId={hotelId}
                        widget={widget}
                        key={id}
                    />
                );
            }
            case WidgetType.ShowCheckAvailabilityButtonForMobileDevices: {
                return (
                    <WidgetAvailabilityButtonForMobileDevices />
                );
            }
            default: {
                captureSentryError(
                    `Incorrect widget type id=${id}, type=${type as WidgetType}`,
                    SentryErrorType.SwitchCase
                );
                return null;
            }
        }
    };

    if (widgetSettingsError)
        return null;

    return (
        <RoomCategorySearchParamsProvider>
            <HourlyObjectsSearchParamsProvider>
                <AllBookingProviders>
                    {WidgetStore.settings.widgets.map(getWidget)}
                </AllBookingProviders>
            </HourlyObjectsSearchParamsProvider>
        </RoomCategorySearchParamsProvider>
    );
};
Widget.displayName = "Widget";
export default Widget;
