import {CalendarContext} from "./CalendarContext";
import {useMemoObject} from "../../common/hooks/useMemoObject";
import {CalendarMode} from "../../data/Calendar/Calendar";

interface ICalendarProviderProps {
    calendarMode: CalendarMode;
}

const CalendarProvider = (props: React.PropsWithChildren<ICalendarProviderProps>) => {
    const {children, calendarMode} = props;

    const isAlwaysOneMonth = calendarMode === CalendarMode.OneMonthWidget || calendarMode === CalendarMode.OneMonthOnHotelPage;
    const isTwoMonthsWidget = calendarMode === CalendarMode.TwoMonthsWidget;
    const isTwoMonthsRegular = calendarMode === CalendarMode.TwoMonthsRegular;

    return (
        <CalendarContext.Provider value={useMemoObject({
            calendarMode,
            isAlwaysOneMonth,
            isTwoMonthsWidget,
            isTwoMonthsRegular
        })}
        >
            {children}
        </CalendarContext.Provider>
    );
};
CalendarProvider.displayName = "CalendarProvider";
export default CalendarProvider;
