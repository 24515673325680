import {IWidgetHooks} from "./WidgetSettingsHooks";

export interface ISingleWidgetInitialSettings {
    id: string;
    type: WidgetType;
    form: IWidgetSettingsContainer;
    roomsList: IWidgetSettingsContainer;
    hooks: IWidgetHooks;
}

export interface IMultipleWidgetInitialSettings {
    hotelId: string;
    version: string;
    baseUrl: string;
    hooks: IWidgetHooks;
}

export interface IWidgetInitialSettings extends ISingleWidgetInitialSettings, IMultipleWidgetInitialSettings {}

export interface IWidgetSettings {
    hotelId: string;
    version: string;
    hooks: IWidgetHooks;
    widgets: IWidget[];
}

export interface IWidget {
    id: string;
    type: WidgetType;
    appearance: IWidgetSettingsContainer;
    ref: IWidgetRef;
}

export interface IWidgetSettingsContainer {
    container: WidgetContainer;
    insert?: WidgetInsertType;
}

export interface IWidgetRefresh {
    refresh: () => void;
}

export type IWidgetRef = React.RefObject<IWidgetRefresh>;

export enum WidgetType {
    VerticalBookingForm = "verticalBlock",
    HorizontalBookingForm = "horizontalBlock",
    RoomsList = "roomsList",
    HourlyObjectsList = "hourlyObjectsList",
    HorizontalAvailabilityCalendar = "horizontalAvailabilityCalendar",
    VerticalAvailabilityCalendar = "verticalAvailabilityCalendar",
    ShowCheckAvailabilityButtonForMobileDevices = "showCheckAvailabilityButtonForMobileDevices"
}

export enum WidgetInsertType {
    Before = "before",
    After = "after",
    Replace = "replace"
}

export type WidgetContainer = string | HTMLElement;
