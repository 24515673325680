import {FullDateDayFirstString} from "../Date";
import DateHelper from "../../helpers/DateHelper";
import {DateFormats} from "../../types/DateFormats";

const LAST_DAY_OF_WEEK_INDEX = 6;

export class CalendarHelper {

    static getDaysInMonthRange = (date: FullDateDayFirstString): number[] => {
        const daysInMonth = DateHelper.getDaysCountInMonth(date, DateFormats.FullDateDayFirst);
        return Array.from(Array(daysInMonth), (_, i) => i + 1);
    };

    static getDaysInMonthArrayWithSpaces = (year: number, monthFromZero: number): number[] => {
        const firstDayOfMonth = DateHelper.getFirstMonthDate(
            DateHelper.getDate(year, monthFromZero, 1, DateFormats.FullDateDayFirst),
            DateFormats.FullDateDayFirst
        );
        const daysInMonthArray = this.getDaysInMonthRange(firstDayOfMonth);

        const dayOfWeekFromSunday = DateHelper.getWeekDay(firstDayOfMonth, DateFormats.FullDateDayFirst);
        const firstDayOfWeekOfMonthFromMonday = dayOfWeekFromSunday === 0 ? LAST_DAY_OF_WEEK_INDEX : dayOfWeekFromSunday - 1;
        const firstSpaces = Array(firstDayOfWeekOfMonthFromMonday).fill(null);

        return [...firstSpaces, ...daysInMonthArray];
    }

    static getMonthsForSlider = (
        currentDate: FullDateDayFirstString,
        monthsToDisplay: number
    ): FullDateDayFirstString[] => [
        DateHelper.addMonthsToDate(-1, currentDate, DateFormats.FullDateDayFirst),
        currentDate,
        DateHelper.addMonthsToDate(1, currentDate, DateFormats.FullDateDayFirst),
        ...monthsToDisplay === 2
            ? [DateHelper.addMonthsToDate(2, currentDate, DateFormats.FullDateDayFirst)]
            : []
    ];
}
