import {AccommodationsContext, IAccommodationUpdateParams} from "./AccommodationsContext";
import {SelectedAccommodationsHelper} from "./helpers/SelectedAccommodationsHelper";
import {
    IAccommodationsAvailabilityMap,
    IAccommodationsMapParams,
    IAccommodationsPricesMap,
    IAccommodationsVariantsMap,
    ISelectedAccommodationsMap
} from "./AccommodationsMaps";
import {IAppState} from "../../store/AppState";
import {useSelector} from "react-redux";
import {AccommodationsAvailabilityHelper} from "./helpers/AccommodationsAvailabilityHelper";
import {AccommodationsPricesHelper} from "./helpers/AccommodationsPricesHelper";
import {AccommodationsVariantsHelper} from "./helpers/AccommodationsVariantsHelper";
import {IRoomCategoryAccommodation} from "../../data/Accommodation";
import {AccommodationRestrictionsHelper} from "./helpers/AccommodationRestrictionsHelper";
import {useMemoObject} from "../../common/hooks/useMemoObject";

interface IAccommodationsState {
    accommodations: IRoomCategoryAccommodation[];
    selectedAccommodationsMap: ISelectedAccommodationsMap;
    accommodationsAvailabilityMap: IAccommodationsAvailabilityMap;
    accommodationsPricesMap: IAccommodationsPricesMap;
    accommodationsVariantsMap: IAccommodationsVariantsMap;
}

const initialSelectedMap = {} as ISelectedAccommodationsMap;
const initialState = {
    selectedAccommodationsMap: initialSelectedMap,
    accommodations: []
} as IAccommodationsState;

const AccommodationsProvider = (props: React.PropsWithChildren<object>) => {
    const {children} = props;

    const {accommodations: reduxAccommodations} = useSelector((state: IAppState) => state.accommodations);
    const {roomCategories} = useSelector((state: IAppState) => state.roomCategories);

    const [state, setState] = React.useState<IAccommodationsState>(initialState);
    const {
        accommodations,
        selectedAccommodationsMap,
        accommodationsAvailabilityMap,
        accommodationsVariantsMap,
        accommodationsPricesMap
    } = state;

    React.useEffect(() => {
        if (roomCategories.length) {
            const accommodations = AccommodationRestrictionsHelper.extractAccommodationsWithoutRestrictions(reduxAccommodations);
            setState({
                accommodations,
                selectedAccommodationsMap: initialSelectedMap,
                accommodationsAvailabilityMap: accommodations
                    ? AccommodationsAvailabilityHelper.createAvailabilityMap(accommodations)
                    : {},
                accommodationsPricesMap: accommodations
                    ? AccommodationsPricesHelper.createPricesMap(accommodations)
                    : {},
                accommodationsVariantsMap: accommodations
                    ? AccommodationsVariantsHelper.createVariantsMap(accommodations, roomCategories)
                    : {}
            });
        }
    }, [reduxAccommodations, roomCategories]);

    const update = React.useCallback((roomCategoryId: string, updateFunc: (map: ISelectedAccommodationsMap) => ISelectedAccommodationsMap) => {
        setState(state => {
            const {selectedAccommodationsMap, accommodationsAvailabilityMap} = state;
            const updatedSelectedAccommodationsMap = updateFunc(selectedAccommodationsMap);
            const updatedAccommodationsAvailabilityMap = AccommodationsAvailabilityHelper.updateAvailabilityMap({
                selectedAccommodationsMap: updatedSelectedAccommodationsMap,
                accommodationsAvailabilityMap,
                roomCategoryId
            });
            return {
                ...state,
                selectedAccommodationsMap: updatedSelectedAccommodationsMap,
                accommodationsAvailabilityMap: updatedAccommodationsAvailabilityMap
            };
        });
    }, []);

    const addAccommodation = React.useCallback((params: IAccommodationUpdateParams) => {
        const {roomCategoryId} = params;
        update(roomCategoryId, map => SelectedAccommodationsHelper.addAccommodation(map, params));
    }, [update]);

    const removeAccommodation = React.useCallback((params: IAccommodationsMapParams) => {
        const {roomCategoryId} = params;
        update(roomCategoryId, map => SelectedAccommodationsHelper.removeAccommodation(map, params));
    }, [update]);

    const updateAccommodation = React.useCallback((params: IAccommodationUpdateParams) => {
        const {roomCategoryId} = params;
        update(roomCategoryId, map => SelectedAccommodationsHelper.updateAccommodation(map, params));
    }, [update]);

    return (
        <AccommodationsContext.Provider value={useMemoObject({
            allAccommodations: reduxAccommodations,
            accommodations,
            selectedAccommodationsMap,
            accommodationsAvailabilityMap,

            accommodationsPricesMap,
            accommodationsVariantsMap,

            addAccommodation,
            removeAccommodation,
            updateAccommodation
        })}>
            {children}
        </AccommodationsContext.Provider>
    );
};
AccommodationsProvider.displayName = "AccommodationsProvider";
export default AccommodationsProvider;
