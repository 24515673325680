import {KonturI18NContext} from "@skbkontur/i18n";
import YandexCheckoutRus from "../../images/YandexCheckoutRus.svg";
import YandexCheckoutEng from "../../images/YandexCheckoutEng.svg";
import styles from "../../providers/Booking/BookingLightbox/Order/BookingLightboxOrder.scss";
import WidgetStore from "../../widget/WidgetStore";

export const YandexCheckoutLogo = () => {
    const {locale} = React.useContext(KonturI18NContext);
    const imageSrc = locale === "ru" ? YandexCheckoutRus : YandexCheckoutEng;
    return (
        <img className={styles.icon} src={WidgetStore.getUrlWithBase(imageSrc)}/>
    );
};
YandexCheckoutLogo.displayName = "YandexCheckoutLogo";
