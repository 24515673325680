import {StringUtils} from "../common/helpers/StringUtils";

interface IParam {
    name?: string,
    value?: string | number | boolean
}

export class UrlBuilder {
    private path: string;
    private params: IParam[];

    constructor(path: string) {
        this.path = path;
        this.params = [];
    }

    getHref(needUnsafe: boolean, needFiltered: boolean): string {
        const queryString: string[] = [];
        const params = (!needFiltered)
            ? this.params
            : this.params.filter(p => !StringUtils.isNullOrEmpty(p.value as string));

        const paramsLength = params.length;
        if (paramsLength < 1) {
            return this.path;
        }

        this.path += (this.path.indexOf("?") > -1) ? "&" : "?";

        for (let i = 0; i < paramsLength; i++) {
            const param = params[i];
            queryString.push(param.name + "=" + ((needUnsafe) ? param.value : encodeURIComponent(param.value)));
        }
        return this.path + queryString.join("&");
    }

    getHrefUnsafe(): string {
        return this.getHref(true, false);
    }

    addParametersObject(parametersObj: object): void {
        for (const i in parametersObj) {
            if (i in parametersObj) {
                this.params.push({name: i, value: parametersObj[i]});
            }
        }
    }

    public static makeUnsafeHref(baseUrl: string, data?: object) {
        const urlBuilder = new UrlBuilder(baseUrl);
        if (data)
            urlBuilder.addParametersObject(data);
        return urlBuilder.getHrefUnsafe();
    }
}
