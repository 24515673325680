import {IHotelFeature} from "../../data/HotelFeature";
import {CustomThunkAction} from "../ThunkAction";

export enum HotelFeaturesActionTypes {
    GET_REQUEST = "hotelFeatures/GET_REQUEST",
    GET_SUCCESS = "hotelFeatures/GET_SUCCESS",
    GET_ERROR = "hotelFeatures/GET_ERROR",
}

interface IHotelFeaturesRequestAction {
    type: HotelFeaturesActionTypes.GET_REQUEST;
}

interface IHotelFeaturesSuccessAction {
    type: HotelFeaturesActionTypes.GET_SUCCESS;
    response: IHotelFeature[];
}

interface IHotelFeaturesErrorAction {
    type: HotelFeaturesActionTypes.GET_ERROR;
    error: Error;
}

export type KnownHotelFeaturesAction =
    | IHotelFeaturesRequestAction
    | IHotelFeaturesSuccessAction
    | IHotelFeaturesErrorAction;

export const getHotelFeatures = (): CustomThunkAction<IHotelFeature[]> => (
    // @ts-expect-error Use new Redux instead of fixing this types
    (dispatch, _getState, {dailyApi}) => (
        dispatch({
            type: {
                REQUEST: HotelFeaturesActionTypes.GET_REQUEST,
                SUCCESS: HotelFeaturesActionTypes.GET_SUCCESS,
                FAILURE: HotelFeaturesActionTypes.GET_ERROR,
            },
            asyncAction: dailyApi.getFeatures(),
        })
    )
);
