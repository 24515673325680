import scroll from "scroll";
// @ts-expect-error TODO Update component or use native methods
import ease from "ease-component";
import {FlippedResizerAnimation, IFlippedResizerAnimationSettings} from "./FlippedResizerAnimation";
import raf from "raf-throttle";

enum FlippedResizeScrollPolitic {
    CompensateAlways = "Compensation",
    CompensateOnSqueeze = "CompensateOnSqueeze",
    Custom = "Custom"
}

export interface IFlippedResizerScrollTopAnimationSettings extends IFlippedResizerAnimationSettings {
    /** Element that should be scrolled */
    scrollElement: HTMLElement;
    /** Explicitly setup scrollTop to animate, otherwise scrollTop animate only on squeeze */
    top?: number;
    /** In what kind od cases scroll should happen */
    politic?: FlippedResizeScrollPolitic;
}

export class FlippedResizerScrollTopAnimation extends FlippedResizerAnimation<IFlippedResizerScrollTopAnimationSettings> {

    protected get settings(): IFlippedResizerScrollTopAnimationSettings {
        const {top, politic, ...settings} = this?.getSettings() || {} as IFlippedResizerScrollTopAnimationSettings;
        return {
            top,
            politic: top === null || top === undefined
                ? politic
                : FlippedResizeScrollPolitic.Custom,
            ...settings
        };
    }

    private cancel: () => void;

    public getSettings: () => IFlippedResizerScrollTopAnimationSettings;

    private onScroll = raf(() => {
        if (this.timeout !== null)
            return;
        this.updateStartValue();
    });

    constructor(private readonly scrollElement: HTMLElement) {
        super();
        this.updateStartValue();
    }

    public subscribe = () => this.scrollElement.addEventListener("scroll", this.onScroll);
    public unsubscribe = () => this.scrollElement.removeEventListener("scroll", this.onScroll);

    protected animationProcess = (duration: number): boolean => {
        if (this.scrollElement) {
            let needAnimateScroll;
            switch (this.settings.politic) {
                case FlippedResizeScrollPolitic.Custom:
                case FlippedResizeScrollPolitic.CompensateAlways:
                    needAnimateScroll = true;
                    break;
                case FlippedResizeScrollPolitic.CompensateOnSqueeze:
                    needAnimateScroll = this.startValue > this.endValue;
                    break;
            }
            if (needAnimateScroll) {
                this.scrollElement.scrollTop = this.startValue;
                this.cancel = scroll.top(this.scrollElement, this.endValue, {ease: ease.inOutCube, duration});
                return true;
            }
        }
        return false;
    };

    protected setFinishValues = (): void => {
        this.cancel = null;
    };

    protected stop = (): void => {
        this.cancel?.();
    };

    protected updateStartValue = (): void => {
        this.startValue = this.scrollElement?.scrollTop || 0;
    };

    protected updateEndValue = (): void => {
        if (this.scrollElement) {
            const {politic, top} = this.settings;
            switch (politic) {
                case FlippedResizeScrollPolitic.Custom:
                    this.endValue = top;
                    break;
                case FlippedResizeScrollPolitic.CompensateAlways:
                case FlippedResizeScrollPolitic.CompensateOnSqueeze:
                    this.endValue = this.scrollElement.scrollTop;
            }
        }
    };
}
