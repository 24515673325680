import {useSelector} from "react-redux";
import {PaymentSystem} from "../data/Payment";
import {IAppState} from "../store/AppState";

export const useGetPaymentSourceSettingsId = (): (paymentSource: PaymentSystem, isCashback?: boolean) => string => {
    const terminals = useSelector((state: IAppState) => state.paymentTerminals.terminals);

    return React.useCallback((paymentSource: PaymentSystem, isCashback = false) => (
        terminals.find(st => st.isCashback === isCashback && st.isAvailable && st.paymentSource === paymentSource)
            ?.id
        ), [terminals]);
};
