import cn from "classnames";
import {IBookingAccommodation} from "../../../../../../data/Booking";
import BookingAccommodationsOccupancyItem from "./BookingAccommodationsOccupancyItem";
import {Gapped} from "@skbkontur/react-ui";
import {FlipperHelper} from "../../../../../../common/components/Flipper/FlipperHelper";
import FlippedElement from "../../../../../../common/components/Flipper/Element/FlippedElement";
import styles from "./BookingAccommodationsOccupancy.scss";
import listStyles from "../BookingAccommodationsList.scss";
import FlippedResizer from "../../../../../../common/components/Flipper/Resizer/FlippedResizer";
import {BookingLightboxFlipperHelper} from "../../../Flipper/BookingLightboxFlipperHelper";
import {IOccupancy} from "../../../../../../data/Accommodation";
import {ICurrency} from "../../../../../../types/Currency";

interface IBookingAccommodationsOccupancyProps extends IOccupancy {
    roomCategoryId: string;
    accommodations: IBookingAccommodation[];
    cost: ICurrency;
    disabled: boolean;
}

const BookingAccommodationsOccupancy = (props: IBookingAccommodationsOccupancyProps) => {
    const {roomCategoryId, mainOccupancy, additionalOccupancy, accommodations, cost, disabled} = props;

    const accommodationsCount = accommodations.length || 0;
    const appearClassName = accommodationsCount > 1 ? styles.fadeTranslateYIn : styles.appearIn;

    return (
        <div className={cn(styles.byOccupancyList, {[listStyles.disabled]: disabled})}>
            <FlippedResizer
                flipId={"OccupancyItemsList"}
                shouldFlip={BookingLightboxFlipperHelper.shouldFlipAccommodationsList(roomCategoryId, mainOccupancy, additionalOccupancy)}
            >
                <Gapped gap={10} vertical>
                    {accommodations.map((item, index) => (
                        <FlippedElement
                            key={item.renderKey}
                            flipId={item.renderKey}
                            shouldFlip={BookingLightboxFlipperHelper.shouldFlipAccommodationItem(item.renderKey)}
                            onAppear={FlipperHelper.onAppear(appearClassName, 300)}
                            withDiv
                        >
                            <BookingAccommodationsOccupancyItem
                                index={index}
                                mainOccupancy={mainOccupancy}
                                additionalOccupancy={additionalOccupancy}
                                accommodation={item}
                                cost={cost}
                                disabled={disabled}
                            />
                        </FlippedElement>
                    ))}
                </Gapped>
            </FlippedResizer>
        </div>
    );
};
BookingAccommodationsOccupancy.displayName = "BookingAccommodationsOccupancy";
export default React.memo(BookingAccommodationsOccupancy);
