import {WidgetSettingsNormalizer} from "./WidgetSettingsNormalizer";
import {
    IMultipleWidgetInitialSettings,
    ISingleWidgetInitialSettings,
    IWidgetSettingsContainer,
    WidgetInsertType,
    WidgetType
} from "./WidgetSettingsTypes";
import {WidgetSettingsValidator} from "./WidgetSettingsValidator";
import {IWidgetHooks} from "./WidgetSettingsHooks";
import WidgetStore from "../WidgetStore";

export class WidgetSettingsInitializer {
    validator: WidgetSettingsValidator = new WidgetSettingsValidator();

    initializeSingle = (initial: ISingleWidgetInitialSettings) => {
        const {id, hooks, type, form, roomsList} = initial;

        WidgetStore.addWidgetSettings({
            hotelId: id,
            version: null,
            widgets: [],
            hooks: this.initializeHooks(hooks)
        });

        if (this.validator.isValidType(type) && form) {
            this.addWidgetItem(id, type, form);
        }

        if (roomsList) {
            this.addWidgetItem(id, WidgetType.RoomsList, roomsList);
        }

        // eslint-disable-next-line no-console
        console.warn("You are using the deprecated version of widget settings." +
            "Please generate a new version of the widget settings in the hotel website settings.");
    };

    initializeMultiple = (initial: IMultipleWidgetInitialSettings) => {
        const {hotelId, version, hooks} = initial;

        WidgetStore.addWidgetSettings({
            hotelId,
            version,
            widgets: [],
            hooks: this.initializeHooks(hooks)
        });
    };

    private initializeHooks = (hooks: IWidgetHooks) => ({
        onError: this.makeHookSafety(hooks?.onError),
        onBooking: this.makeHookSafety(hooks?.onBooking),
        onHourlyBooking: this.makeHookSafety(hooks?.onHourlyBooking),
        onInit: this.makeHookSafety(hooks?.onInit),
    });

    addWidgetItem = (hotelId: string, type: WidgetType, container: IWidgetSettingsContainer): string => {
        const appearance = this.initializeContainer(container, type);

        if (!appearance) return;

        return WidgetStore.addWidget(type, appearance);
    };

    /* eslint-disable  @typescript-eslint/no-explicit-any *//* Because of common hook */
    private makeHookSafety = <THook extends (...args: any) => void>(hook: THook): THook => (
        (...args) => {
            try {
                return hook?.(...args);
            } catch (error) {
                // error intended to a client, so there is no need for Sentry
                /* eslint-disable-next-line no-console */
                console.error(`error with widget initialization hook: ${JSON.stringify(error)}`);
            }
        }
    ) as THook;

    private initializeContainer = (
        initialContainer: IWidgetSettingsContainer,
        type: WidgetType
    ): IWidgetSettingsContainer => {
        if (this.validator.isValidContainer(initialContainer, type)) {
            return {
                insert: initialContainer.insert
                    ? WidgetSettingsNormalizer.normalizeEnum(initialContainer.insert) as WidgetInsertType
                    : WidgetInsertType.After,
                container: WidgetSettingsNormalizer.normalizeContainer(initialContainer.container)
            };
        }
    };
}
