import dailyApi, {DailyApi} from "../api/dailyApi";
import hotelInfoApi, {HotelInfoApi} from "../api/hotelInfoApi";
import bookingApi, {BookingApi} from "../api/bookingApi";
import hotelSearchApi, {IHotelSearchApi} from "../api/hotelSearch";
import paymentsApi, {PaymentsApi} from "../api/paymentsApi";
import paymentTerminalApi, {PaymentTerminalApi} from "../api/paymentTerminalApi";
import cashbackApi, {CashbackApi} from "../api/cashbackApi";
import paymentsByLinkApi, {PaymentsByLinkApi} from "../api/paymentsByLinkApi";
import promoCodeApi, {PromoCodeApi} from "../api/promoCodeApi";
import availabilitiesApi, {AvailabilitiesApi} from "../api/availabilitiesApi";
import hourlyApi, {HourlyApi} from "../api/hourlyApi";
import hourlyBookingApi, {HourlyBookingApi} from "../api/hourlyBookingApi";

export interface IExtraArgument {
    dailyApi: DailyApi;
    hourlyApi: HourlyApi;
    hotelInfoApi: HotelInfoApi;
    bookingApi: BookingApi;
    hourlyBookingApi: HourlyBookingApi;
    hotelSearchApi: IHotelSearchApi;
    paymentsApi: PaymentsApi;
    paymentTerminalApi: PaymentTerminalApi;
    cashbackApi: CashbackApi;
    promoCodeApi: PromoCodeApi;
    paymentsByLinkApi: PaymentsByLinkApi;
    availabilitiesApi: AvailabilitiesApi;
}

export const extraArgument: IExtraArgument = {
    bookingApi,
    dailyApi,
    hourlyApi,
    hotelInfoApi,
    hotelSearchApi,
    paymentsApi,
    paymentTerminalApi,
    cashbackApi,
    paymentsByLinkApi,
    promoCodeApi,
    availabilitiesApi,
    hourlyBookingApi,
};
