import {ApiBase} from "./apiBase";
import WidgetStore from "../widget/WidgetStore";
import ApiEntryPoints from "../core/node/ApiEntryPoints.js";
import {UrlHelper} from "../helpers/UrlHelper";

export class ApiSiteBase<T = unknown> extends ApiBase<T> {
    protected buildRequestUrl(pathParts?: string[]): string {
        pathParts = pathParts || [];
        const widgetId = WidgetStore.settings && WidgetStore.settings.hotelId;
        const basePrefix = widgetId ? WidgetStore.baseUrl + ApiEntryPoints.widgetApiV1 : ApiEntryPoints.apiV1;
        const parameter = widgetId || UrlHelper.getSiteName();
        const urlParts = (parameter ? [basePrefix, this.modelType, parameter] : [basePrefix, this.modelType])
            .concat(pathParts.map(pp => pp.toString()));
        return urlParts.join("/");
    }
}
