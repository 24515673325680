import {IHotelFeature} from "../../data/HotelFeature";
import {Reducer} from "redux";
import {HotelFeaturesActionTypes, KnownHotelFeaturesAction} from "./hourlyFeaturesActionCreators";

export interface IHourlyFeaturesState {
    error: Error;
    isLoading: boolean;
    hourlyFeatures: IHotelFeature[];
}

const initialState: IHourlyFeaturesState = {
    hourlyFeatures: [],
    error: null,
    isLoading: false,
};

export const hourlyFeaturesReducer: Reducer<IHourlyFeaturesState> = (
    state: IHourlyFeaturesState = initialState,
    action: KnownHotelFeaturesAction
) => {
    switch (action.type) {
        case HotelFeaturesActionTypes.GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case HotelFeaturesActionTypes.GET_SUCCESS:
            return {
                ...state,
                hourlyFeatures: action.response,
                isLoading: false,
            };
        case HotelFeaturesActionTypes.GET_ERROR:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        default:
            return state;
    }
};
