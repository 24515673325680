import styles from "./GuestsSelectControl.scss";
import {IGuestSelectField} from "./GuestsSelectControl";
import CounterInput from "../Counter/CounterInput";

interface IGuestsSelectTooltipRowProps {
    field: IGuestSelectField;
    minGuestsCount?: number;
    maxGuestsCount?: number;
    readonly children?: React.ReactNode;
}

const GuestsSelectTooltipRow = (props: IGuestsSelectTooltipRowProps) => {
    const {field, minGuestsCount = 0, maxGuestsCount = Infinity, children} = props;
    return (
        <div className={styles.tooltipRow}>
            <span className={styles.caption}>
                {children}
            </span>
            <CounterInput
                value={field.count}
                minValue={minGuestsCount}
                maxValue={maxGuestsCount}
                onChange={field.onChange}
            />
        </div>
    );
};
GuestsSelectTooltipRow.displayName = "GuestsSelectTooltipRow";
export default GuestsSelectTooltipRow;
