import {AvailabilitiesDailyType} from "../../data/Availability";
import {Reducer} from "redux";
import {ActionTypes, KnownAvailabilitiesAction} from "./availabilitiesActionCreators";

export interface IAvailabilitiesState {
    isLoading: boolean;
    availabilities: AvailabilitiesDailyType;
    error?: Error;
}

export const defaultState: IAvailabilitiesState = {
    isLoading: false,
    availabilities: null,
    error: null,
};

export const availabilitiesReducer: Reducer<IAvailabilitiesState> = (
    state: IAvailabilitiesState = defaultState,
    action: KnownAvailabilitiesAction
) => {
    switch (action.type) {
        case ActionTypes.GET_AVAILABILITIES_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ActionTypes.GET_AVAILABILITIES_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case ActionTypes.GET_AVAILABILITIES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                availabilities: action.response,
            };
        }
        default: {
            return state;
        }
    }
};
