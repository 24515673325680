import {FlippedProps} from "flip-toolkit/lib/types";
import {Flipped} from "react-flip-toolkit";
import {FlipperContext, getFlipId} from "../FlipperContext";
import {FlippedResizerHeightAnimation, IFlippedResizerHeightAnimationSettings} from "./FlippedResizerHeightAnimation";
import {FlippedResizerScrollTopAnimation, IFlippedResizerScrollTopAnimationSettings} from "./FlippedResizerScrollTopAnimation";
import {useMount} from "@skbkontur/hotel-hooks/react";

interface IFlippedResizerProps extends FlippedProps {
    className?: string;
    scrollAfterHeight?: boolean;
    getScrollSettings?: () => IFlippedResizerScrollTopAnimationSettings;
    getHeightSettings?: () => IFlippedResizerHeightAnimationSettings;
}

const FlippedResizer = (props: React.PropsWithChildren<IFlippedResizerProps>) => {
    const {
        children,
        className,
        flipId: childFlipId,
        getHeightSettings,
        getScrollSettings,
        scrollAfterHeight,
        ...flippedProps
    } = props;

    const {parentFlipId} = React.useContext(FlipperContext);
    const flipId = getFlipId(parentFlipId, childFlipId);

    const container = React.useRef<HTMLDivElement>();
    const heightAnimation = React.useRef<FlippedResizerHeightAnimation>();
    const scrollAnimation = React.useRef<FlippedResizerScrollTopAnimation>();
    const {scrollElement} = getScrollSettings?.() || {};

    useMount(() => {
        if (container.current) {
            heightAnimation.current = new FlippedResizerHeightAnimation(container.current);
        }
    });

    React.useEffect(() => {
        if (container.current && scrollElement) {
            scrollAnimation.current = new FlippedResizerScrollTopAnimation(scrollElement);
            scrollAnimation.current.subscribe();
            return () => scrollAnimation.current.unsubscribe();
        }
    }, [scrollElement]);

    heightAnimation.current?.updateGetSettings(getHeightSettings);
    scrollAnimation.current?.updateGetSettings(getScrollSettings);

    const animateResize = async () => {
        heightAnimation.current.prepareAnimation();

        if (scrollAfterHeight) {
            await heightAnimation.current.animate();
            scrollAnimation.current?.prepareAnimation();
            scrollAnimation.current?.animate();
        } else {
            const heightDuration = heightAnimation.current.getDuration();
            heightAnimation.current.animate(heightDuration);
            scrollAnimation.current?.prepareAnimation();
            scrollAnimation.current?.animate(heightDuration);
        }
    };

    return (
        <div className={className} ref={container}>
            <Flipped {...flippedProps} flipId={flipId} onStart={animateResize}>
                <div>
                    <Flipped inverseFlipId={String(flipId)}>
                        <div>
                            {children}
                        </div>
                    </Flipped>
                </div>
            </Flipped>
        </div>
    );
};
FlippedResizer.displayName = "FlippedResizer";
export default FlippedResizer;
