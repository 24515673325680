import {BookingOrderContext} from "../providers/BookingOrder/BookingOrderContext";
import {bookingLightboxOrderAnalyticsEvents} from "../analytics/bookingLightboxOrderAnalyticsEvents";

export enum FormFieldName {
    Fio = "Fio",
    Comment = "Comment",
    Email = "Email",
    Phone = "Phone",
}

export const useTrackFormFieldFill = (formFieldName: FormFieldName) => {
    const {
        form: {customer, comment},
    } = React.useContext(BookingOrderContext);
    const {fio, email, phone} = customer;

    const formFieldValue = React.useMemo(() => {
        switch (formFieldName) {
            case FormFieldName.Fio:
                return fio;
            case FormFieldName.Comment:
                return comment;
            case FormFieldName.Email:
                return email;
            case FormFieldName.Phone:
                return phone;
        }
    }, [fio, comment, email, phone, formFieldName]);

    return React.useCallback(() => {
        formFieldValue && bookingLightboxOrderAnalyticsEvents.trackFormFieldFill(formFieldName, formFieldValue);
    }, [formFieldValue, formFieldName]);
};
