import {IHotelFeature} from "../../../../data/HotelFeature";
import {useNotDeletedHourlyFeatures} from "../../../../hooks/useNotDeletedHourlyFeatures";
import {
    HourlyAccommodationsContext,
    IHourlyAccommodationsContext,
} from "../../../HourlyAccommodations/HourlyAccommodationsContext";

export interface IFeatureWithCount extends IHotelFeature {
    count: number;
}

export const useSelectedHotelFeatures = (): IFeatureWithCount[] => {
    const sellingHotelFeatures = useNotDeletedHourlyFeatures();
    const {selectedAccommodation}: IHourlyAccommodationsContext = React.useContext(
        HourlyAccommodationsContext
    );
    const {bookingFeatures} = selectedAccommodation;

    return Object.keys(bookingFeatures).map(id => {
        const item = sellingHotelFeatures.find(feature => feature.id === id);

        return {...item, count: bookingFeatures[id]};
    });
};
