import {Modal} from "@skbkontur/react-ui";
import {useTranslation} from "@skbkontur/i18n";
import {useSelector} from "react-redux";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {IAppState} from "../../store/AppState";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";

interface IBookingOverbookingLightboxProps {
    onClose: () => void;
    onUpdate: () => void;
}

const BookingOverbookingLightbox = (props: IBookingOverbookingLightboxProps) => {
    const {onUpdate, onClose} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);
    const {isLoading} = useSelector((state: IAppState) => state.accommodations);

    return (
        <Modal
            disableFocusLock
            width={540}
            ignoreBackgroundClick
            onClose={onClose}
        >
            <Modal.Header>
                {t("overbooking.title")}
            </Modal.Header>
            <Modal.Body>
                {t("overbooking.resultsChanged")}<br />{t("overbooking.variantsMissing")}
            </Modal.Body>
            <Modal.Footer>
                <PrimaryButton loading={isLoading} onClick={onUpdate}>
                    {t("overbooking.update")}
                </PrimaryButton>
            </Modal.Footer>
        </Modal>
    );
};
BookingOverbookingLightbox.displayName = "BookingOverbookingLightbox";
export default BookingOverbookingLightbox;
