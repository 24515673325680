import {IHotelFeature} from "../../data/HotelFeature";
import styles from "./BookingRatesHotelFeatures.scss";
import {Gapped} from "@skbkontur/react-ui";
import HotelFeatureEmpty from "../../images/empty/HotelFeatureEmpty.png";
import {Compare} from "../../helpers/CompareHelper";
import SliderWithGallery from "../SliderWithGallery/SliderWithGallery";
import WidgetStore from "../../widget/WidgetStore";
import {DeviceContext} from "../../providers/Device/DeviceContext";

export interface IBookingRatesHotelFeatureInfoBlockProps {
    hotelFeature: IHotelFeature;
}

const BookingRatesHotelFeatureInfoBlock = ({hotelFeature}: IBookingRatesHotelFeatureInfoBlockProps) => {
    const {imageMetas, name, comment} = hotelFeature;

    const {isMobileMode} = React.useContext(DeviceContext);

    const title = <div className={styles.caption}>{name}</div>;

    return (
        <div className={styles.leftBlock}>
            {isMobileMode && title}
            <div className={styles.sliderContainer}>
                <SliderWithGallery
                    imageMetas={[...imageMetas].sort(Compare.byOrder)}
                    emptyImage={WidgetStore.getUrlWithBase(HotelFeatureEmpty)}
                />
            </div>
            <Gapped gap={4} vertical>
                {!isMobileMode && title}
                {comment}
            </Gapped>
        </div>
    );
};

BookingRatesHotelFeatureInfoBlock.displayName = "BookingRatesHotelFeatureInfoBlock";
export default BookingRatesHotelFeatureInfoBlock;
