import Fetcher from "../core/Fetcher";
import {ImageApi} from "./image";
import {ApiSiteBase} from "./apiSiteBase";
import {IHotelInfo, IHotelInfoModel} from "../data/HotelInfo";
import {first} from "lodash";

export interface IHotelInfoApi {
    getHotelInfo: () => Promise<IHotelInfo>;
}

export class HotelInfoApi extends ApiSiteBase implements IHotelInfoApi {
    async getHotelInfo(): Promise<IHotelInfo> {
        const hotelInfoModel = await Fetcher.get<IHotelInfoModel>(this.buildRequestUrl());
        const {
            organizationName,
            description,
            freeChildrenOn,
            checkInTime,
            checkOutTime,
            freeChildrenAge,
            address,
            contacts,
            currencyCode,
            countryCode,
            requisites,
            offerAgreementLink,
            autoSendConfirmationEmail,
            imageMetas,
            displayAvailabilityCalendar,
            timeZone
        } = hotelInfoModel;
        ImageApi.sortMetas(imageMetas);

        const mainImageMetas = imageMetas.filter(im => im.order === 0);
        const othersImagesMetas = imageMetas.filter(im => im.order !== 0);

        const mainImage = first(ImageApi.getImagesFromMetas(mainImageMetas));
        const filteredImageThumbnails = ImageApi.getThumbnailsFromMetas(othersImagesMetas);
        const galleryImages = ImageApi.getImagesFromMetas(imageMetas);

        return {
            organizationName,
            description,
            freeChildrenOn,
            checkInTime,
            checkOutTime,
            freeChildrenAge,
            address,
            contacts,
            images: galleryImages,
            currencyCode,
            imagesThumbnail: [mainImage, ...filteredImageThumbnails].filter(Boolean),
            countryCode,
            requisites,
            offerAgreementLink,
            autoSendConfirmationEmail,
            displayAvailabilityCalendar,
            timeZone
        };
    }
}

export default new HotelInfoApi("hotelInfo");
