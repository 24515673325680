export enum HourlyBookingLightboxStep {
    Initial = "initial",
    NotAvailable = "NotAvailable",
    SelectRates = "selectRates",
    FinishOrder = "finishOrder",
}

export const isShowSearch = (step: HourlyBookingLightboxStep) => (
    step !== HourlyBookingLightboxStep.FinishOrder
);

export const isShowRatesStep = (step: HourlyBookingLightboxStep) => (
    step === HourlyBookingLightboxStep.SelectRates
);

export const isReadyToOrder = (step: HourlyBookingLightboxStep) => (
    step === HourlyBookingLightboxStep.FinishOrder
);

export interface IHourlyBookingLightboxStepsParams {
    hourlyObjectsId?: string;
    scrollToStart?: boolean;
}

export interface IHourlyBookingLightboxStepsContext {
    goToStep: (step: HourlyBookingLightboxStep, params?: IHourlyBookingLightboxStepsParams) => void;
    step: HourlyBookingLightboxStep;
    params?: IHourlyBookingLightboxStepsParams;
}

export const HourlyBookingLightboxStepsContext = React.createContext(
    {} as IHourlyBookingLightboxStepsContext
);
