import {CountryCodeAlpha3} from "@skbkontur/i18n";
import {IForeignRequisites, IRussianRequisites} from "./Requisites";
import {IImage, IImageMeta} from "@skbkontur/hotel-data/image";

export interface IHotelInfoModel {
    organizationName: string;
    description: string;
    freeChildrenOn: boolean;
    checkInTime?: string;
    checkOutTime?: string;
    freeChildrenAge?: number;
    address: string;
    contacts?: IContact[];
    imageMetas: IImageMeta[];
    currencyCode?: string;
    countryCode: CountryCodeAlpha3;
    requisites: IRussianRequisites | IForeignRequisites;
    offerAgreementLink: string;
    autoSendConfirmationEmail: boolean;
    displayAvailabilityCalendar?: boolean;
    timeZone?: ITimeZone;
}

export interface IHotelInfo {
    organizationName: string;
    description: string;
    freeChildrenOn: boolean;
    checkInTime?: string;
    checkOutTime?: string;
    freeChildrenAge?: number;
    address: string;
    contacts?: IContact[];
    images: IImage[];
    currencyCode?: string;
    imagesThumbnail: IImage[];
    countryCode: CountryCodeAlpha3;
    requisites: IRussianRequisites | IForeignRequisites;
    offerAgreementLink: string;
    autoSendConfirmationEmail: boolean;
    displayAvailabilityCalendar?: boolean;
    timeZone?: ITimeZone;
}

export interface ITimeZone {
    offset?: string;
    offsetInMinutes?: number;
}

export interface IContact {
    type: ContactType;
    value: string;
}

export enum ContactType {
    Address = "address",
    Phone = "phone",
    Vk = "vk",
    Email = "email",
    Telegram = "telegram",
    Viber = "viber",
    Whatsapp = "whatsApp"
}

export const FREE_CHILDREN_COUNT = 1;
export const MIN_ADULTS_COUNT = 1;
