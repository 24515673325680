import {IRoomCategoryAccommodation} from "../../../data/Accommodation";
import {IAccommodationsAvailabilityMap, ISelectedAccommodationsMap} from "../AccommodationsMaps";
import {SelectedAccommodationsHelper} from "./SelectedAccommodationsHelper";

// TODO Написать тесты

interface IUpdateAvailabilityParams {
    roomCategoryId: string;
    accommodationsAvailabilityMap: IAccommodationsAvailabilityMap;
    selectedAccommodationsMap: ISelectedAccommodationsMap;
}

export class AccommodationsAvailabilityHelper {
    static createAvailabilityMap = (roomCategoryAccommodations: IRoomCategoryAccommodation[]): IAccommodationsAvailabilityMap => (
        roomCategoryAccommodations?.reduce((acc, item) => {
            const {roomCategoryId, availableCount} = item;
            return {
                ...acc,
                [roomCategoryId]: {
                    availableCount,
                    allCount: availableCount
                }
            };
        }, {} as IAccommodationsAvailabilityMap) || {}
    );

    static updateAvailabilityMap = (params: IUpdateAvailabilityParams): IAccommodationsAvailabilityMap => {
        const {accommodationsAvailabilityMap, selectedAccommodationsMap, roomCategoryId} = params;
        const {allCount} = accommodationsAvailabilityMap[roomCategoryId];
        const busyAccommodationsCount = SelectedAccommodationsHelper.getBusyAccommodationsCount(selectedAccommodationsMap, roomCategoryId);
        return {
            ...accommodationsAvailabilityMap,
            [roomCategoryId]: {
                allCount,
                availableCount: allCount - busyAccommodationsCount
            }
        };
    };
}
