import {useSelector} from "react-redux";
import {IAppState} from "../store/AppState";
import {IRate} from "../data/Rate";

export const useRate = (rateId: string) => {
    const {rates} = useSelector((state: IAppState) => state.rates);
    return React.useMemo(() => (
        rates.find(rc => rc.id === rateId) || {} as IRate
    ), [rates, rateId]);
};
