import {Gapped, Modal} from "@skbkontur/react-ui";
import styles from "./BookingLightbox.scss";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import BookingSearchForm from "../../../components/BookingSearchForm/BookingSearchForm";
import {IRoomCategorySearchParams} from "../../../data/SearchParams";
import {getAccommodations} from "../../../store/accommodations/accommodationsActionCreators";
import {useDispatch, useSelector} from "react-redux";
import BookingLightboxOrder from "./Order/BookingLightboxOrder";
import BookingLightboxStepsProvider from "./Steps/BookingLightboxStepsProvider";
import {
    BookingLightboxStep,
    BookingLightboxStepsContext,
    isNotAvailableStep,
    isReadyToOrder,
    isSelectAccommodationsStep,
    isShowRatesStep
} from "./Steps/BookingLightboxStepsContext";
import AccommodationsProvider from "../../Accommodations/AccommodationsProvider";
import BookingAccommodations from "./Accommodations/BookingAccommodations";
import BookingRates from "./Rates/BookingRates";
import BookingLightboxFlipper from "./Flipper/BookingLightboxFlipper";
import {RoomCategorySearchParamsContext} from "../../SearchParams/SearchParamsContext";
import FlippedResizer from "../../../common/components/Flipper/Resizer/FlippedResizer";
import {BOOKING_LIGHTBOX_CLASSNAME, BookingLightboxHelper} from "./BookingLightboxHelper";
import {isEqual} from "lodash";
import {DeviceContext} from "../../Device/DeviceContext";
import {WithProvider} from "../../../common/components/WithProvider/WithProvider";
import {getPaymentTerminals} from "../../../store/paymentSource/paymentSourceActionCreators";
import {getCashbackTerminalIds} from "../../../store/cashback/cashbackActionCreators";
import BookingOverbookingProvider from "./Overbooking/BookingOverbookingProvider";
import {IAppState} from "../../../store/AppState";
import {BookingType} from "../../../data/Booking";
import {useShouldFlipStepsChange} from "./Flipper/useShouldFlipStepsChange";
import {WithErrorBoundaryLightbox} from "../../../components/ErrorBoundary/WithErrorBoundaryLightbox";
import BookingDatesNotAvailableCalendar from "./DatesNotAvailableCalendar/BookingDatesNotAvailableCalendar";
import {useLightboxResize} from "../../../hooks/useLightboxResize";
import {useMount} from "@skbkontur/hotel-hooks/react";

interface IBookingLightboxProps {
    onClose: () => void;
    onBooking: (bookingParams: BookingType) => Promise<void>;
}

const BookingLightbox = (props: IBookingLightboxProps) => {
    const {onBooking, onClose} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModule);
    const dispatch = useDispatch();

    const width = useLightboxResize(BookingLightboxHelper.getWidth);
    const {isAvailable} = useSelector((state: IAppState) => state.promoCode);
    const {isDesktopMode} = React.useContext(DeviceContext);
    const {step, params: stepParams, goToStep} = React.useContext(BookingLightboxStepsContext);

    const {
        params: searchParams,
        setParams: setSearchParams
    } = React.useContext(RoomCategorySearchParamsContext);

    const {fromDate, toDate} = searchParams;

    const search = (newSearchParams: IRoomCategorySearchParams) => {
        /** Необходимо обновить searchParams до accommodations, чтобы обновленные параметры попали в BookingOverbookingProvider */
        setSearchParams(currentSearchParams => ({
            ...currentSearchParams,
            ...newSearchParams,
        }));
        dispatch(getAccommodations(newSearchParams));
    };

    const changeParams = (changedParams: IRoomCategorySearchParams) => {
        const isParamsChanged = !isEqual(searchParams, changedParams);
        if (isParamsChanged && step !== BookingLightboxStep.Initial) {
            goToStep(BookingLightboxStep.Initial);
        }
        if (!isParamsChanged && step === BookingLightboxStep.Initial) {
            goToStep(BookingLightboxStep.SelectAccommodations);
        }
    };

    useMount(() => {
        dispatch(getPaymentTerminals());
    });

    React.useEffect(() => {
        fromDate && toDate && dispatch(getCashbackTerminalIds(fromDate, toDate));
    }, [fromDate, toDate]);

    const shouldFlip = useShouldFlipStepsChange();

    return (
        <Modal
            className={BOOKING_LIGHTBOX_CLASSNAME}
            width={width}
            onClose={onClose}
            ignoreBackgroundClick
            disableFocusLock
        >
            <Modal.Header className={styles.header} sticky={isDesktopMode}>
                <div className={styles.caption}>
                    {t("BookingModal.caption")}
                </div>
            </Modal.Header>
            <Modal.Body noPadding>
                <div className={styles.container}>
                    <BookingLightboxFlipper>
                        <Gapped gap={40} vertical>
                            <BookingSearchForm
                                params={searchParams}
                                onSearch={search}
                                onChange={changeParams}
                                inline
                                searchOnMount
                                autoFocus
                            />
                            <FlippedResizer
                                flipId={"Steps"}
                                shouldFlip={shouldFlip}
                                getHeightSettings={() => ({withOverflow: true})}
                                getScrollSettings={() => BookingLightboxHelper.getScrollSettings(step, stepParams, isDesktopMode, isAvailable)}
                                scrollAfterHeight={BookingLightboxHelper.isScrollWaitHeight(step)}
                            >
                                <Gapped gap={40} vertical verticalAlign="top">
                                    {isNotAvailableStep(step) && <BookingDatesNotAvailableCalendar />}
                                    {isSelectAccommodationsStep(step) && <BookingAccommodations />}
                                    {isShowRatesStep(step) && <BookingRates />}
                                    {isReadyToOrder(step) && <BookingLightboxOrder onBooking={onBooking} />}
                                </Gapped>
                            </FlippedResizer>
                        </Gapped>
                    </BookingLightboxFlipper>
                </div>
            </Modal.Body>
        </Modal>
    );
};
BookingLightbox.displayName = "BookingLightbox";
const withErrorBoundary = WithErrorBoundaryLightbox(BookingLightbox);
const withOverbookingProvider = WithProvider(withErrorBoundary, BookingOverbookingProvider);
const withStepsProvider = WithProvider(withOverbookingProvider, BookingLightboxStepsProvider);
export default WithProvider(withStepsProvider, AccommodationsProvider);
