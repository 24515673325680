import {useTranslation} from "@skbkontur/i18n";
import {Gapped} from "@skbkontur/react-ui";
import {useDispatch, useSelector} from "react-redux";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import DateHelper from "../../helpers/DateHelper";
import {IAppState} from "../../store/AppState";
import {
    getAvailabilitiesHourly,
    clearAvailabilitiesHourly,
    getAvailabilitiesHourlyTo,
} from "../../store/availabilitiesHourly/availabilitiesHourlyActionCreators";
import {getHourlyAccommodations} from "../../store/hourlyAccommodations/hourlyAccommodationsActionCreators";
import {ICurrency} from "../../types/Currency";
import {DateFormats} from "../../types/DateFormats";
import DatesPeriodPicker from "../DatesPeriodControl/DatesPeriodPickerControl";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import styles from "./HourlyObjectsList.scss";
import HourlyObjectsListSelect from "./HourlyObjectsListSelect";
import cn from "classnames";
import {HourlyObjectsAvailableHelper} from "./HourlyObjectsAvailableHelper";
import {useHourlyStateUpdateBySearchParams} from "./hooks/useHourlyStateUpdateBySearchParams";
import {useHourlyValidationAvailableTime} from "./hooks/useHourlyValidationAvailableTime";
import {useGetSearchParamsHourlyObjects} from "./hooks/useGetSearchParamsHourlyObjects";
import {IHourlyObjectSearchParams} from "../../data/SearchParams";
import {createValidationInfo, ValidationInfoType} from "../../forms/ValidationInfo";
import {useHourlyAvailabilities} from "./hooks/useHourlyAvailabilities";
import {HourlyBookingLightboxHelper} from "../../providers/HourlyBooking/HourlyBookingLightbox/HourlyBookingLightboxHelper";

export interface IHourlyObjectsItemAvailableTime {
    timestamp: number;
    price?: ICurrency;
    isUnavailable?: boolean;
}

interface IHourlyObjectsListAvailableTimeProps {
    hourlyObjectId: string;
    hourlyObjectItemId?: string;
    onClick?: () => void;
    onChange?: (params: IHourlyObjectSearchParams) => void;
    inline?: boolean;
}

export interface IHourlyObjectsListAvailableTimeState {
    date: string;
    from?: IHourlyObjectsItemAvailableTime;
    to?: IHourlyObjectsItemAvailableTime;
    isError: boolean;
    needSearch: boolean;
}

const getInitialState = (date: string, needSearch: boolean): IHourlyObjectsListAvailableTimeState => ({
    date,
    from: null,
    to: null,
    isError: false,
    needSearch,
});

const HourlyObjectsListAvailableTime = (props: IHourlyObjectsListAvailableTimeProps) => {
    const {
        hourlyObjectId: hourlyObjectIdFromProps,
        hourlyObjectItemId: hourlyObjectItemIdFromProps,
        inline,
        onClick,
        onChange,
    } = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModule);
    const dispatch = useDispatch();

    const isLoading = useSelector((state: IAppState) => state.availabilitiesHourly.isLoading);
    const offsetInMinutes = useSelector((state: IAppState) => state.hotelInfo.info?.timeZone?.offsetInMinutes);

    const today = DateHelper.getTodayWithTimezone(DateFormats.FullDateDayFirst, offsetInMinutes);

    const {dateFromSearchParams, params: searchParams, setParams} = useGetSearchParamsHourlyObjects();

    const currentDate = dateFromSearchParams || today;
    const [state, setState] = React.useState<IHourlyObjectsListAvailableTimeState>(getInitialState(currentDate, !inline));

    const {date, from, to, isError, needSearch} = state;

    const {fromItems, toItems} = useHourlyAvailabilities();

    useHourlyStateUpdateBySearchParams({
        from,
        to,
        fromItems,
        toItems,
        hourlyObjectIdFromProps,
        hourlyObjectItemIdFromProps,
        setState,
    });
    useHourlyValidationAvailableTime({
        state,
        fromItems,
        toItems,
        setState,
    });


    const loadAvailabilities = (date: string) => {
        const {fromDate, toDate} = HourlyObjectsAvailableHelper.getPeriodDate(date, offsetInMinutes);

        dispatch(
            getAvailabilitiesHourly({
                hourlyObjectId: hourlyObjectIdFromProps,
                hourlyObjectItemId: hourlyObjectItemIdFromProps,
                fromDate,
                toDate,
            }),
        );
    };

    const getAvailabilitiesTo = (fromDate: string) => {
        dispatch(
            getAvailabilitiesHourlyTo({
                hourlyObjectId: hourlyObjectIdFromProps,
                hourlyObjectItemId: hourlyObjectItemIdFromProps,
                fromDate,
            }),
        );
    };

    React.useEffect(() => {
        dispatch(clearAvailabilitiesHourly());
        loadAvailabilities(date);
    }, [date]);

    React.useEffect(() => {
        if (!from?.timestamp) {
            return;
        }

        const fromDate = DateHelper.convertWithTimezone(from.timestamp, {
            formatIn: DateFormats.UnixMsTimestamp,
            formatOut: DateFormats.FullDateYearFirstWithTime,
            offsetInMinutes,
        });

        getAvailabilitiesTo(fromDate);
    }, [from]);

    const handleChange = (newDate: string) => {
        setState(getInitialState(newDate, inline));

        if (newDate === date) {
            return;
        }

        setParams((currentParams: IHourlyObjectSearchParams) => {
            const params: IHourlyObjectSearchParams = {
                ...currentParams,
                hourlyObjectId: hourlyObjectIdFromProps,
                hourlyObjectItemId: hourlyObjectItemIdFromProps,
                timeFrom: null,
                timeTo: null,
            };

            onChange?.(params);

            return params;
        });
    };

    const handleFromValueChange = (value: IHourlyObjectsItemAvailableTime) => {
        if (value.isUnavailable) {
            return;
        }

        const newDate = DateHelper.convertWithTimezone(value.timestamp, {
            formatIn: DateFormats.UnixMsTimestamp,
            formatOut: DateFormats.FullDateDayFirst,
            offsetInMinutes,
        });

        setState(prev => ({
            ...prev,
            from: value,
            date: newDate,
            isError: false,
            needSearch: true,
        }));

        const params: IHourlyObjectSearchParams = {
            ...searchParams,
            hourlyObjectId: hourlyObjectIdFromProps,
            hourlyObjectItemId: hourlyObjectItemIdFromProps,
            timeFrom: value.timestamp,
            timeTo: to?.timestamp,
        };
        onChange?.(params);
    };

    const handleToValueChange = (value: IHourlyObjectsItemAvailableTime) => {
        if (value.isUnavailable) {
            return;
        }

        setState(prev => ({
            ...prev,
            to: value,
            isError: false,
            needSearch: true,
        }));

        const params: IHourlyObjectSearchParams = {
            ...searchParams,
            hourlyObjectId: hourlyObjectIdFromProps,
            hourlyObjectItemId: hourlyObjectItemIdFromProps,
            timeFrom: from?.timestamp,
            timeTo: value.timestamp
        };

        onChange?.(params);
    };

    const handleShowPrice = () => {
        setParams(currentParams => ({
            ...currentParams,
            hourlyObjectId: hourlyObjectIdFromProps,
            hourlyObjectItemId: hourlyObjectItemIdFromProps,
            timeFrom: from.timestamp,
            timeTo: to.timestamp,
        }));

        setState(prev => ({
            ...prev,
            needSearch: false,
        }));

        if (!inline) {
            dispatch(clearAvailabilitiesHourly());
        }

        if (!from.timestamp || !to.timestamp) {
            return;
        }

        const params = HourlyBookingLightboxHelper.getAccommodationsParams(
            from.timestamp,
            to.timestamp,
            offsetInMinutes,
        );
        dispatch(getHourlyAccommodations(params));

        onClick?.();
    };

    const validationInfo = createValidationInfo(t("validations.empty"), ValidationInfoType.Immediate);

    return (
        <div
            className={cn(styles.availableTime, {
                [styles.gapTop]: !inline,
                [styles.gapRight]: inline,
                [styles.availableTimeRow]: inline,
            })}
        >
            <DatesPeriodPicker
                validationInfo={!date && validationInfo}
                field={{
                    onChange: handleChange,
                    placeholder: "",
                    value: date,
                }}
                width={inline && "auto"}
                minDate={today}
            />
            <Gapped gap={10}>
                {!inline && t("Select.from")}
                <HourlyObjectsListSelect
                    error={!from}
                    placeholder=" "
                    items={fromItems}
                    value={from}
                    disabled={isLoading}
                    selectedDate={date}
                    onValueChange={handleFromValueChange}
                />
                {inline ? "−" : t("Select.to")}
                <HourlyObjectsListSelect
                    placeholder=" "
                    error={isError || !to}
                    items={toItems}
                    value={to}
                    disabled={isLoading}
                    selectedDate={date}
                    withoutPlaceholderPrice
                    onValueChange={handleToValueChange}
                />
            </Gapped>
            <PrimaryButton
                disabled={!from || !to || isError}
                width={!inline && "100%"}
                use={inline && !needSearch ? "default" : "pay"}
                onClick={handleShowPrice}
            >
                {t("Buttons.showPrice")}
            </PrimaryButton>
        </div>
    );
};
HourlyObjectsListAvailableTime.displayName = "HourlyObjectsListAvailableTime";
export default HourlyObjectsListAvailableTime;
