import { Nullable } from "@skbkontur/react-ui/typings/utility-types";

export type PromiseCancellable<T> = Promise<Nullable<T>> & {cancel: () => void};

export const makePromiseCancellable = <T>(
    promiseFunc: (isCancelled: () => boolean) => Promise<T>
): PromiseCancellable<T> => {
    const cancelledRef = {isCancelled: false};
    const cancel = () => {
        cancelledRef.isCancelled = true;
    };
    const promise = promiseFunc(() => cancelledRef.isCancelled);
    return Object.defineProperty(promise, "cancel", {value: cancel}) as PromiseCancellable<T>;
};
