import cn from "classnames";
import styles from "./Title.scss";

interface ITitleProps {
    id?: string;
    small?: boolean;
}

const Title = (props: React.PropsWithChildren<ITitleProps>) => {
    const {small, children, id} = props;

    const titleClassNames = cn(styles.title, {[styles.small]: small});

    return (
        <h3 id={id} className={titleClassNames}>
            {children}
        </h3>
    );
};
Title.displayName = "Title";
export default Title;
