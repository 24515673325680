import {BaseAnalyticsEvents} from "./BaseAnalyticsEvents";
import {AnalyticsCategory, IframeEventAnalyticsAction} from "./Analytics";

/* eslint-disable i18next/no-literal-string */
class IframeEvent extends BaseAnalyticsEvents {
    initializationMOB = (organizationName: string) => (
        this.trackEvent(
            IframeEventAnalyticsAction.InitializationMOB,
            "открыли МОБ в iframe", {
                organizationName
            }
        )
    );

    initializationWidget = (organizationName: string, organizationId: string) => (
        this.trackEvent(
            IframeEventAnalyticsAction.InitializationWidget,
            "загрузился виджет в iframe", {
                organizationName, organizationId
            }
        )
    );
}

export const iframeEvent = new IframeEvent(AnalyticsCategory.IframeEvent);
