import {CustomThunkAction} from "../ThunkAction";
import {IBaseSearchParams} from "../../data/SearchParams";
import {IHourlyAccommodation} from "../../data/HourlyBooking";

export enum HourlyAccommodationsActionTypes {
    GET_HOURLY_OBJECTS_ACCOMMODATIONS_REQUEST = "hourlyObjectsAccommodations/GET_HOURLY_OBJECTS_ACCOMMODATIONS_REQUEST",
    GET_HOURLY_OBJECTS_ACCOMMODATIONS_SUCCESS = "hourlyObjectsAccommodations/GET_HOURLY_OBJECTS_ACCOMMODATIONS_SUCCESS",
    GET_HOURLY_OBJECTS_ACCOMMODATIONS_ERROR = "hourlyObjectsAccommodations/GET_HOURLY_OBJECTS_ACCOMMODATIONS_ERROR",
    CLEAR_HOURLY_OBJECTS_ACCOMMODATIONS = "hourlyObjectsAccommodations/CLEAR_HOURLY_OBJECTS_ACCOMMODATIONS"
}

interface IHourlyAccommodationsRequestAction {
    type: HourlyAccommodationsActionTypes.GET_HOURLY_OBJECTS_ACCOMMODATIONS_REQUEST;
}

interface IHourlyAccommodationsSuccessAction {
    type: HourlyAccommodationsActionTypes.GET_HOURLY_OBJECTS_ACCOMMODATIONS_SUCCESS;
    response: IHourlyAccommodation[];
}

interface IHourlyAccommodationsErrorAction {
    type: HourlyAccommodationsActionTypes.GET_HOURLY_OBJECTS_ACCOMMODATIONS_ERROR;
    error: Error;
}

interface IHourlyAccommodationsClearAction {
    type: HourlyAccommodationsActionTypes.CLEAR_HOURLY_OBJECTS_ACCOMMODATIONS;
}

export type KnownHourlyAccommodationsAction = IHourlyAccommodationsRequestAction
    | IHourlyAccommodationsSuccessAction
    | IHourlyAccommodationsErrorAction
    | IHourlyAccommodationsClearAction;

export const getHourlyAccommodations = (params: Omit<IBaseSearchParams, "adultsCount" | "kidsCount">): CustomThunkAction<IHourlyAccommodation[]> => (
    // @ts-expect-error Use new Redux instead of fixing this types
    (dispatch, getState, {hourlyApi}) => dispatch({
        type: {
            REQUEST: HourlyAccommodationsActionTypes.GET_HOURLY_OBJECTS_ACCOMMODATIONS_REQUEST,
            SUCCESS: HourlyAccommodationsActionTypes.GET_HOURLY_OBJECTS_ACCOMMODATIONS_SUCCESS,
            FAILURE: HourlyAccommodationsActionTypes.GET_HOURLY_OBJECTS_ACCOMMODATIONS_ERROR
        },
        asyncAction: hourlyApi.getAccommodations(params)
    })
);

export const clearHourlyAccommodations = () => ({type: HourlyAccommodationsActionTypes.CLEAR_HOURLY_OBJECTS_ACCOMMODATIONS});
