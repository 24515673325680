import {DatePicker} from "@skbkontur/react-ui";
import {ValidationInfo} from "@skbkontur/react-ui-validations";
import DateHelper from "../../helpers/DateHelper";
import styles from "./DatesPeriodControl.scss";
import DatesPeriodPicker from "./DatesPeriodPickerControl";
import {createValidationInfo} from "../../forms/ValidationInfo";
import cn from "classnames";
import {DateFormats} from "../../types/DateFormats";
import {Translated, tType} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";

export interface IDatePeriodField {
    value: string;
    placeholder: React.ReactNode;
    onChange: (value: string) => void;
}

interface IDatesPeriodControlProps {
    fromField: IDatePeriodField;
    toField: IDatePeriodField;
    isAutoFocus?: boolean;
    hideDash?: boolean;
    offsetInMinutes?: number
    onValidate?: (validationInfo: IDatePeriodValidationInfo) => void;
}

export interface IDatePeriodValidationInfo {
    fromDatePicker: ValidationInfo;
    toDatePicker: ValidationInfo;
}

interface IDatesPeriodChanges {
    current: IDatePeriodField;
    prev: IDatePeriodField;
}

interface IDatesValidationInfo {
    required: ValidationInfo;
    orderError: ValidationInfo;
    equalError: ValidationInfo;
    checkinBeforeToday: ValidationInfo;
    checkoutBeforeToday: ValidationInfo;
    selectDateNoLaterThan: ValidationInfo;
}

export const getDatesPeriodValidationInfo = (t: tType, maxDate: string): IDatesValidationInfo => ({
    required: createValidationInfo(t("validations.emptyCheckinCheckout")),
    orderError: createValidationInfo(t("validations.checkoutBeforeCheckin")),
    equalError: createValidationInfo(t("validations.checkoutEqualsCheckin")),
    checkinBeforeToday: createValidationInfo(t("validations.checkinBeforeNow")),
    checkoutBeforeToday: createValidationInfo(t("validations.checkoutBeforeNow")),
    selectDateNoLaterThan: createValidationInfo(t("validations.selectDateNoLaterThan", {date: maxDate}))
});

export default class DatesPeriodControl extends React.Component<IDatesPeriodControlProps> {
    static MIN_PERIOD_DAYS = 1;
    static MAX_PERIOD_YEAR = 2;

    fromDatePickerRef: DatesPeriodPicker;
    toDatePickerRef: DatesPeriodPicker;

    componentDidMount() {
        if (this.props.isAutoFocus) {
            // Use Promise async-call to avoid Modal autofocus phantom bug
            Promise.resolve().then(this.focusFirstEmptyPicker);
        }
    }

    componentDidUpdate(prevProps: IDatesPeriodControlProps) {
        const {fromField, toField} = this.props;
        const fromFieldChanges = {current: fromField, prev: prevProps.fromField};
        const toFieldChanges = {current: toField, prev: prevProps.toField};
        if (this.isFieldValid(fromField.value) && this.isFieldValid(toField.value)) {
            this.invalidateIfOrderIsNotCorrect(fromFieldChanges, toFieldChanges);
        } else {
            this.focusEmptyAfterChange(fromFieldChanges, toFieldChanges);
        }
    }

    focusEmptyAfterChange(fromField: IDatesPeriodChanges, toField: IDatesPeriodChanges) {
        const isAnotherFocusDetected = this.focusIfAnotherEmpty(fromField, toField.current.value, this.toDatePickerRef);
        if (!isAnotherFocusDetected) {
            this.focusIfAnotherEmpty(toField, fromField.current.value, this.fromDatePickerRef);
        }
    }

    focusIfAnotherEmpty(changes: IDatesPeriodChanges, anotherValue: string, anotherRef: DatesPeriodPicker) {
        if (this.isFieldValid(changes.current.value) && this.isFieldChanged(changes) && !this.isFieldValid(anotherValue)) {
            anotherRef.focusPicker();
            return true;
        }
        return false;
    }

    focusFirstEmptyPicker = () => {
        const {fromField, toField} = this.props;
        const isAnyAlreadyFocused = this.fromDatePickerRef.isFocused() || this.toDatePickerRef.isFocused;
        if (!isAnyAlreadyFocused) {
            if (!this.isFieldValid(fromField.value)) {
                this.fromDatePickerRef.focusPicker();
            } else if (!this.isFieldValid(toField.value)) {
                this.toDatePickerRef.focusPicker();
            }
        }
    };

    invalidateIfOrderIsNotCorrect(fromField: IDatesPeriodChanges, toField: IDatesPeriodChanges) {
        if (!this.isCorrectOrder(fromField.current.value, toField.current.value)) {
            const isNotActualDetected = this.invalidateFirstIfSecondChanged(fromField, toField, this.fromDatePickerRef);
            if (!isNotActualDetected) {
                this.invalidateFirstIfSecondChanged(toField, fromField, this.toDatePickerRef);
            }
        }
    }

    invalidateFirstIfSecondChanged(first: IDatesPeriodChanges, second: IDatesPeriodChanges, firstRef: DatesPeriodPicker) {
        if (!this.isFieldChanged(first) && this.isFieldChanged(second)) {
            first.current.onChange(null);
            firstRef.focusPicker();
            return true;
        }
        return false;
    }

    isFieldChanged = (changes: IDatesPeriodChanges) => changes.current.value !== changes.prev.value;
    isFieldValid = (value: string) => DatePicker.validate(value) && value.length === String(DateFormats.FullDateDayFirst).length;

    getMinDateTo = (today: string): string => {
        const {fromField, toField} = this.props;

        if (!toField?.value && fromField?.value) {
            return DateHelper.addDaysToDate(DatesPeriodControl.MIN_PERIOD_DAYS, fromField?.value, DateFormats.FullDateDayFirst);
        }

        return DateHelper.addDaysToDate(DatesPeriodControl.MIN_PERIOD_DAYS, today, DateFormats.FullDateDayFirst);
    };

    render() {
        const {fromField, toField, hideDash, offsetInMinutes} = this.props;
        const today = DateHelper.getTodayWithTimezone(DateFormats.FullDateDayFirst, offsetInMinutes);
        const maxDate = DateHelper.addYearsToDate(DatesPeriodControl.MAX_PERIOD_YEAR, today, DateFormats.FullDateDayFirst);

        return (
            <Translated ns={[TranslationNamespaces.BookingModule]}>
                {(t: tType) => (
                    <div className={cn(styles.control, {[styles.withoutDash]: hideDash})}>
                        <div className={styles.dateFrom}>
                            <DatesPeriodPicker
                                field={fromField}
                                validationInfo={this.validate(t).fromDatePicker}
                                minDate={today}
                                maxDate={maxDate}
                                ref={this.setFromDatePickerRef}
                            />
                        </div>
                        <span className={styles.dash}>&ndash;</span>
                        <div className={styles.dateTo}>
                            <DatesPeriodPicker
                                field={toField}
                                validationInfo={this.validate(t).toDatePicker}
                                minDate={this.getMinDateTo(today)}
                                maxDate={maxDate}
                                ref={this.setToDatePickerRef}
                            />
                        </div>
                    </div>
                )}
            </Translated>
        );
    }

    private setFromDatePickerRef = (ref: DatesPeriodPicker) => this.fromDatePickerRef = ref;
    private setToDatePickerRef = (ref: DatesPeriodPicker) => this.toDatePickerRef = ref;

    isCorrectOrder = (fromField: string, toField: string) => (
        DateHelper.getDiffByDays(fromField, toField) >= DatesPeriodControl.MIN_PERIOD_DAYS
    );

    validate = (t: tType): IDatePeriodValidationInfo => {
        const {offsetInMinutes} = this.props;
        const today = DateHelper.getTodayWithTimezone(DateFormats.FullDateDayFirst, offsetInMinutes);
        const maxDate = DateHelper.addYearsToDate(DatesPeriodControl.MAX_PERIOD_YEAR, today, DateFormats.FullDateDayFirst);
        const validationInfo = getDatesPeriodValidationInfo(t, maxDate);
        const {fromField, toField, onValidate} = this.props;
        const fromDate = fromField.value;
        const toDate = toField.value;
        const result = {} as IDatePeriodValidationInfo;

        if (DateHelper.isBeforeDate(maxDate, fromDate)) {
            result.fromDatePicker = validationInfo.selectDateNoLaterThan;
        }

        if (DateHelper.isBeforeDate(maxDate, toDate)) {
            result.toDatePicker = validationInfo.selectDateNoLaterThan;
        }

        if (fromDate && toDate && validationInfo.orderError && !this.isCorrectOrder(fromDate, toDate)) {
            result.toDatePicker = validationInfo.orderError;
        }

        if (fromDate && toDate && validationInfo.orderError && DateHelper.isSameDate(toDate, fromDate)) {
            result.toDatePicker = validationInfo.equalError;
        }

        if (fromDate && validationInfo.checkinBeforeToday && DateHelper.isBeforeDate(fromDate, today)) {
            result.fromDatePicker = validationInfo.checkinBeforeToday;
        }

        if (toDate && validationInfo.checkoutBeforeToday && DateHelper.isBeforeDate(toDate, today)) {
            result.toDatePicker = validationInfo.checkoutBeforeToday;
        }

        if (!fromDate && validationInfo.required) {
            result.fromDatePicker = validationInfo.required;
        }

        if (!toDate && validationInfo.required) {
            result.toDatePicker = validationInfo.required;
        }

        onValidate && onValidate(result);
        return result;
    };
}
