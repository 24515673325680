import { ICurrency } from "../types/Currency";

export interface IBookingPromoCodeApplyResultInfo {
    promoCodeId?: string;
    discount?: ICurrency;
    prepaymentDiscount?: ICurrency;
    hotelFeatureNames?: string[];
    promoCodeType?: PromoCodeType;
    result: PromoCodeResult;
}

export interface IBookingPromoCodeApplyBackendResultInfo {
    promoCodeId?: string;
    discount?: ICurrency;
    hotelFeatureNames?: string[];
    promoCodeType?: PromoCodeType;
    searchResultType: PromoCodeBackendResultType;
}

export interface IBookingPromoCodeApplyRequestInfo {
    promoCodeName: string;
    bookings: IBookingPromoCodeParams[];
}

interface IBookingPromoCodeParams {
    checkin: string;
    checkout: string;
    rateId: string;
    roomCategoryId: string;
    guestCount: number;
    occupancyIndex: number;
}

export enum PromoCodeResult {
    Accepted = "accepted",
    Incorrect = "incorrect",
    NotActing = "notActing"
}

export enum PromoCodeBackendResultType {
    Success = "success",
    NotFound = "notFound",
    Inactive = "inactive",
    NotApplicable = "notApplicable"
}

export enum PromoCodeType {
    AccommodationDiscount = "accommodationDiscount", // скидка на проживание
    FreeHotelFeature = "freeHotelFeature" // на бесплатную услугу
}
