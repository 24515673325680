import ApiEntryPoints from "../core/node/ApiEntryPoints.js";
import WidgetStore from "../widget/WidgetStore";

export class ApiUrlBuilder {
    static buildRequestUrl(pathParts?: string[]): string {
        pathParts = pathParts || [];
        const widgetId = WidgetStore.settings && WidgetStore.settings.hotelId;
        const basePrefix = widgetId ? WidgetStore.baseUrl + ApiEntryPoints.apiV1 : ApiEntryPoints.apiV1;
        const urlParts = [basePrefix, ...pathParts.map(pp => pp.toString())];
        return urlParts.join("/");
    }
}
