import {Reducer} from "redux";
import {KnownPaymentAction, PaymentActionTypes} from "./paymentActionCreators";
import {PaymentResult} from "../../data/Payment";

export interface IPaymentState {
    error?: Error;
    isLoading: boolean;
    isPaymentCreation: boolean;
    result: PaymentResult;
}

const initialState: IPaymentState = {
    error: null,
    isLoading: false,
    isPaymentCreation: false,
    result: null
};

export const paymentReducer: Reducer<IPaymentState> = (
    state: IPaymentState = initialState,
    action: KnownPaymentAction
) => {

    switch (action.type) {
        case PaymentActionTypes.GET_PAYMENT_LINK_REQUEST:
        case PaymentActionTypes.GET_PAYMENT_RESULT_REQUEST:
            return {
                ...state,
                isLoading: true
            };

        case PaymentActionTypes.GET_PAYMENT_LINK_ERROR:
        case PaymentActionTypes.GET_PAYMENT_RESULT_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case PaymentActionTypes.GET_PAYMENT_RESULT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                result: action.response
            };

        case PaymentActionTypes.CREATE_PAYMENT_REQUEST:
            return {
                ...state,
                isLoading: true,
                isPaymentCreation: true
            };

        case PaymentActionTypes.CREATE_PAYMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isPaymentCreation: false
            };

        case PaymentActionTypes.CREATE_PAYMENT_ERROR:
            return {
                ...state,
                isLoading: false,
                isPaymentCreation: false,
                error: action.error,
                result: PaymentResult.failed
            };

        case PaymentActionTypes.GET_PAYMENT_LINK_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case PaymentActionTypes.CLEAR_PAYMENT:
            return {
                ...initialState
            };

        default:
            return state;
    }
};
