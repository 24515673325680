import styles from "./Image.scss";

const Img = (props: React.ImgHTMLAttributes<HTMLImageElement>) => {
    const {...imgProps} = props;

    return (
        <img
            className={styles.image}
            {...imgProps}
            loading={"lazy"}
        />
    );
};

Img.displayName = "Img";
export default Img;