import styles from "./HourlyObjectsCard.scss";
import {DeviceContext} from "../../providers/Device/DeviceContext";
import {Compare} from "../../helpers/CompareHelper";
import HourlyObjectEmpty from "../../images/empty/HourlyObjectEmpty.png"
import PlacementParameters from "../PlacementParameters/PlacementParameters";
import {IHourlyObject} from "../../data/HourlyObjects";
import SliderWithGallery from "../SliderWithGallery/SliderWithGallery";
import WidgetStore from "../../widget/WidgetStore";
import cn from "classnames";

interface IHourlyObjectsCardProps {
    hourlyObject: IHourlyObject;
    hourlyObjectItemId?: string;
    title?: string;
    autoPlaySlider?: boolean;
    isListView?: boolean;
}

const HourlyObjectsCard = (props: React.PropsWithChildren<IHourlyObjectsCardProps>) => {
    const {hourlyObject, title, children, autoPlaySlider, hourlyObjectItemId, isListView} = props;

    const {isMobileMode} = React.useContext(DeviceContext);

    const {name, imageMetas, capacity, description, items} = hourlyObject;
    const item = hourlyObjectItemId && items?.find(el => el.id === hourlyObjectItemId);
    const objectTitle = <div className={styles.title}>{title || item?.name || name}</div>;

    return (
        <div className={styles.container}>
            {isMobileMode && objectTitle}
            <div className={cn(styles.sliderContainer, {[styles.sliderContainerInList]: isListView})}>
                <SliderWithGallery
                    autoPlay={autoPlaySlider}
                    imageMetas={[...imageMetas].sort(Compare.byOrder)}
                    emptyImage={WidgetStore.getUrlWithBase(HourlyObjectEmpty)}
                />
            </div>
            <div className={styles.descriptionInfo}>
                {!isMobileMode && objectTitle}
                {capacity > 0 && (
                    <div className={styles.parameters}>
                        <PlacementParameters capacity={capacity} />
                    </div>
                )}
                {description && (
                    <div className={styles.description}>
                        {item?.description || description}
                    </div>
                )}
            </div>
            <div className={styles.content}>{children}</div>
        </div>
    );
};
HourlyObjectsCard.displayName = "HourlyObjectsCard";
export default React.memo(HourlyObjectsCard);
