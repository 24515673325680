export type WidgetContainerDomValidationResult = (
    WidgetValidationResult.ContainerIncorrectType |
    WidgetValidationResult.ContainerNotFound |
    WidgetValidationResult.ContainerIsHidden |
    WidgetValidationResult.Ok
);

export type WidgetEnumValidationResult = (
    WidgetValidationResult.Ok |
    WidgetValidationResult.EnumError
);

export type WidgetSettingsContainerValidationResult = (
    WidgetValidationResult.Ok |
    WidgetValidationResult.ContainerIsEmpty
);

export enum WidgetValidationResult {
    ContainerIsEmpty = "ContainerIsEmpty",
    ContainerIncorrectType = "ContainerIncorrectType",
    ContainerNotFound = "ContainerNotFound",
    ContainerIsHidden = "ContainerIsHidden",

    EnumError = "EnumError",
    Ok = "Ok",
    Error = "Error"
}
