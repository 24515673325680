import {Flipper} from "react-flip-toolkit";
import {AccommodationsContext} from "../../../Accommodations/AccommodationsContext";
import {SelectedAccommodationsHelper} from "../../../Accommodations/helpers/SelectedAccommodationsHelper";
import {BookingLightboxStepsContext} from "../Steps/BookingLightboxStepsContext";
import {ISelectedAccommodationBooking} from "../../../Accommodations/AccommodationsMaps";
import {BookingLightboxFlipperHelper, IBookingLightboxFlipperState} from "./BookingLightboxFlipperHelper";

const BookingLightboxFlipper = (props: React.PropsWithChildren<object>) => {
    const {children} = props;

    const {step} = React.useContext(BookingLightboxStepsContext);
    const {selectedAccommodationsMap} = React.useContext(AccommodationsContext);

    const prevBookingsRef = React.useRef<ISelectedAccommodationBooking[]>();
    const bookings = React.useMemo(() => (
        SelectedAccommodationsHelper.toSelectedAccommodationBookings(selectedAccommodationsMap)
    ), [selectedAccommodationsMap]);

    const changedAccommodation = React.useMemo(() => {
        const result = BookingLightboxFlipperHelper.getChangedAccommodation(prevBookingsRef.current, bookings);
        prevBookingsRef.current = bookings;
        return result;
    }, [bookings]);

    return (
        <Flipper
            flipKey={`${step}_${JSON.stringify(bookings)}`}
            decisionData={{step, changedAccommodation} as IBookingLightboxFlipperState}
        >
            {children}
        </Flipper>
    );
};
BookingLightboxFlipper.displayName = "BookingLightboxFlipper";
export default React.memo(BookingLightboxFlipper);
