import {useRef} from "@skbkontur/hotel-hooks/react";
import {ISliderImage} from "../types/SliderImage";

interface IUseDotIndexList {
    images: ISliderImage[];
    slideNumber: number;
}

const DOT_MAX_COUNT = 7;

export const useSliderDotIndexList = (props: IUseDotIndexList): number[] => {
    const {slideNumber, images} = props;
    const slidesCount = images.length;

    const [indexRef, setIndexRef] = useRef({
        startIndex: 0,
        endIndex: Math.min(DOT_MAX_COUNT, slidesCount),
    });

    const dotIndexList = images.map((_, index) => index);

    const countMax = Math.min(DOT_MAX_COUNT, slidesCount);
    const {startIndex, endIndex} = indexRef.current;
    let newStartIndex = startIndex;
    let newEndIndex = endIndex;

    const withLeftOffset = slideNumber <= startIndex && startIndex !== 0;
    if (withLeftOffset) {
        newStartIndex = slideNumber - 1 > 0 ? slideNumber - 1 : 0;
        newEndIndex = newStartIndex + countMax;
        setIndexRef({startIndex: newStartIndex, endIndex: newEndIndex});
    }

    const withRightOffset = slideNumber + 1 >= endIndex && endIndex !== slidesCount;
    if (withRightOffset) {
        newEndIndex = slidesCount <= slideNumber + 1 ? slidesCount : slideNumber + 2;
        newStartIndex = newEndIndex - countMax;
        setIndexRef({startIndex: newStartIndex, endIndex: newEndIndex});
    }

    return dotIndexList.filter(index => newStartIndex <= index && index < newEndIndex);
};
