export const WithProvider =
    <TProps extends object, TProviderProps extends object>(
        Component: React.ComponentType<TProps>,
        Provider: React.ComponentType<React.PropsWithChildren<TProviderProps>>,
    ) =>
    // eslint-disable-next-line react/display-name
    (props: TProps & TProviderProps) => (
        <Provider {...props}>
            <Component {...props} />
        </Provider>
    );
