export default class CssHelper {

    static copyCssRule(classNameToCopy: string, classNameToPaste: string): boolean {
        const cssRuleAndSheet = this.getCssClassRuleAndSheet(classNameToCopy);
        if (!cssRuleAndSheet) return false;

        const { cssRule, styleSheet } = cssRuleAndSheet;
        return !!styleSheet.insertRule(cssRule.replace(classNameToCopy, classNameToPaste), 0);
    }

    private static getCssClassRuleAndSheet(className: string): { cssRule: string, styleSheet: CSSStyleSheet } {
        const selectorToFind = `.${className}`;
        const styleSheets = Array.from(document.styleSheets);
        for (const styleSheet of styleSheets) {
            try {
                for (const cssRule of Array.from(styleSheet.cssRules) as CSSStyleRule[]) {
                    if (cssRule.selectorText === selectorToFind) {
                        return {
                            cssRule: cssRule.cssText || cssRule.style.cssText,
                            styleSheet
                        };
                    }
                }
            } catch { /* do nothing */ }
        }
        return null;
    }
}
