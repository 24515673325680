import {IAppState} from "../../store/AppState";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "@skbkontur/i18n";
import ErrorLightbox from "../ErrorLightbox/ErrorLightbox";
import {clearGlobalError} from "../../store/globalError/actions";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";

const GlobalErrorLightbox = () => {
    const {tcn} = useTranslation(TranslationNamespaces.Common);
    const globalError = useSelector((state: IAppState) => state.globalError);

    const dispatch = useDispatch();
    const handleClose = () => dispatch(clearGlobalError());

    return (
        globalError?.ExceptionNumber && (
            <ErrorLightbox title={tcn("GlobalErrorLightbox.title")} onClose={handleClose}>
                <div>
                    <div>
                        {tcn("GlobalErrorLightbox.tryAgainMOB", {raw: true})}
                    </div>
                </div>
            </ErrorLightbox>
        )
    );
};
GlobalErrorLightbox.displayName = "GlobalErrorLightbox";
export default GlobalErrorLightbox;
