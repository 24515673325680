import {tracker} from "@skbkontur/km-tracker";
import {AnalyticsAction, AnalyticsCategory, CustomAnalyticsActionVariables} from "./Analytics";

// TODO можно объединить с отелем после релиза единого фронта
export class BaseAnalyticsEvents {
    category: AnalyticsCategory;

    constructor(category: AnalyticsCategory) {
        this.category = category;
    }

    protected trackEvent = (action: AnalyticsAction, label: string, customVariables?: CustomAnalyticsActionVariables) => {
        tracker.trackEvent(this.category, action, label, customVariables);
    };
}
