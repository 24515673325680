import {DeviceMode, DeviceSize} from "../../providers/Device/DeviceContext";

const LIGHTBOX_MARGINS = 60;
const LIGHTBOX_MARGINS_MOBILE_LARGE = 40;
const LIGHTBOX_MARGINS_MOBILE = 20;
const LIGHTBOX_MAX_WIDTH = 540;

export class ErrorLightboxHelper {
    static getWidth = (deviceMode: DeviceMode) => {
        const width = window.innerWidth;
        switch (deviceMode) {
            case DeviceMode.MobileLarge:
                return Math.min(width - LIGHTBOX_MARGINS_MOBILE_LARGE, LIGHTBOX_MAX_WIDTH);
            case DeviceMode.Mobile:
                return Math.min(DeviceSize.Mobile - LIGHTBOX_MARGINS_MOBILE, LIGHTBOX_MAX_WIDTH);
            default:
                return Math.min(width - LIGHTBOX_MARGINS, LIGHTBOX_MAX_WIDTH);
        }
    };
}
