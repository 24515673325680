// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yhfhTE{display:flex;flex-direction:column;gap:16px;padding:4px;width:220px}.N6EtmN{display:grid;grid-template-columns:1fr 90px;grid-gap:8px;align-items:center;gap:8px}.f6hntJ{align-items:center;background-color:#f0f0f0;border-radius:10px;box-sizing:border-box;display:flex;justify-content:center;padding:4px;width:100%}", "",{"version":3,"sources":["webpack://./src/components/GuestsAccommodationTooltip/GuestsAccommodationTooltip.scss","webpack://./src/scss/colors.scss"],"names":[],"mappings":"AAEA,QAGI,YAAA,CACA,qBAAA,CACA,QAAA,CAJA,WAAA,CACA,WAGA,CAGJ,QACI,YAAA,CACA,8BAAA,CACA,YAAA,CACA,kBAAA,CADA,OACA,CAGJ,QAKI,kBAAA,CAEA,wBCrByB,CDsBzB,kBAAA,CAPA,qBAAA,CAGA,YAAA,CAEA,sBAAA,CAHA,WAAA,CADA,UAMA","sourcesContent":["@import \"../../scss/colors\";\r\n\r\n.tooltip {\r\n    padding: 4px;\r\n    width: 220px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 16px;\r\n}\r\n\r\n.tooltipRow {\r\n    display: grid;\r\n    grid-template-columns: 1fr 90px;\r\n    gap: 8px;\r\n    align-items: center;\r\n}\r\n\r\n.characteristic {\r\n    box-sizing: border-box;\r\n    width: 100%;\r\n    padding: 4px;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    background-color: $grayscale-light-background;\r\n    border-radius: 10px;\r\n}","$grayscale-background: #e6e6e6;\r\n$grayscale-border: #e7e7e7;\r\n$grayscale-hover: #e3e3e3;\r\n$grayscale-light-background: #f0f0f0;\r\n$grayscale-text: #222;\r\n$grayscale-secondary-text: #757575;\r\n$react-ui-focus-color: #1d85d0;\r\n$main-error-text: #cb3d35;\r\n$main-success-background: #d7f8ae;\r\n$grayscale-disabled-text: #adadad;\r\n$blue-hover-color: #1874cf;\r\n$text-color-description: #333;\r\n$border-gray-color: #e7e7e7;\r\n$link-color: #4e93c8;\r\n\r\n$white-color: #fff;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "yhfhTE",
	"tooltipRow": "N6EtmN",
	"characteristic": "f6hntJ"
};
export default ___CSS_LOADER_EXPORT___;
