import {concat, sortBy} from "lodash";
import {
    IAccommodation,
    IAccommodationPrice,
    IAccommodationRestrictionsRecommendations,
    IRoomCategoryAccommodation
} from "../../../data/Accommodation";

export class AccommodationRestrictionsHelper {
    static extractAccommodationsWithRestrictions = (accommodations: IRoomCategoryAccommodation[]) => (
        accommodations ? this.extractAccommodations(accommodations, true) : accommodations
    );

    static extractAccommodationsWithoutRestrictions = (accommodations: IRoomCategoryAccommodation[]) => (
        accommodations ? this.extractAccommodations(accommodations, false) : accommodations
    );

    private static extractAccommodations = (
        roomCategoryAccommodations: IRoomCategoryAccommodation[],
        onlyRestricted: boolean
    ): IRoomCategoryAccommodation[] => (
        roomCategoryAccommodations.reduce((acc, item) => {
            const isRestrictedRoomCategory = this.isRestrictedRoomCategory(item);
            if (isRestrictedRoomCategory === onlyRestricted) {
                const {accommodations} = item;
                acc.push({
                    ...item,
                    accommodations: accommodations.reduce((acc, item) => {
                        const {accommodationPrices} = item;
                        const isRestrictedAccommodation = this.isRestrictedAccommodation(item);
                        if (isRestrictedAccommodation === onlyRestricted) {
                            acc.push({
                                ...item,
                                accommodationPrices: accommodationPrices.filter(ap => (
                                    this.isPriceHasRestrictions(ap) === onlyRestricted
                                ))
                            });
                        }
                        return acc;
                    }, [] as IAccommodation[])
                });
            }
            return acc;
        }, [] as IRoomCategoryAccommodation[])
    );

    static isRestrictedRoomCategory = (roomCategoryAccommodation: IRoomCategoryAccommodation) => (
        roomCategoryAccommodation.accommodations.every(this.isRestrictedAccommodation)
    );

    static isRestrictedAccommodation = (accommodation: IAccommodation) => (
        accommodation.accommodationPrices.every(this.isPriceHasRestrictions)
    );

    private static isPriceHasRestrictions = (price: IAccommodationPrice) => !!price.restrictionsRecommendations;

    static getMinSeverityRestrictions = (accommodations: IAccommodation[]): IAccommodationRestrictionsRecommendations => {
        const allRestrictions = concat(...accommodations.map(a => a.accommodationPrices.map(p => p.restrictionsRecommendations)));
        const restrictionsBySeverity = sortBy<IAccommodationRestrictionsRecommendations>(allRestrictions, this.getRestrictionsSeverity);
        return restrictionsBySeverity[0];
    };

    private static getRestrictionsSeverity = (restrictions: IAccommodationRestrictionsRecommendations) => {
        const {
            arrivalDateDifferenceInDays,
            departureDateDifferenceInDays,
            maxStayDifferenceInDays,
            minStayDifferenceInDays
        } = restrictions;

        return (
            arrivalDateDifferenceInDays
            + departureDateDifferenceInDays
            + maxStayDifferenceInDays
            + minStayDifferenceInDays
        );
    };
}
