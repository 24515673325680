import {IAppState} from "../../store/AppState";
import {useSelector} from "react-redux";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import {Tooltip} from "@skbkontur/react-ui";
import HourlyObjectsListAvailableTime from "./HourlyObjectsListAvailableTime";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {useTranslation} from "@skbkontur/i18n";
import cn from "classnames";
import {WidgetClientGlobalStyles} from "../../widget/WidgetClientGlobalStyles";
import WidgetStore from "../../widget/WidgetStore";
import styles from "./HourlyObjectsList.scss";

interface IHourlyObjectsListCheckButtonProps {
    hourlyObjectId: string;
    hourlyObjectItemId?: string;
    onClick: () => void;
}

const HourlyObjectsListCheckButton = (props: IHourlyObjectsListCheckButtonProps) => {
    const {hourlyObjectId, hourlyObjectItemId, onClick} = props;

    const [isOpenTooltip, setIsOpenTooltip] = React.useState(false);
    const isLoading = useSelector((state: IAppState) => state.hourlyObjects.isLoading);

    const {t} = useTranslation(TranslationNamespaces.BookingModule);

    const btnClasses = cn({
        [WidgetClientGlobalStyles.WidgetCardPriceButtonContainer]: WidgetStore.isWidget(),
    });

    const trigger = isOpenTooltip ? "opened" : "closed";

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsOpenTooltip(true);
    };

    const handleOnclick = () => {
        setIsOpenTooltip(false);
        onClick();
    };

    return (
        <Tooltip
            closeButton={false}
            className={styles.tooltip}
            allowedPositions={[
                "bottom left",
                "bottom center",
                "top left",
                "top center",
                "top right",
                "left bottom",
                "left middle",
                "left top",
            ]}
            trigger={trigger}
            onCloseRequest={() => setIsOpenTooltip(false)}
            render={() => (
                <HourlyObjectsListAvailableTime
                    hourlyObjectId={hourlyObjectId}
                    hourlyObjectItemId={hourlyObjectItemId}
                    onClick={handleOnclick}
                />
            )}>
            <div className={btnClasses}>
                <PrimaryButton loading={isLoading} onClick={handleClick}>
                    {t("checkAvailability")}
                </PrimaryButton>
            </div>
        </Tooltip>
    );
};
HourlyObjectsListCheckButton.displayName = "HourlyObjectsListCheckButton";
export default HourlyObjectsListCheckButton;
