import {CustomThunkAction} from "../ThunkAction";
import {IRoomCategoryAccommodation} from "../../data/Accommodation";
import {IRoomCategorySearchParams} from "../../data/SearchParams";

export enum ActionTypes {
    GET_ACCOMMODATIONS_REQUEST = "accommodations/GET_ACCOMMODATIONS_REQUEST",
    GET_ACCOMMODATIONS_SUCCESS = "accommodations/GET_ACCOMMODATIONS_SUCCESS",
    GET_ACCOMMODATIONS_ERROR = "accommodations/GET_ACCOMMODATIONS_ERROR",

    CLEAR_ACCOMMODATIONS = "accommodations/CLEAR_ACCOMMODATIONS"
}

interface IAccommodationsRequestAction {
    type: ActionTypes.GET_ACCOMMODATIONS_REQUEST;
}

interface IAccommodationsSuccessAction {
    type: ActionTypes.GET_ACCOMMODATIONS_SUCCESS;
    response: IRoomCategoryAccommodation[];
}

interface IAccommodationsErrorAction {
    type: ActionTypes.GET_ACCOMMODATIONS_ERROR;
    error: Error;
}

interface IAccommodationsClearAction {
    type: ActionTypes.CLEAR_ACCOMMODATIONS;
}

export type KnownAccommodationsAction = IAccommodationsRequestAction
    | IAccommodationsSuccessAction
    | IAccommodationsErrorAction
    | IAccommodationsClearAction;

export const getAccommodations = (params: IRoomCategorySearchParams): CustomThunkAction<IRoomCategoryAccommodation[]> => (
    // @ts-expect-error Use new Redux instead of fixing this types
    (dispatch, getState, {dailyApi}) => dispatch({
        type: {
            REQUEST: ActionTypes.GET_ACCOMMODATIONS_REQUEST,
            SUCCESS: ActionTypes.GET_ACCOMMODATIONS_SUCCESS,
            FAILURE: ActionTypes.GET_ACCOMMODATIONS_ERROR
        },
        asyncAction: dailyApi.getAccommodations(params)
    })
);

export const clearAccommodations = () => ({type: ActionTypes.CLEAR_ACCOMMODATIONS});
