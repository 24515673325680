import styles from "./RoomCategoryCard.scss";
import {useRoomCategory} from "../../hooks/useRoomCategory";
import {Compare} from "../../helpers/CompareHelper";
import SliderWithGallery from "../SliderWithGallery/SliderWithGallery";
import {
    RoomCategoryCharacteristics,
    RoomCategoryCharacteristicsAmenities,
    RoomCategoryCharacteristicsRooms
} from "@skbkontur/hotel-components/roomCategoryCharacteristics";
import RoomCategoryEmpty from "../../images/empty/RoomCategoryEmpty.png";
import WidgetStore from "../../widget/WidgetStore";

interface IRoomCategoryCardProps {
    id: string;
    title?: string;
    autoPlaySlider?: boolean;
}

const RoomCategoryCard = (props: React.PropsWithChildren<IRoomCategoryCardProps>) => {
    const {id, title, children, autoPlaySlider} = props;

    const roomCategory = useRoomCategory(id);
    const {name, description, imageMetas} = roomCategory;

    return (
        <div className={styles.container}>
            <div className={styles.sliderContainer}>
                <SliderWithGallery
                    autoPlay={autoPlaySlider}
                    imageMetas={[...imageMetas].sort(Compare.byOrder)}
                    emptyImage={WidgetStore.getUrlWithBase(RoomCategoryEmpty)}
                />
            </div>
            <div className={styles.characteristics}>
                <div className={styles.title}>{title || name}</div>
                <RoomCategoryCharacteristicsRooms category={roomCategory} />
                <RoomCategoryCharacteristics category={roomCategory} modifyImageUrl={WidgetStore.getUrlWithBase} />
                {description && (
                    <div className={styles.description}>
                        {description}
                    </div>
                )}
                <RoomCategoryCharacteristicsAmenities category={roomCategory} modifyImageUrl={WidgetStore.getUrlWithBase} />
            </div>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    );
};
RoomCategoryCard.displayName = "RoomCategoryCard";
export default React.memo(RoomCategoryCard);
