import {AvailabilitiesDailyType} from "../Availability";
import {CalendarDataMapType} from "../Calendar/Calendar";
import {Currency} from "../../common/helpers/Currency";
import DateHelper from "../../helpers/DateHelper";
import {DateFormats} from "../../types/DateFormats";

export class RoomAvailabilityCalendarPipes {
    static toFrontend = (availabilities: AvailabilitiesDailyType, offsetInMinutes: number): CalendarDataMapType => {
        return Object.keys(availabilities).reduce((calendarData, timestamp) => {
            const date = DateHelper.convertWithTimezone(
                timestamp, {
                    formatIn: DateFormats.UnixMsTimestamp,
                    formatOut: DateFormats.FullDateYearFirst,
                    offsetInMinutes
                }
            );

            const yearMonth = DateHelper.convertWithTimezone(
                date, {
                    formatIn: DateFormats.FullDateYearFirst,
                    formatOut: DateFormats.MonthWithYear,
                    offsetInMinutes
                }
            );

            const dayNumber = DateHelper.getDayNumber(date, DateFormats.FullDateYearFirst);

            calendarData[yearMonth] = calendarData[yearMonth] ?? {};
            calendarData[yearMonth][dayNumber] = {
                description: Currency.getString(availabilities[timestamp].price),
            };
            return calendarData;
        }, {});
    };
}
