import Fetcher from "../core/Fetcher";
import {ApiSiteBase} from "./apiSiteBase";
import {IPaymentTerminal} from "../data/Payment";

export class PaymentTerminalApi extends ApiSiteBase {
    getTerminals(): Promise<IPaymentTerminal[]> {
        const retryConfig = {
            retries: 10,
            delay: 1000
        };
        return Fetcher.get(this.buildRequestUrl(), null, {retryConfig});
    }
}

// TODO причесать роуты (выделить одну часть маршрута), когда выделится общая инфраструктура фронтенда
export default new PaymentTerminalApi("payment-source/terminals");
