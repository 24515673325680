import {FlippedProps} from "flip-toolkit/lib/types";
import {FlipperContext, getFlipId} from "../FlipperContext";
import {Flipped} from "react-flip-toolkit";
import styles from "./FlippedElement.scss";
import {useMemoObject} from "../../../hooks/useMemoObject";

interface IFlippedElementProps extends FlippedProps {
    /**
     * Use this prop for flipped nodes that contains other children FlippedElement's
     * (to share parentFlipId and delay info)
     */
    withContext?: boolean;
    /**
     * Use this prop if you wrap non-html element like a ReactComponent
     * (because Flipped applies transition style to children html-element)
     */
    withDiv?: boolean;
    /**
     * Use this prop if children FlippedElements should wait the end of this node animation
     * (useful for "cascade" animation effect when children wait parent)
     */
    withDelay?: boolean;
    /**
     *  Use this prop to compensate translation of this FlippedElement for children elements
     *  (if you want to animate this node but dont want to apply translation to children elements)
     */
    withInverseParent?: boolean;
    inline?: boolean;
}

const FlippedElement = (props: React.PropsWithChildren<IFlippedElementProps>) => {
    const {
        withContext,
        withDiv,
        children,
        withDelay,
        withInverseParent,
        inline,
        flipId: childFlipId,
        ...flippedProps
    } = props;

    const {parentFlipId = "", delayedFlipId: parentDelayedFlipId} = React.useContext(FlipperContext);
    const flipId = getFlipId(parentFlipId, childFlipId);

    const contextValue = useMemoObject({
        parentFlipId: flipId,
        delayedFlipId: withDelay ? flipId : parentDelayedFlipId
    });

    const className = inline ? styles.inline : "";

    let content = (
        <Flipped flipId={flipId} {...flippedProps} delayUntil={parentDelayedFlipId}>
            {withDiv
                ? <div className={className}>{children}</div>
                : children}
        </Flipped>
    );

    content = withInverseParent ? (
        <Flipped inverseFlipId={parentFlipId}>
            <div className={className}>{content}</div>
        </Flipped>
    ) : content;

    return withContext
        ? (
            <FlipperContext.Provider value={contextValue}>
                {content}
            </FlipperContext.Provider>
        ) : content;
};
FlippedElement.displayName = "FlippedElement";
export default FlippedElement;
