import {IHotelSearchModel} from "../../api/hotelSearch";
import {IPlaceSearchParams} from "../../data/SearchParams";
import {CustomThunkAction} from "../ThunkAction";

export enum ActionTypes {
    SEARCH_REQUEST = "hotelsSearchStore/SEARCH_REQUEST",
    SEARCH_ERROR = "hotelsSearchStore/SEARCH_ERROR",
    SEARCH_SUCCESS = "hotelsSearchStore/SEARCH_SUCCESS",
}

interface ISearchHotelsRequestAction {
    type: ActionTypes.SEARCH_REQUEST;
}

interface ISearchHotelsSuccessAction {
    type: ActionTypes.SEARCH_SUCCESS;
    response: IHotelSearchModel[];
}

interface ISearchHotelsErrorAction {
    type: ActionTypes.SEARCH_ERROR;
    error: Error;
}

export type KnownHotelsSearchAction = (
    ISearchHotelsRequestAction
    | ISearchHotelsSuccessAction
    | ISearchHotelsErrorAction
    );

export const hotelSearch = (params: IPlaceSearchParams): CustomThunkAction<IHotelSearchModel[]> => (
    // @ts-expect-error Use new Redux instead of fixing this types
    (dispatch, getState, {hotelSearchApi}) => dispatch({
        type: {
            REQUEST: ActionTypes.SEARCH_REQUEST,
            SUCCESS: ActionTypes.SEARCH_SUCCESS,
            FAILURE: ActionTypes.SEARCH_ERROR
        },
        asyncAction: hotelSearchApi.search(params)
    })
);
