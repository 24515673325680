import {Reducer} from "redux";
import {ActionTypes, KnownRoomCategoriesAction} from "./roomCategoriesActionCreators";
import {IRoomCategory} from "@skbkontur/hotel-data/roomCategory";

export interface IRoomCategoriesState {
    isLoading: boolean;
    roomCategories: IRoomCategory[];
    // TODO AxiosResponseError
    error?: Error;
}

export const defaultState: IRoomCategoriesState = {
    isLoading: false,
    roomCategories: [],
    error: null
};

export const roomCategoriesReducer: Reducer<IRoomCategoriesState> = (state: IRoomCategoriesState = defaultState, action: KnownRoomCategoriesAction) => {
    switch (action.type) {
        case ActionTypes.GET_ROOM_CATEGORIES_REQUEST: {
            return {
                ...state,
                isLoading: true
            };
        }

        case ActionTypes.GET_ROOM_CATEGORIES_ERROR: {
            // TODO toast "ERROR"
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }

        case ActionTypes.GET_ROOM_CATEGORIES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                roomCategories: action.response
            };
        }

        default:
            return state;
    }
};
