import {Guid} from "../guid";

/**
 * Render key is needed in dynamic list case for correct work React with our form engine
 * This is only frontend field and should be ignored on backend side or removed in PipeHelper before sending to backend
 * Use this in React components like `key={item.renderKey}`
 */

export interface IWithRenderKey {
    renderKey?: string;
}

export const getRenderKey = () => Guid.getUuid(4, 16);

export const withRenderKey = <TValue extends object>(value: TValue): TValue & IWithRenderKey => {
    const {renderKey} = value as IWithRenderKey;
    return {
        ...value,
        renderKey: renderKey || getRenderKey()
    };
};

export const withoutRenderKey = <TValue extends object>(value: TValue & IWithRenderKey): TValue => {
    const {renderKey, ...withoutRenderKeyValue} = value;
    return withoutRenderKeyValue as TValue;
};

export const RenderKeyPath = (p: IWithRenderKey) => p.renderKey;