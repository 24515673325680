export class StringUtils {

    static isNullOrEmpty = (str: string) => str === null || str === undefined || str === "" || str === "undefined" || str === "null";

    static insertLeadingZero = (num: number | string, len: number): string => {
        const n = num.toString();
        const arr: string[] = [];
        for (let i = 0; i < (len - n.length); ++i)
            arr[arr.length] = "0";
        arr[arr.length] = n;
        return arr.join("");
    };
}
