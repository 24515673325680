import {ICurrency} from "../types/Currency";
import {IImageMeta} from "@skbkontur/hotel-data/image";

export interface IHotelFeature {
    id: string;
    name: string;
    measure: Measure;
    comment: string;
    cost: ICurrency;
    imageMetas?: IImageMeta[];
    type: HotelFeatureAdditionType;
    isDeleted: boolean;
    rateIds: string[];
}

export enum Measure {
    Counter = "counter",
    Hour = "hour",
    Day = "day",
}

export enum HotelFeatureAdditionType {
    OnceForBooking = "onceForBooking",
    ByDayAndGuest = "byDayAndGuest",
}
