import Fetcher from "../core/Fetcher";
import {ApiSiteBase} from "./apiSiteBase";
import {UrlHelper} from "../helpers/UrlHelper";
import {IPayment, IPaymentData, IPaymentInfo} from "../data/Payment";

export interface IPaymentsCreateResponse {
    confirmationUrl: string;
    payment: IPayment;
}

export class PaymentsApi extends ApiSiteBase {
    createPayment(paymentData: IPaymentData, language: string): Promise<IPaymentsCreateResponse> {
        const {paymentSourceSettingsId, bookingIds, paymentSystem: source, contactInfo, kind} = paymentData;
        const params = {
            paymentSourceSettingsId,
            bookingIds,
            returnUrl: UrlHelper.getSiteOriginAndPathname(),
            language,
            source,
            contactInfo,
            kind
        };
        return Fetcher.postJSON(this.buildRequestUrl(), params);
    }

    getPaymentInfo(paymentId: string): Promise<IPaymentInfo> {
        return Fetcher.get(this.buildRequestUrl([paymentId]));
    }
}

export default new PaymentsApi("payments");
