import {useSelector} from "react-redux";
import {IAppState} from "../store/AppState";

interface IUsePaymentSystem {
    isCashback: boolean;
    isPaymentSystemEnabled: boolean;
    setIsCashback: React.Dispatch<React.SetStateAction<boolean>>;
}

export const usePaymentSystem = (): IUsePaymentSystem => {
    const [isCashback, setIsCashback] = React.useState<boolean>(false);

    const terminals = useSelector((state: IAppState) => state.paymentTerminals.terminals);
    const isPaymentSystemEnabled = terminals?.some(st => st.isAvailable && st.isCashback === isCashback);

    return {
        isCashback,
        isPaymentSystemEnabled,
        setIsCashback
    };
};
