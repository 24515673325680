import H from "history";
import qs from "qs";
import {UrlHelper} from "./UrlHelper";
import {KnownSearchParams} from "../data/SearchParams";

const KEYWORDS = {
    true: true,
    false: false,
    // @ts-expect-error null is target value here
    null: null,
};

const decoder = (value: string): unknown => {
    if (/^(\d+|\d*\.\d+)$/.test(value)) {
        return parseFloat(value);
    }
    if (value in KEYWORDS) {
        return KEYWORDS[value];
    }
    if (typeof value === "string") {
        return decodeURIComponent(value);
    }
    return value;
};

// TODO перевести на стандартный механизм react-router
export class UrlParamsExtractor {
    static getSearchParams<TParams extends KnownSearchParams>(location: H.Location): TParams {
        const stringParams = UrlHelper.getSearchParams(location);
        const {t, paymentId, ...resultParams} = qs.parse(stringParams, {decoder});
        return Object.keys(resultParams).length > 0 ? resultParams as unknown as TParams : null;
    }

    static getPaymentId(location: H.Location): string {
        const stringParams = UrlHelper.getSearchParams(location);
        const {paymentId} = qs.parse(stringParams);
        return (paymentId as string) || null;
    }
}
