import {HourlyAccommodationsContext} from "./HourlyAccommodationsContext";
import {IAppState} from "../../store/AppState";
import {useSelector} from "react-redux";
import {useMemoObject} from "../../common/hooks/useMemoObject";
import {IHourlyBookingAccommodation, IHourlyAccommodationPrice} from "../../data/HourlyBooking";
import {HourlyBookingLightboxHelper} from "../HourlyBooking/HourlyBookingLightbox/HourlyBookingLightboxHelper";
import {getRenderKey} from "@skbkontur/hotel-data/renderKey";

interface IAccommodationsState {
    accommodationPrices: IHourlyAccommodationPrice[];
    selectedAccommodation: IHourlyBookingAccommodation;
}

const initialState: IAccommodationsState = {
    accommodationPrices: null,
    selectedAccommodation: {
        hourlyObjectId: "",
        bookingFeatures: {},
        hourlyRateId: "",
    },
};

const HourlyAccommodationsProvider = (props: React.PropsWithChildren<object>) => {
    const {children} = props;

    const {accommodations: reduxAccommodations} = useSelector(
        (state: IAppState) => state.hourlyObjectsAccommodations
    );

    const [state, setState] = React.useState<IAccommodationsState>(initialState);
    const {selectedAccommodation, accommodationPrices} = state;
    const {hourlyObjectId: hourlyObjectIdState, hourlyObjectItemId: hourlyObjectItemIdState} = selectedAccommodation;

    React.useEffect(() => {
        if (!reduxAccommodations) return;
        const accommodation = HourlyBookingLightboxHelper.getAccommodation(reduxAccommodations, selectedAccommodation.hourlyObjectId);
        let accommodationPrices = accommodation?.accommodations || []

        if(hourlyObjectItemIdState) {
            const isAvailableHourlyItem = accommodation?.freeHourlyObjectItemIds.includes(hourlyObjectItemIdState);

            accommodationPrices = isAvailableHourlyItem ? accommodationPrices : [];
        }

        setState((state: IAccommodationsState) => ({
            ...state,
            selectedAccommodation: {
                ...state.selectedAccommodation,
                hourlyRateId: "",
                bookingFeatures: {},
            },
            accommodationPrices,
        }));
    }, [reduxAccommodations, hourlyObjectIdState, hourlyObjectItemIdState]);

    const updateAccommodation = (selectedAccommodation: Partial<IHourlyBookingAccommodation>) => {
        setState((state: IAccommodationsState) => ({
            ...state,
            selectedAccommodation: {
                ...state.selectedAccommodation,
                ...selectedAccommodation,
                renderKey: state.selectedAccommodation.renderKey || getRenderKey(),
            },
        }));
    };

    return (
        <HourlyAccommodationsContext.Provider
            value={useMemoObject({
                accommodationPrices,
                selectedAccommodation,
                updateAccommodation,
            })}
        >
            {children}
        </HourlyAccommodationsContext.Provider>
    );
};
HourlyAccommodationsProvider.displayName = "HourlyObjectsAccommodationsProvider";
export default HourlyAccommodationsProvider;
