import {ApiBase} from "./apiBase";
import Fetcher from "../core/Fetcher";
import {IPlaceSearchParams} from "../data/SearchParams";

export interface IHotelSearchApi {
    suggest: (place: string) => Promise<string[]>;
    search: (params: IPlaceSearchParams) => Promise<IHotelSearchModel[]>;
}

export interface IHotelSearchModel {
    alias: string;
    name: string;
    address: string;
    thumbnailUrl?: string;
    description: string;
}

class HotelSearchApi extends ApiBase<string> {

    suggest(place: string): Promise<string[]> {
        const query = ApiBase.toQueryParams({place});
        return Fetcher.get(this.buildRequestUrl(["suggest"]) + query);
    }

    search(params: IPlaceSearchParams): Promise<IHotelSearchModel[]> {
        const query = ApiBase.toQueryParams({
            ...params,
            fromDate:  ApiBase.toQueryDate(params.fromDate),
            toDate:  ApiBase.toQueryDate(params.toDate)
        });
        return Fetcher.get(this.buildRequestUrl(["search"]) + query);
    }

}

export default new HotelSearchApi();
