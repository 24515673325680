import RoomCategoryCard from "../RoomCategoryCard/RoomCategoryCard";
import RoomCategoriesListCheckButton from "./RoomCategoriesListCheckButton";
import {useSelector} from "react-redux";
import {IAppState} from "../../store/AppState";
import {RoomCategorySearchParamsContext} from "../../providers/SearchParams/SearchParamsContext";
import {BookingContext} from "../../providers/Booking/BookingContext";
import {Flipper} from "react-flip-toolkit";
import RoomCategoriesListFlipper from "./RoomCategoriesListFlipper";

const RoomCategoriesList = () => {
    const {roomCategories} = useSelector((state: IAppState) => state.roomCategories);
    const {setParams} = React.useContext(RoomCategorySearchParamsContext);
    const {isBookingProcess, openBookingLightbox} = React.useContext(BookingContext);

    const searchByCategory = (roomCategoryId: string) => {
        setParams(params => ({...params, roomCategoryId}));
        openBookingLightbox();
    };

    return (
        <Flipper flipKey={JSON.stringify(roomCategories.map(rc => rc.id))}>
            {roomCategories.map(({id}, index) => (
                <RoomCategoriesListFlipper key={id} roomCategoryId={id} isLast={index === roomCategories.length - 1}>
                    <RoomCategoryCard autoPlaySlider={!isBookingProcess} id={id}>
                        <RoomCategoriesListCheckButton
                            onClick={() => searchByCategory(id)}
                        />
                    </RoomCategoryCard>
                </RoomCategoriesListFlipper>
            ))}
        </Flipper>
    );
};
RoomCategoriesList.displayName = "RoomCategoriesList";
export default RoomCategoriesList;
