import DateHelper from "../../helpers/DateHelper";
import {DateFormats} from "../../types/DateFormats";
import {IAvailabilitiesHourlySearchParams, IHourlyObjectSearchParams} from "../../data/SearchParams";

export interface IHourlyAvailablePeriodDate {
    fromDate: string;
    toDate: string;
}

export const MAX_HOUR_IN_NEW_DAY = 12;

export class HourlyObjectsAvailableHelper {
    static getPeriodDate = (date: string,  offsetInMinutes?: number): IHourlyAvailablePeriodDate => {
        const todayInHotelTimeZone = DateHelper.getTodayWithTimezone(DateFormats.FullDateYearFirstWithTime, offsetInMinutes);

        const isToday = DateHelper.isSameDate(
            date,
            DateHelper.convert(todayInHotelTimeZone, DateFormats.FullDateYearFirstWithTime, DateFormats.FullDateDayFirst)
        );

        const isBeforeDate = DateHelper.isBeforeDateByDay(
            date,
            DateHelper.convert(todayInHotelTimeZone, DateFormats.FullDateYearFirstWithTime, DateFormats.FullDateDayFirst)
        );

        const fromDate = (isToday || isBeforeDate)
            ? todayInHotelTimeZone
            : DateHelper.convert(date,  DateFormats.FullDateDayFirst, DateFormats.FullDateYearFirstWithTime);

        const nextDay =  DateHelper.addDaysToDate(1, fromDate, DateFormats.FullDateYearFirstWithTime, DateFormats.FullDateYearFirst);

        const toDate = DateHelper.addHoursToDate(
            MAX_HOUR_IN_NEW_DAY,
            nextDay,
            DateFormats.FullDateYearFirst,
            DateFormats.FullDateYearFirstWithTime
        );

        return {fromDate, toDate};
    };

    static getAvailabilitiesHourlyParams = (searchParams: IHourlyObjectSearchParams): IAvailabilitiesHourlySearchParams => {
        const {fromDate, toDate} = HourlyObjectsAvailableHelper.getPeriodDate(searchParams.fromDate);

        return {...searchParams, fromDate, toDate};
    };
}
