import {IRate} from "../../data/Rate";
import {CustomThunkAction} from "../ThunkAction";

export enum ActionTypes {
    GET_HOURLY_RATES_REQUEST = "rates/GET_HOURLY_RATES_REQUEST",
    GET_HOURLY_RATES_SUCCESS = "rates/GET_HOURLY_RATES_SUCCESS",
    GET_HOURLY_RATES_ERROR = "rates/GET_HOURLY_RATES_ERROR",
}

interface IHourlyRatesRequestAction {
    type: ActionTypes.GET_HOURLY_RATES_REQUEST;
}

interface IHourlyRatesSuccessAction {
    type: ActionTypes.GET_HOURLY_RATES_SUCCESS;
    response: IRate[];
}

interface IHourlyRatesErrorAction {
    type: ActionTypes.GET_HOURLY_RATES_ERROR;
    error: Error;
}

export type KnownRatesAction = IHourlyRatesRequestAction | IHourlyRatesSuccessAction | IHourlyRatesErrorAction;

export const getHourlyRates = (): CustomThunkAction<IRate[]> => (
    // @ts-expect-error Use new Redux instead of fixing this types
    (dispatch, getState, {hourlyApi}) => (
        dispatch({
            type: {
                REQUEST: ActionTypes.GET_HOURLY_RATES_REQUEST,
                SUCCESS: ActionTypes.GET_HOURLY_RATES_SUCCESS,
                FAILURE: ActionTypes.GET_HOURLY_RATES_ERROR,
            },
            asyncAction: hourlyApi.getRates(),
        })
    )
);
