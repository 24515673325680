import Fetcher from "../core/Fetcher";
import qs from "qs";
import {DateFormats} from "../types/DateFormats";
import ApiEntryPoints from "../core/node/ApiEntryPoints.js";

import {dayjs} from "../data/Date";

export class ApiBase<T = unknown> {
    protected modelType: string;

    constructor(modelType: string = null) {
        this.modelType = modelType;
    }

    protected buildRequestUrl(pathParts?: string[]): string {
        pathParts = pathParts || [];
        const pathStart = this.modelType ? [ApiEntryPoints.apiV1, this.modelType] : [ApiEntryPoints.apiV1];
        const urlParts = pathStart.concat(pathParts.map(pp => pp.toString()));
        return urlParts.join("/");
    }

    create(data: T): Promise<string> {
        return Fetcher.postJSON<string>(this.buildRequestUrl(), data);
    }

    batchCreate(data: T[]): Promise<string> {
        return Fetcher.postJSON<string>(this.buildRequestUrl(["batch"]), data);
    }

    save(data: T): Promise<void> {
        return Fetcher.patchJSON<void>(this.buildRequestUrl(), data);
    }

    remove(id: string, queryParams?: object): Promise<void> {
        const url = this.buildRequestUrl([id, queryParams ? qs.stringify(queryParams) : ""]);
        return Fetcher.del(url).catch(async (error) => {
            const errorValue = await Fetcher.convert(error.response);
            return Promise.reject(errorValue);
        });
    }

    checkDelete(id: string): Promise<void> {
        return Fetcher.del(this.buildRequestUrl([id, "checkDelete"]));
    }

    static toQueryParams<T extends object>(value: T, continues?: boolean): string {
        const params = Object.keys(value)
            .filter(key => value[key] !== null && value[key] !== undefined)
            .map(key => `${key}=${encodeURIComponent(value[key].toString())}`);

        const startSymbol = continues ? "&" : "?";
        const timestampToFixIeAjaxCache = +new Date();
        return params.length > 0 ? `${startSymbol}${params.join("&")}&t=${timestampToFixIeAjaxCache}` : "";
    }

    protected static toQueryDate(firstDayDate: string): string {
        return dayjs(firstDayDate, DateFormats.FullDateDayFirst).format(DateFormats.FullDateYearFirst);
    }
}
