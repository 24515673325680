const CHARS = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
export const EMPTY_GUID_VALUE = "00000000-0000-0000-0000-000000000000";
const validator = new RegExp("^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$", "i");
const validatorLight = new RegExp("^[a-z0-9]{32}$", "i");

type GuidInitialValue = Guid | string;

export class Guid {
    private readonly value: string;
    static readonly empty = EMPTY_GUID_VALUE;

    constructor(value: GuidInitialValue) {
        if (!value)
            throw new Error("ArgumentError: 'guid'");
    
        this.value = EMPTY_GUID_VALUE;
        if (value instanceof Guid)
            this.value = value.toString();
        else if (typeof value === "string" && Guid.isGuid(value))
            this.value = value;
        else if (typeof value === "string" && validatorLight.test(value.toString())) {
            const parts = value.replace(/-/g, "").toLowerCase();
            if (parts.length !== 32)
                throw new Error("ArgumentError: 'guid'");
            this.value = "";
            for (let i = 0; i < 32; i++) {
                if (i === 8 || i === 12 || i === 16 || i === 20) {
                    this.value += "-";
                }
                this.value += parts[i];
            }
        } else
            throw new Error("ArgumentError: 'guid'");
    }

    equals(other: GuidInitialValue): boolean {
        return Guid.isGuid(other) && this.value === other.toString();
    }

    isEmpty(): boolean {
        return this.value === EMPTY_GUID_VALUE;
    }

    toString(): string {
        return this.value;
    }

    toJSON(): string {
        return this.value;
    }

    static isGuid(value: GuidInitialValue): boolean {
        return value && (value instanceof Guid || validator.test(value.toString()));
    }

    static parse(value: string): Guid {
        return new Guid(value);
    }

    static equals(first: GuidInitialValue, second: GuidInitialValue) {
        return Guid.isGuid(first) && Guid.isGuid(second) && new Guid(first).equals(new Guid(second));
    }

    /*
        * Generate a random NewGuid.
        *
        * EXAMPLES:
        *   // No arguments  - returns RFC4122, version 4 ID
        *   >>> guid.newGuid()
        *   "92329D39-6F5C-4520-ABFC-AAB64544E172"
        *
        *   // One argument - returns ID of the specified length
        *   >>> guid.newGuid(15)     // 15 character ID (default base=62)
        *   "VcydxgltxrVZSTV"
        *
        *   // Two arguments - returns ID of the specified length, and radix. (Radix must be <= 62)
        *   >>> guid.newGuid(8, 2)  // 8 character ID (base=2)
        *   "01001010"
        *   >>> guid.newGuid(8, 10) // 8 character ID (base=10)
        *   "47473046"
        *   >>> guid.newGuid(8, 16) // 8 character ID (base=16)
        *   "098F4D35"
        */

    static getUuid = (len?: number, radix?: number): string => {
        radix = radix || CHARS.length;
        let i: number;
        const uuid: string[] = [];
        if (len) {
            // Compact form
            for (i = 0; i < len; i++)
                uuid[i] = CHARS[0 | Math.random() * radix];
        } else {
            // rfc4122, version 4 form
            uuid[14] = "4";
            // rfc4122 requires these characters
            uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
            // Fill in random data.  At i==19 set the high bits of clock sequence as per rfc4122, sec. 4.1.5
            for (i = 0; i < 36; i++)
                if (!uuid[i]) {
                    const r = 0 | Math.random() * 16;
                    uuid[i] = CHARS[(i === 19) ? (r & 0x3) | 0x8 : r & 0xf];
                }
        }
        return uuid.join("");
    };

    static newGuid(len?: number, radix?: number): Guid {
        return new Guid(Guid.getUuid(len, radix));
    }

    static isEmpty(guid: string): boolean {
        return guid === EMPTY_GUID_VALUE;
    }

    static getGuidOrNullIfEmpty(guid: string) {
        return Guid.isEmpty(guid) ? null : guid;
    }
}
