import Fetcher from "../core/Fetcher";
import {
    ITranslationDuplicatesList,
    ITranslationKeyInfo,
    ITranslationKeyValue,
    ITranslationManagerApi
} from "@skbkontur/i18n";
import {ApiUrlBuilder} from "./apiUrlBuilder";

export class TranslationManagerApi implements ITranslationManagerApi {
    findDuplicates(values: ITranslationKeyValue[]): Promise<ITranslationDuplicatesList[]> {
        const url = ApiUrlBuilder.buildRequestUrl(["translations", "search"]);
        return Fetcher.postJSON(url, values);
    }

    addTranslationKeys(keys: ITranslationKeyInfo[]): Promise<void> {
        const url = ApiUrlBuilder.buildRequestUrl(["translations", "keys", "add"]);
        return Fetcher.postJSON<void>(url, keys);
    }

    changeTranslationNamespace(sourceNamespace: string, targetNamespace: string, sourceKey: string, targetKey: string): Promise<void> {
        const url = ApiUrlBuilder.buildRequestUrl(["translations", "changeNamespace"]);
        return Fetcher.postJSON<void>(url, {sourceNamespace, targetNamespace, sourceKey, targetKey});
    }

    static createTranslationsUrl(pathParts: string[]): string {
        return ApiUrlBuilder.buildRequestUrl(["translations", ...pathParts]);
    }
}

export default new TranslationManagerApi();
