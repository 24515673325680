import styles from "./PlacementParameters.scss";
import {People1Icon16Light} from "@skbkontur/icons/People1Icon16Light";

interface IPlacementParametersProps {
    capacity?: number;
}

const PlacementParameters = (props: IPlacementParametersProps) => {
    const {capacity} = props;

    return (
        <div className={styles.iconRow}>
            <span>{capacity}</span>
            <div className={styles.icon}>
                <People1Icon16Light />
            </div>
        </div>
    );
};
PlacementParameters.displayName = "PlacementParameters";
export default PlacementParameters;
