export enum DateFormats {
    FullDateDayFirst = "DD.MM.YYYY",
    FullDateYearFirst = "YYYY-MM-DD",
    FullDateDayFirstWithTime = "DD.MM.YYYY HH:mm",
    FullDateYearFirstWithTime = "YYYY-MM-DDTHH:mm",
    FullDateYearFirstWithTimeWithSeconds = "YYYY-MM-DDTHH:mm:ss",
    MonthWithYear = "YYYY-MM",
    UnixMsTimestamp = "x",
    OnlyTime = "HH:mm",
    FullDateYearFirstWithTimeWithSecondsWithTimeZone = "YYYY-MM-DDTHH:mm:ssZ",
}
