import {Reducer} from "redux";
import {AvailabilitiesHourlyType} from "../../data/Availability";
import {ActionTypes, KnownAvailabilitiesHourlyAction} from "./availabilitiesHourlyActionCreators";

export interface IAvailabilitiesHourlyState {
    isLoading: boolean;
    availabilitiesHourly: AvailabilitiesHourlyType;
    availabilitiesTo: AvailabilitiesHourlyType;
    error?: Error;
}

export const defaultState: IAvailabilitiesHourlyState = {
    isLoading: false,
    availabilitiesHourly: {},
    availabilitiesTo: {},
    error: null,
};

export const availabilitiesHourlyReducer: Reducer<IAvailabilitiesHourlyState> = (
    state: IAvailabilitiesHourlyState = defaultState,
    action: KnownAvailabilitiesHourlyAction
) => {
    switch (action.type) {
        case ActionTypes.GET_AVAILABILITIES_HOURLY_REQUEST:
        case ActionTypes.GET_AVAILABILITIES_HOURLY_TO_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ActionTypes.GET_AVAILABILITIES_HOURLY_ERROR:
        case ActionTypes.GET_AVAILABILITIES_HOURLY_TO_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case ActionTypes.GET_AVAILABILITIES_HOURLY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                availabilitiesHourly: {...state.availabilitiesHourly, ...action.response},
            };
        }
        case ActionTypes.GET_AVAILABILITIES_HOURLY_TO_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                availabilitiesTo: {...action.response},
            };
        }
        case ActionTypes.CLEAR_AVAILABILITIES_HOURLY: {
            return {
                ...state,
                availabilitiesHourly: {},
                availabilitiesTo: {}
            };
        }
        default: {
            return state;
        }
    }
};
