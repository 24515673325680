import {Modal} from "@skbkontur/react-ui";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import PrimaryButton from "../PrimaryButton/PrimaryButton";

interface IErrorBoundaryLightboxProps {
    onClose: () => void;
}

const ErrorBoundaryLightbox = (props: IErrorBoundaryLightboxProps) => {
    const {onClose} = props;

    const {t, tcn} = useTranslation(TranslationNamespaces.BookingModule);

    return  (
        <Modal disableFocusLock onClose={onClose} width={600}>
            <Modal.Header>
                {t("ErrorBoundaryLightbox.header")}
            </Modal.Header>
            <Modal.Body>
                {t("ErrorBoundaryLightbox.text", {raw: true})}
            </Modal.Body>
            <Modal.Footer>
                <PrimaryButton use="default" size="large" onClick={onClose}>
                    {tcn("buttons.tryAgain")}
                </PrimaryButton>
            </Modal.Footer>
        </Modal>
    );
};

ErrorBoundaryLightbox.displayName = "ErrorBoundaryLightbox";
export default ErrorBoundaryLightbox;
