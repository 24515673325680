import {useSelector} from "react-redux";
import {IAppState} from "../store/AppState";
import {IHourlyObject} from "../data/HourlyObjects";

export const useHourlyObjectById = (hourlyObjectsId: string): IHourlyObject => {
    const {hourlyObjects} = useSelector((state: IAppState) => state.hourlyObjects);
    return React.useMemo(() => (
        hourlyObjects.find(rc => rc.id === hourlyObjectsId) || ({} as IHourlyObject)
    ), [hourlyObjects, hourlyObjectsId]);
};
