import ItemWithBorder from "../../../../components/ItemWithBorder/ItemWithBorder";
import {Flipped} from "react-flip-toolkit";
import {FlipperHelper} from "../../../../common/components/Flipper/FlipperHelper";
import {useFlipperMountStagger} from "../../../../common/components/Flipper/useFlipperMountStagger";
import {BookingLightboxFlipperStaggers} from "./BookingLightboxFlipperStaggers";
import styles from "./BookingLightboxFlipper.scss";
import FlippedElement from "../../../../common/components/Flipper/Element/FlippedElement";
import {useShouldFlipStepsChange} from "./useShouldFlipStepsChange";

interface IBookingLightboxFlipperRoomCategoryProps {
    roomCategoryId: string;
    isLast: boolean;
}

const BookingLightboxFlipperRoomCategory = (props: React.PropsWithChildren<IBookingLightboxFlipperRoomCategoryProps>) => {
    const {roomCategoryId, children, isLast} = props;

    const stagger = useFlipperMountStagger(BookingLightboxFlipperStaggers.RoomCategory);
    const shouldFlip = useShouldFlipStepsChange();

    return (
        <FlippedElement
            flipId={roomCategoryId}
            shouldFlip={shouldFlip}
            onAppear={FlipperHelper.onAppear(styles.fadeIn500, 500)}
            stagger={stagger}
            withContext
            withDelay
            withDiv
        >
            <ItemWithBorder withoutBorder={isLast}>
                <Flipped inverseFlipId={roomCategoryId}>
                    <div>
                        {children}
                    </div>
                </Flipped>
            </ItemWithBorder>
        </FlippedElement>
    );
};
BookingLightboxFlipperRoomCategory.displayName = "BookingLightboxFlipperRoomCategory";
export default React.memo(BookingLightboxFlipperRoomCategory);
