// TODO оставить один из вариантов после того, как поменяются модели на бекенде

export interface IWithDeletedOrIsDeleted extends IWithDeleted, IWithIsDeleted {
}

export interface IWithDeleted {
    deleted?: boolean;
}

export interface IWithIsDeleted {
    isDeleted?: boolean;
}

export const getNotDeletedItems = <TItem extends IWithDeletedOrIsDeleted>(items: TItem[]): TItem[] => (
    (items || []).filter(item => !item.deleted && !item.isDeleted)
);

export interface IWithId {
    id?: string;
}

export interface IWithGuestId {
    guestId?: string;
}

export const getItemById = <TItem extends IWithId>(items: TItem[], id?: string): TItem => (
    id ? (items || []).find(r => r.id === id) : null
);

export const getItemByGuestId = <TItem extends IWithGuestId>(items: TItem[], guestId?: string): TItem => (
    guestId ? (items || []).find(r => r.guestId === guestId) : null
);

export const getItemIndexById = <TItem extends IWithId>(items: TItem[], id?: string): number => (
    id ? (items || []).findIndex(r => r.id === id) : null
);
