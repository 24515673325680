// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZSaP3T{border-bottom:1px solid #e7e7e7}.ZSaP3T.bUsBuv{border-bottom:0}", "",{"version":3,"sources":["webpack://./src/components/ItemWithBorder/ItemWithBorder.scss"],"names":[],"mappings":"AAEA,QACI,+BAAA,CAEA,eACI,eAAA","sourcesContent":["@import \"../../scss/colors\";\r\n\r\n.item {\r\n    border-bottom: 1px solid $grayscale-border;\r\n\r\n    &.withoutBorder {\r\n        border-bottom: 0;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "ZSaP3T",
	"withoutBorder": "bUsBuv"
};
export default ___CSS_LOADER_EXPORT___;
