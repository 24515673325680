import {useDispatch} from "react-redux";
import {bookingLightboxPaymentAnalyticsEvents} from "../analytics/bookingLightboxPaymentAnalyticsEvents";
import {PaymentSystem} from "../data/Payment";
import {IAppState} from "../store/AppState";
import {IExtraArgument} from "../store/ExtraArgument";
import {CustomThunkAction} from "../store/ThunkAction";
import {IBookingParams, KnownBooking} from "../data/BookingParams";
import {BookingSuccessContext} from "../providers/BookingSuccess/BookingSuccessContext";
import {PaymentContext} from "../providers/Payment/PaymentContext";
import {BookingKind} from "../data/BookingKind";

interface IUseCommonBookingProviderProps<TBooking extends KnownBooking> {
    kind: BookingKind;
    createBooking(bookings: TBooking[]): CustomThunkAction<string[], IAppState, IExtraArgument>;
    clearAccommodations(): void;
}

interface IUseCommonBookingProvider<TBooking extends KnownBooking> {
    isBookingProcess: boolean;
    isBookingLightboxOpened: boolean;
    openBookingLightbox: () => void;
    closeBookingLightbox: () => void;
    handleBooking: (bookingParams: IBookingParams<TBooking>) => Promise<void>;
}

export const useCommonBookingProvider = <TBooking extends KnownBooking>(
    props: IUseCommonBookingProviderProps<TBooking>
): IUseCommonBookingProvider<TBooking> => {
    const {kind, clearAccommodations, createBooking} = props;

    const {createPayment} = React.useContext(PaymentContext);

    const [isBookingLightboxOpened, setIsBookingLightboxOpened] = React.useState(false);
    const {isSuccessLightboxOpened, openBookingLightboxSuccess} = React.useContext(BookingSuccessContext);
    const dispatch = useDispatch();

    const openBookingLightbox = React.useCallback(() => {
        setIsBookingLightboxOpened(true);
    }, []);

    const closeBookingLightbox = () => {
        setIsBookingLightboxOpened(false);
        dispatch(clearAccommodations());
    };

    const handleBooking = async (bookingParams: IBookingParams<TBooking>) => {
        const {bookings, paymentSystem, paymentSourceSettingsId, contactInfo, prepaymentSum} = bookingParams;
        /* eslint-disable @typescript-eslint/await-thenable */
        // @ts-expect-error Need to use new Redux
        const {response: bookingIds}: string[] = await dispatch(createBooking(bookings));

        if(!bookingIds?.length) {
            // в случае ошибки не надо продолжать и показывать "Спасибо за бронирование"
            return;
        }

        if (Object.values(PaymentSystem).includes(paymentSystem)) {
            const paymentData = {paymentSourceSettingsId, bookingIds, paymentSystem, contactInfo, kind};
            bookingLightboxPaymentAnalyticsEvents.trackPaymentStart(paymentSystem, prepaymentSum);
            await createPayment(paymentData, closeBookingLightbox);
        } else {
            bookingLightboxPaymentAnalyticsEvents.trackBookingWithoutPrepayment();
            closeBookingLightbox();
            openBookingLightboxSuccess();
        }
    };

    return {
        isBookingProcess: isBookingLightboxOpened || isSuccessLightboxOpened,
        isBookingLightboxOpened,
        openBookingLightbox,
        closeBookingLightbox,
        handleBooking,
    };
};
