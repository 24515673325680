import styles from "./GuestsAccommodationTooltip.scss";
import BookingAccommodationsAdultsCountSelect
    from "../../providers/Booking/BookingLightbox/Accommodations/Selects/BookingAccommodationsAdultsCountSelect";
import BookingAccommodationsChildrenCountSelect
    from "../../providers/Booking/BookingLightbox/Accommodations/Selects/BookingAccommodationsChildrenCountSelect";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import {useSelector} from "react-redux";
import {IAppState} from "../../store/AppState";
import {AccommodationsVariantsHelper} from "../../providers/Accommodations/helpers/AccommodationsVariantsHelper";
import {IBookingAccommodation, IBookingAccommodationVariant} from "../../data/Booking";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {SumWithLocale} from "../SumWithLocale/SumWithLocale";
import {Accommodation} from "@skbkontur/hotel-components/accommodation";
import {ICurrency} from "../../types/Currency";

interface IGuestsAccommodationControlTooltipProps {
    accommodation: IBookingAccommodation;
    showChildren: boolean;
    occupancyVariants: IBookingAccommodationVariant[];
    maxChildrenCount: number;
    cost: ICurrency;
    onSave: (adultsCount: number, childrenCount: number) => void;
}

const GuestsAccommodationTooltip = (props: IGuestsAccommodationControlTooltipProps) => {
    const {
        accommodation,
        showChildren,
        occupancyVariants,
        maxChildrenCount,
        cost,
        onSave
    } = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);

    const {freeChildrenAge} = useSelector((state: IAppState) => state.hotelInfo.info);
    const {adultsCount, childrenCount, mainAccommodation, additionalAccommodation} = accommodation;
    const [currentAdultsCount, setCurrentAdultsCount] = React.useState<number>(adultsCount);
    const [currentChildrenCount, setCurrentChildrenCount] = React.useState<number>(childrenCount);

    const adultsCountVariants = AccommodationsVariantsHelper.getAdultsCountVariants(occupancyVariants);
    const childrenCountVariants = AccommodationsVariantsHelper.getChildrenCountVariants(occupancyVariants, currentAdultsCount);

    const disableSave = showChildren && !childrenCountVariants.includes(currentChildrenCount);

    return (
        <div className={styles.tooltip}>
            <div className={styles.tooltipRow}>
                <div>{t("GuestsAccommodationTooltip.adults")}</div>
                <BookingAccommodationsAdultsCountSelect
                    value={currentAdultsCount}
                    onChange={setCurrentAdultsCount}
                    availableVariants={adultsCountVariants}
                />
            </div>
            {showChildren && (
                <div className={styles.tooltipRow}>
                    <div>{t("GuestsAccommodationTooltip.children", {count: freeChildrenAge})}</div>
                    <BookingAccommodationsChildrenCountSelect
                        value={currentChildrenCount}
                        onChange={setCurrentChildrenCount}
                        availableVariants={childrenCountVariants}
                        maxCount={maxChildrenCount}
                    />
                </div>
            )}
            <div className={styles.tooltipRow}>
                <div>{t("GuestsAccommodationTooltip.accommodation")}</div>
                <div className={styles.characteristic}>
                    <Accommodation mainAccommodation={mainAccommodation} additionalAccommodation={additionalAccommodation} />
                </div>
            </div>
            <div className={styles.tooltipRow}>
                <div>{t("GuestsAccommodationTooltip.cost")}</div>
                <div className={styles.characteristic}>
                    <SumWithLocale sum={cost} />
                </div>
            </div>
            <PrimaryButton
                loading={false}
                width="100%"
                disabled={disableSave}
                onClick={() => onSave(currentAdultsCount, currentChildrenCount)}
            >
                {t("GuestsAccommodationTooltip.save")}
            </PrimaryButton>
        </div>
    );
};
GuestsAccommodationTooltip.displayName = "GuestsAccommodationTooltip";
export default GuestsAccommodationTooltip;
