import {Reducer} from "redux";
import {BookingActionTypes, KnownAccommodationsAction} from "./bookingActionCreators";

export interface IBookingState {
    isLoading: boolean;
    error?: Error;
}

export const defaultState: IBookingState = {
    isLoading: false,
    error: null
};

export const bookingReducer: Reducer<IBookingState> = (state: IBookingState = defaultState, action: KnownAccommodationsAction) => {
    switch (action.type) {
        case BookingActionTypes.CREATE_BOOKING_REQUEST: {
            return {
                ...state,
                isLoading: true
            };
        }

        case BookingActionTypes.CREATE_BOOKING_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }

        case BookingActionTypes.CREATE_BOOKING_SUCCESS: {
            return {
                ...state,
                isLoading: false
            };
        }

        default:
            return state;
    }
};
