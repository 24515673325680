import {IGlobalError} from "./reducer";

export enum ActionTypes {
    GET_GLOBAL_ERROR = "globalError/GET_GLOBAL_ERROR",
    CLEAR_GLOBAL_ERROR = "globalError/CLEAR_GLOBAL_ERROR",

    SET_HOTEL_NOT_FOUND = "globalError/SET_HOTEL_NOT_FOUND",
    SET_WIDGET_SETTINGS_ERROR = "globalError/SET_WIDGET_SETTINGS_ERROR",
}

interface IGetGlobalErrorAction {
    type: ActionTypes.GET_GLOBAL_ERROR;
    data: IGlobalError;
}

interface IClearGlobalErrorAction {
    type: ActionTypes.CLEAR_GLOBAL_ERROR;
}

interface ISetPageNotFoundAction {
    type: ActionTypes.SET_HOTEL_NOT_FOUND;
}

interface ISetWidgetSettingsError {
    type: ActionTypes.SET_WIDGET_SETTINGS_ERROR;
}

export const getGlobalError = (globalError: IGlobalError): IGetGlobalErrorAction => ({
    type: ActionTypes.GET_GLOBAL_ERROR,
    data: globalError
});

export const clearGlobalError = (): IClearGlobalErrorAction => (
    {type: ActionTypes.CLEAR_GLOBAL_ERROR}
);

export const setHotelNotFound = (): ISetPageNotFoundAction => (
    {type: ActionTypes.SET_HOTEL_NOT_FOUND}
);

export const setWidgetSettingsError = (): ISetWidgetSettingsError => (
    {type: ActionTypes.SET_WIDGET_SETTINGS_ERROR}
);

export type KnownErrorAction = IGetGlobalErrorAction | IClearGlobalErrorAction | ISetPageNotFoundAction | ISetWidgetSettingsError;
