import {getGlobalError, setHotelNotFound, setWidgetSettingsError} from "../store/globalError/actions";
import {FetcherError} from "./Fetcher";
import {Store} from "redux";
import WidgetStore from "../widget/WidgetStore";

export const errorHandler = (store: Store) => async (error: FetcherError) => {
    if (error?.response?.status === 500 || !error.response) {
        const globalError = await error.response.json();
        store.dispatch(getGlobalError(globalError));
    }
    if (error?.response?.status === 404) {
        store.dispatch(setHotelNotFound());
    }
    if (WidgetStore?.settings?.hotelId && error?.response?.status === 400) {
        // error intended to a client, so there is no need for Sentry
        /* eslint-disable-next-line no-console */
        console.error(`You have a problem with access to the widget service.
Check widget settings and widget option availability for your organization.
error: ${JSON.stringify(error)}, settings: ${JSON.stringify(WidgetStore?.settings)}`);
        store.dispatch(setWidgetSettingsError());
    }
    WidgetStore?.settings?.hooks?.onError(new Error(error.message));
    return Promise.reject(error);
};
