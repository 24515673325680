import {useRef} from "../react";

interface IUseTouchMoveProps {
    next: () => void;
    back: () => void;
    trigger: Exclude<keyof Touch, "identifier" | "rotationAngle" | "target">;
    offsetTouch: number;
}

interface IUseTouchMove<T = Element> {
    isTouch: boolean;
    handleTouchStart: (event: React.TouchEvent<T>) => void;
    handleTouchMove: (event: React.TouchEvent<T>) => void;
    handleTouchEnd: () => void;
}

export const useTouchMove = <T = Element>(props: IUseTouchMoveProps): IUseTouchMove<T> => {
    const {trigger, offsetTouch, next, back} = props;
    const [isTouch, setIsTouch] = React.useState(false);
    const [touchPositionRef, setTouchPositionRef] = useRef<number | null>(null);

    const handleTouchStart = (event: React.TouchEvent<T>) => {
        const touchDown = event.touches[0]?.[trigger];
        if (typeof touchDown === "number") {
            setTouchPositionRef(touchDown);
            setIsTouch(true);
        }
    };

    const handleTouchMove = (event: React.TouchEvent<T>) => {
        const currentPosition = event.touches[0]?.[trigger];

        if (touchPositionRef.current === null || typeof currentPosition !== "number") {
            return;
        }

        const direction = touchPositionRef.current - currentPosition;
        setTouchPositionRef(null);

        if (direction > offsetTouch) {
            next();
        }

        if (direction < -offsetTouch) {
            back();
        }
    };

    const handleTouchEnd = () => {
        setIsTouch(false);
    };

    return {isTouch, handleTouchStart, handleTouchMove, handleTouchEnd};
};
