const STAGGER_STEP_MS = 100;

export class FlipperHelper {
    /** Use animation fill-mode forwards to fix final state */
    static onAppear = (className: string, duration: number) => (
        (el: HTMLElement, index: number) => {
            setTimeout(() => {
                el.classList.add(className);
                setTimeout(() => {
                    el.style.opacity = String(1);
                    el.classList.remove(className);
                }, duration);
            }, index * STAGGER_STEP_MS);
        }
    );

    // TODO разобраться при возможности, почему onExit эффекты пытаются отрабатывать, но элоемент исчезает раньше, чем появляется анимация
    static onExit = (className: string, duration: number) => (
        (el: HTMLElement, index: number, removeElement: () => void) => {
            setTimeout(() => {
                el.classList.add(className);
                setTimeout(() => {
                    removeElement();
                }, duration);
            }, index * STAGGER_STEP_MS);
        }
    );
}
