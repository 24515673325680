import Calendar from "../Calendar/Calendar";
import {useDispatch, useSelector} from "react-redux";
import {getAvailabilities} from "../../store/availabilities/availabilitiesActionCreators";
import {IRoomCategorySearchParams} from "../../data/SearchParams";
import {CalendarDataMapType} from "../../data/Calendar/Calendar";
import {RoomAvailabilityCalendarPipes} from "../../data/RoomAvailabilityCalendar/RoomAvailabilityCalendarPipes";
import {RoomAvailabilityCalendarHelper} from "../../data/RoomAvailabilityCalendar/RoomAvailabilityCalendarHelper";
import {DeviceContext} from "../../providers/Device/DeviceContext";
import {useEffectWithoutInitCall} from "../../hooks/useEffectWithoutInitCall";
import {FullDateDayFirstString} from "../../data/Date";
import DateHelper from "../../helpers/DateHelper";
import {DateFormats} from "../../types/DateFormats";
import {CalendarContext} from "../../providers/Calendar/CalendarContext";
import {AvailabilitiesDailyType} from "../../data/Availability";
import {IAppState} from "../../store/AppState";
import styles from "./RoomAvailabilityCalendar.scss";
import {WithProvider} from "../../common/components/WithProvider/WithProvider";
import CalendarProvider from "../../providers/Calendar/CalendarProvider";
import BookingLightboxContainer from "../../providers/Booking/BookingLightbox/BookingLightboxContainer";
import {debounce} from "lodash";

interface IRoomAvailabilityCalendarProps {
    searchParams: IRoomCategorySearchParams;
    roomCategoryId: string;
}

interface ILoadAvailabilitiesParams {
    startDate: string;
    adultsCount: number;
    kidsCount: number;
    roomCategoryId: string;
}

const THROTTLE_DELAY = 300;

const RoomAvailabilityCalendar = (props: IRoomAvailabilityCalendarProps) => {
    const {searchParams, roomCategoryId} = props;
    const {fromDate, adultsCount, kidsCount} = searchParams;

    const {isMobileMode} = React.useContext(DeviceContext);
    const {isAlwaysOneMonth, isTwoMonthsWidget} = React.useContext(CalendarContext);
    const dispatch = useDispatch();

    const offsetInMinutes = useSelector((state: IAppState) => state.hotelInfo.info?.timeZone?.offsetInMinutes);
    const today = DateHelper.getTodayWithTimezone(DateFormats.FullDateDayFirst, offsetInMinutes);

    const initialStartDate: FullDateDayFirstString = fromDate || today;
    const [availabilities, setAvailabilities] = React.useState<AvailabilitiesDailyType>({});
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [calendarData, setCalendarData] = React.useState<CalendarDataMapType>({});
    const [startDate, setStartDate] = React.useState<FullDateDayFirstString>(initialStartDate);
    const [monthsToDisplay, setMonthsToDisplay] = React.useState<1 | 2>(2);

    const loadAvailabilities = React.useMemo(() => (
        debounce(async (loadParams: ILoadAvailabilitiesParams) => {
            const {roomCategoryId, startDate, kidsCount, adultsCount} = loadParams;
            setIsLoading(true);
            // @ts-expect-error Redux wrong types
            // eslint-disable-next-line @typescript-eslint/await-thenable
            const {response} = await dispatch(getAvailabilities({
                adultsCount,
                kidsCount,
                roomCategoryId,
                ...RoomAvailabilityCalendarHelper.getDatesForSearch(startDate, offsetInMinutes)
            }));
            setAvailabilities(response);
            setIsLoading(false);
        }, THROTTLE_DELAY, {trailing: true})
    ), [offsetInMinutes]);

    React.useEffect(() => {
        loadAvailabilities({roomCategoryId, startDate, kidsCount, adultsCount});
    }, [roomCategoryId, startDate, kidsCount, adultsCount]);

    useEffectWithoutInitCall(() => {
        setCalendarData({});
    }, [roomCategoryId]);

    useEffectWithoutInitCall(() => {
        setCalendarData((calendarData) => ({
            ...calendarData,
            ...RoomAvailabilityCalendarPipes.toFrontend(availabilities, offsetInMinutes)
        }));
    }, [availabilities, offsetInMinutes]);

    React.useEffect(() => {
        if (isAlwaysOneMonth) {
            setMonthsToDisplay(1);
        } else {
            setMonthsToDisplay(isMobileMode ? 1 : 2);
        }
    }, [isMobileMode]);

    const calendar = (
        <Calendar
            monthsToDisplay={monthsToDisplay}
            calendarData={calendarData}
            initialDate={initialStartDate}
            onMonthChange={setStartDate}
            isLoading={isLoading}
        />
    );

    const isCustomPaddings = isAlwaysOneMonth || isTwoMonthsWidget;

    return isCustomPaddings ? (
        <div className={styles.calendarContainer}>
            {calendar}
        </div>
    ) : (
        <BookingLightboxContainer>
            {calendar}
        </BookingLightboxContainer>
    );
};
RoomAvailabilityCalendar.displayName = "RoomAvailabilityCalendar";
export default WithProvider(RoomAvailabilityCalendar, CalendarProvider);
