import {IBookingAccommodation} from "../../data/Booking";
import {
    IAccommodationsPricesMap,
    IAccommodationsMapParams,
    ISelectedAccommodationsMap,
    IAccommodationsAvailabilityMap, IAccommodationsVariantsMap
} from "./AccommodationsMaps";
import {IRoomCategoryAccommodation} from "../../data/Accommodation";

export interface IAccommodationUpdateParams extends IAccommodationsMapParams {
    accommodation: IBookingAccommodation;
}

export interface IAccommodationsContext {
    allAccommodations: IRoomCategoryAccommodation[];
    accommodations: IRoomCategoryAccommodation[];
    selectedAccommodationsMap: ISelectedAccommodationsMap;
    accommodationsPricesMap: IAccommodationsPricesMap;
    accommodationsAvailabilityMap: IAccommodationsAvailabilityMap;
    accommodationsVariantsMap: IAccommodationsVariantsMap;

    addAccommodation: (args: IAccommodationUpdateParams) => void;
    removeAccommodation: (args: IAccommodationsMapParams) => void;
    updateAccommodation: (args: IAccommodationUpdateParams) => void;

}

export const AccommodationsContext = React.createContext({} as IAccommodationsContext);
