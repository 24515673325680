import {IRate} from "../../data/Rate";
import {CustomThunkAction} from "../ThunkAction";

export enum ActionTypes {
    GET_RATES_REQUEST = "rates/GET_RATES_REQUEST",
    GET_RATES_SUCCESS = "rates/GET_RATES_SUCCESS",
    GET_RATES_ERROR = "rates/GET_RATES_ERROR"
}

interface IRatesRequestAction {
    type: ActionTypes.GET_RATES_REQUEST;
}

interface IRatesSuccessAction {
    type: ActionTypes.GET_RATES_SUCCESS;
    response: IRate[];
}

interface IRatesErrorAction {
    type: ActionTypes.GET_RATES_ERROR;
    error: Error;
}

export type KnownRatesAction = IRatesRequestAction | IRatesSuccessAction | IRatesErrorAction;

export const getRates = (): CustomThunkAction<IRate[]> => (
    // @ts-expect-error Use new Redux instead of fixing this types
    (dispatch, getState, {dailyApi}) => dispatch({
        type: {
            REQUEST: ActionTypes.GET_RATES_REQUEST,
            SUCCESS: ActionTypes.GET_RATES_SUCCESS,
            FAILURE: ActionTypes.GET_RATES_ERROR
        },
        asyncAction: dailyApi.getRates()
    })
);
