import {Gapped, Modal, Spinner} from "@skbkontur/react-ui";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";

interface IPaymentCheckingLightboxProps {
    onClose: () => void;
}

const PaymentCheckingLightbox = (props: IPaymentCheckingLightboxProps) => {
    const {onClose} = props;
    const {t} = useTranslation(TranslationNamespaces.BookingModule);

    return (
        <Modal
            disableFocusLock
            onClose={onClose}
            width={500}
            noClose
            data-tid="LoadingModal"
        >
            <Modal.Body>
                <Gapped gap={10}>
                    <Spinner type="mini" caption={null} />
                    {t("checkPayment")}
                </Gapped>
            </Modal.Body>
        </Modal>
    );
};
PaymentCheckingLightbox.displayName = "PaymentCheckingLightbox";
export default PaymentCheckingLightbox;
