import {DeviceContext} from "../../../providers/Device/DeviceContext";
import {TBankSbpDesktopPayment} from "./TBankSbpDesktopPayment";
import {TBankSbpMobilePayment} from "./TBankSbpMobilePayment";

interface ITBankSbpPaymentProps {
    paymentUrl: string;
}

const TBankSbpPayment = (props: ITBankSbpPaymentProps) => {
    const {paymentUrl} = props;

    const {isMobileMode} = React.useContext(DeviceContext);

    return isMobileMode ? (
        <TBankSbpMobilePayment />
    ) : (
        <TBankSbpDesktopPayment paymentUrl={paymentUrl} />
    );
};

TBankSbpPayment.displayName = "TBankSbpPayment";
export default TBankSbpPayment;
