import {Reducer} from "redux";
import {ActionTypes, KnownAccommodationsAction} from "./accommodationsActionCreators";
import {IRoomCategoryAccommodation} from "../../data/Accommodation";

export interface IAccommodationsState {
    isLoading: boolean;
    accommodations: IRoomCategoryAccommodation[];
    error?: Error;
}

export const defaultState: IAccommodationsState = {
    isLoading: false,
    accommodations: null,
    error: null
};

export const accommodationsReducer: Reducer<IAccommodationsState> = (state: IAccommodationsState = defaultState, action: KnownAccommodationsAction) => {
    switch (action.type) {
        case ActionTypes.GET_ACCOMMODATIONS_REQUEST: {
            return {
                ...state,
                isLoading: true
            };
        }

        case ActionTypes.GET_ACCOMMODATIONS_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }

        case ActionTypes.GET_ACCOMMODATIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                accommodations: action.response
            };
        }

        case ActionTypes.CLEAR_ACCOMMODATIONS: {
            return {
                ...state,
                accommodations: null
            };
        }

        default:
            return state;
    }
};
