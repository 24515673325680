import {CustomThunkAction} from "../ThunkAction";
import WidgetStore from "../../widget/WidgetStore";
import {IHourlyBookingAccommodation, IHourlyCreatedBooking} from "../../data/HourlyBooking";
import {HourlyAccommodationsPricesHelper} from "../../providers/HourlyAccommodations/helpers/HourlyAccommodationsPricesHelper";
import {IWidgetHookHourlyBooking} from "../../widget/settings/WidgetSettingsHooks";

export enum HourlyBookingActionTypes {
    CREATE_HOURLY_BOOKING_REQUEST = "hourlyBooking/CREATE_HOURLY_BOOKING_REQUEST",
    CREATE_HOURLY_BOOKING_SUCCESS = "hourlyBooking/CREATE_HOURLY_BOOKING_SUCCESS",
    CREATE_HOURLY_BOOKING_ERROR = "hourlyBooking/CREATE_HOURLY_BOOKING_ERROR",
}

interface ICreateHourlyBookingRequestAction {
    type: HourlyBookingActionTypes.CREATE_HOURLY_BOOKING_REQUEST;
}

interface ICreateHourlyBookingSuccessAction {
    type: HourlyBookingActionTypes.CREATE_HOURLY_BOOKING_SUCCESS;
    response: string[];
}

interface ICreateHourlyBookingErrorAction {
    type: HourlyBookingActionTypes.CREATE_HOURLY_BOOKING_ERROR;
    error: Error;
}

export type KnownHourlyAccommodationsAction =
    | ICreateHourlyBookingRequestAction
    | ICreateHourlyBookingSuccessAction
    | ICreateHourlyBookingErrorAction;

export const createHourlyBooking =
    (bookings: IHourlyBookingAccommodation[]): CustomThunkAction<string[]> =>
    // @ts-expect-error Use new Redux instead of fixing this types
    (dispatch, getState, {hourlyBookingApi}) => dispatch({
            type: {
                REQUEST: HourlyBookingActionTypes.CREATE_HOURLY_BOOKING_REQUEST,
                SUCCESS: HourlyBookingActionTypes.CREATE_HOURLY_BOOKING_SUCCESS,
                FAILURE: HourlyBookingActionTypes.CREATE_HOURLY_BOOKING_ERROR,
            },
            asyncAction: async () => {
                const createdBookings: IHourlyCreatedBooking[] =
                    await hourlyBookingApi.createHourlyBooking(bookings);
                const {
                    hourlyObjects: {hourlyObjects},
                    hourlyObjectsAccommodations: {accommodations},
                } = getState();
                if (WidgetStore.settings) {
                    const pricesMap =
                        HourlyAccommodationsPricesHelper.createHourlyPricesMap(accommodations);

                    WidgetStore.settings.hooks.onHourlyBooking(
                        createdBookings.map((b: IHourlyCreatedBooking): IWidgetHookHourlyBooking => {
                            const hourlyObject = hourlyObjects.find(rc => rc.id === b.hourlyObjectId);

                            return {
                                id: b.id,
                                comment: b.comment,
                                customer: b.customer,
                                fromDate: b.checkin,
                                toDate: b.checkout,
                                hourlyObject: hourlyObject.name,
                                hourlyObjectItem: hourlyObject?.items.find((ho) => ho.id === b.hourlyObjectItemId)?.name,
                                ...HourlyAccommodationsPricesHelper.getHourlyBookingPrice(b, pricesMap),
                            };
                        })
                    );
                }
                return createdBookings.map(b => b.id);
            },
        });
