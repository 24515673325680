import {BookingPaymentSystemRadioGroup} from "./BookingPaymentSystemRadioGroup";
import {BookingPaymentPayButton} from "./BookingPaymentPayButton";
import {useSelector} from "react-redux";
import {Gapped} from "@skbkontur/react-ui";
import {PaymentSystem, IPaymentTerminal} from "../../../data/Payment";
import {IAppState} from "../../../store/AppState";

interface IBookingPaymentProps {
    isCashback: boolean;
    isOverbookingLoading: boolean;
}

export const BookingPayment = (props: IBookingPaymentProps) => {
    const {isCashback, isOverbookingLoading} = props;

    const isLoading = useSelector((state: IAppState) => state.booking.isLoading || state.payment.isPaymentCreation);

    const isTerminalEnabled = (system: PaymentSystem) => (st: IPaymentTerminal) => (
        st.isAvailable
        && st.isCashback === Boolean(isCashback)
        && st.paymentSource === system
    );

    const isYandexCheckoutEnabled = useSelector((state: IAppState) => (
        state.paymentTerminals.terminals.some(isTerminalEnabled(PaymentSystem.YandexCheckout))
    ));

    const isTBankSbpEnabled = useSelector((state: IAppState) => (
        state.paymentTerminals.terminals.some(isTerminalEnabled(PaymentSystem.TBankSbp))
    ));

    const defaultPaymentSystem = isTBankSbpEnabled ? PaymentSystem.TBankSbp : PaymentSystem.YandexCheckout;
    const [paymentSystem, setPaymentSystem] = React.useState<PaymentSystem>(defaultPaymentSystem);

    const hasPaymentSystemChoice = isYandexCheckoutEnabled && isTBankSbpEnabled;

    return (
        <Gapped gap={30} vertical>
            {hasPaymentSystemChoice && (
                <BookingPaymentSystemRadioGroup
                    isLoading={isLoading || isOverbookingLoading}
                    onChange={setPaymentSystem}
                    paymentSystem={paymentSystem}
                />
            )}
            <BookingPaymentPayButton
                isLoading={isLoading || isOverbookingLoading}
                paymentSystem={paymentSystem}
            />
        </Gapped>
    );
};

BookingPayment.displayName = "BookingPayment";
