import {ICurrency} from "../types/Currency";
import {IBookingContactInfo} from "./Booking";
import {BookingKind} from "./BookingKind";

// TODO: разобраться с неймингом, энам PaymentSystem, а переменные paymentSource
export enum PaymentSystem {
    YandexCheckout = "yandexCheckout",
    TBankSbp = "tinkoffSbp"
}

export enum PaymentStatus {
    created = "created",
    pending = "pending",
    succeeded = "succeeded",
    canceled = "canceled"
}

export enum PaymentResult {
    canceled = "canceled",
    failed = "failed",
    succeeded = "succeeded"
}

export enum PaymentLinkStatus {
    Active = "active",
    Inactive = "inactive",
    Expired = "expired"
}

export interface IPayment {
    id: string;
    externalId: string;
    organizationId: string;
    bookingIds: string[];
    source: PaymentSystem;
    price: ICurrency;
    currencyType: string;
    status: PaymentStatus;
    createdAt: string;
    isPaid: boolean;
    paidAt: string;
    cancellation: ICancellation;
    description: string;
    paymentMethod: PaymentMethod;
}

export enum PaymentMethod {
    AlfaClick = "alfaClick",
    ApplePay = "applePay",
    BankCard = "bankCard",
    Cash = "cash",
    DirectCarrierBilling = "directCarrierBilling",
    GooglePay = "googlePay",
    Installments = "installments",
    QiwiWallet = "qiwiWallet",
    SberbankOnline = "sberbankOnline",
    Tinkoff = "tinkoff",
    Webmoney = "webmoney",
    YandexMoney = "yandexMoney"
}

interface ICancellation {
    canceledAt: string;
    reason: string;
}

export interface IPaymentInfo {
    status: PaymentStatus;
    bookingIds: string[];
}

export interface IPaymentTerminal {
    id: string;
    isCashback: boolean;
    isAvailable: boolean;
    paymentSource: PaymentSystem;
}

export interface IPaymentData {
    paymentSourceSettingsId: string;
    bookingIds: string[];
    paymentSystem: PaymentSystem;
    contactInfo: IBookingContactInfo;
    kind: BookingKind;
}
