import raf from "raf-throttle";
import {DeviceContext, DeviceMode} from "../providers/Device/DeviceContext";

export const useLightboxResize = (getWidth: (deviceMode: DeviceMode) => number): number => {
    const [width, setWidth] = React.useState<number>();
    const {deviceMode} = React.useContext(DeviceContext);

    React.useEffect(() => {
        const onResize = raf(() => {
            setWidth(getWidth(deviceMode));
        });
        onResize();
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, [deviceMode]);

    return width;
};
