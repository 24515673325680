import CalendarMonth from "./CalendarMonth";
import styles from "./Calendar.scss";
import {Flipper} from "react-flip-toolkit";
import CalendarControls from "./CalendarControls";
import {CalendarDataMapType} from "../../data/Calendar/Calendar";
import cn from "classnames";
import {Loader} from "@skbkontur/react-ui";
import FlippedElement from "../../common/components/Flipper/Element/FlippedElement";
import {CalendarHelper} from "../../data/Calendar/CalendarHelper";
import DateHelper from "../../helpers/DateHelper";
import {FullDateDayFirstString, MonthWithYearString} from "../../data/Date";
import {DateFormats} from "../../types/DateFormats";
import {CalendarContext} from "../../providers/Calendar/CalendarContext";

export interface ICalendarProps {
    monthsToDisplay: 1 | 2;
    calendarData: CalendarDataMapType;
    initialDate?: string;
    isLoading: boolean;
    offsetInMinutes?: number;
    onMonthChange?: (yearMonth: FullDateDayFirstString) => void;
}

const dateFormatYearMonth = (date: FullDateDayFirstString): MonthWithYearString => (
    DateHelper.convert(date, DateFormats.FullDateDayFirst, DateFormats.MonthWithYear)
);

const getLastAvailableDate = (monthsToDisplay: 1 | 2, offsetInMinutes?: number): FullDateDayFirstString => {
    const todayDate = DateHelper.getTodayWithTimezone(DateFormats.FullDateDayFirst, offsetInMinutes);
    const lastAvailableDate = DateHelper.addYearsToDate(2, todayDate, DateFormats.FullDateDayFirst);
    if (monthsToDisplay === 2) {
        return DateHelper.addMonthsToDate(-1, lastAvailableDate, DateFormats.FullDateDayFirst);
    }
    return lastAvailableDate;
};

const Calendar = (props: ICalendarProps) => {
    const {
        calendarData,
        offsetInMinutes,
        initialDate = DateHelper.getTodayWithTimezone(DateFormats.FullDateDayFirst, offsetInMinutes),
        monthsToDisplay, isLoading, onMonthChange
    } = props;

    const {isTwoMonthsRegular} = React.useContext(CalendarContext);
    const [currentDate, setCurrentDate] = React.useState<FullDateDayFirstString>(initialDate);
    const [datesForSlider, setDatesForSlider] = React.useState<FullDateDayFirstString[]>(
        CalendarHelper.getMonthsForSlider(currentDate, monthsToDisplay)
    );

    React.useEffect(() => {
        setDatesForSlider(CalendarHelper.getMonthsForSlider(currentDate, monthsToDisplay));
        onMonthChange(currentDate);
    }, [currentDate]);

    React.useEffect(() => {
        setDatesForSlider(CalendarHelper.getMonthsForSlider(currentDate, monthsToDisplay));
    }, [monthsToDisplay]);

    const showNextMonth = () => setCurrentDate(
        DateHelper.addMonthsToDate(1, currentDate, DateFormats.FullDateDayFirst)
    );
    const showPrevMonth = () => setCurrentDate(
        DateHelper.addMonthsToDate(-1, currentDate, DateFormats.FullDateDayFirst)
    );

    const leftArrowIsHidden = DateHelper.isSameMonth(
        DateHelper.getTodayWithTimezone(DateFormats.FullDateDayFirst, offsetInMinutes),
        currentDate,
        DateFormats.FullDateDayFirst
    );

    const rightArrowIsHidden = DateHelper.isSameMonth(
        getLastAvailableDate(monthsToDisplay, offsetInMinutes),
        currentDate,
        DateFormats.FullDateDayFirst
    );

    return (
        <div className={cn(styles.calendar, {[styles.calendarTwoMonthsRegular]: isTwoMonthsRegular})}>
            <CalendarControls
                leftArrowIsHidden={leftArrowIsHidden}
                rightArrowIsHidden={rightArrowIsHidden}
                onLeftArrowClick={showPrevMonth}
                onRightArrowClick={showNextMonth}
            />
            <div className={styles.sliderContainer}>
                <Flipper
                    className={styles.slider}
                    flipKey={datesForSlider.join()}
                >
                    {datesForSlider.map(date => {
                        const dateInFormatYearMonth = dateFormatYearMonth(date);

                        return (
                            <FlippedElement
                                key={dateInFormatYearMonth}
                                flipId={dateInFormatYearMonth}
                                withDiv
                            >
                                <Loader
                                    active={isLoading && !calendarData[dateInFormatYearMonth]}
                                    delayBeforeSpinnerShow={0}
                                    minimalDelayBeforeSpinnerHide={500}
                                >
                                    <CalendarMonth
                                        monthNumberFromZero={DateHelper.getMonth(date, DateFormats.FullDateDayFirst)}
                                        year={DateHelper.getYear(date, DateFormats.FullDateDayFirst)}
                                        monthData={calendarData[dateInFormatYearMonth]}
                                        isSingleMonth={monthsToDisplay === 1}
                                    />
                                </Loader>
                            </FlippedElement>
                        );
                    })}
                </Flipper>
            </div>
        </div>
    );
};
Calendar.displayName = "Calendar";
export default Calendar;
