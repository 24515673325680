import {Input} from "@skbkontur/react-ui";
import OrderFormRow from "../Row/OrderFormRow";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import {BookingOrderContext} from "../../BookingOrder/BookingOrderContext";
import {createValidationInfo} from "../../../forms/ValidationInfo";
import {IBookingOrderState} from "../../../data/BookingOrder";
import {FormFieldName, useTrackFormFieldFill} from "../../../hooks/useTrackFormFieldFill";

const OrderFormFio = () => {
    const {tcn} = useTranslation(TranslationNamespaces.Common);

    const {
        setForm,
        form: {customer},
    } = React.useContext(BookingOrderContext);
    const {fio} = customer;

    const getValidation = () => {
        if (!fio) return createValidationInfo(tcn("enterFio"));
    };

    const handleChange = (fio: string) => {
        setForm((value: IBookingOrderState) => ({
            ...value,
            customer: {...value.customer, fio},
        }));
    };

    const onBlur = useTrackFormFieldFill(FormFieldName.Fio);

    return (
        <OrderFormRow caption={tcn("fioCaption")} required validationInfo={getValidation()}>
            <Input width="100%" size="medium" onValueChange={handleChange} onBlur={onBlur} />
        </OrderFormRow>
    );
};
OrderFormFio.displayName = "OrderFormFio";
export default OrderFormFio;
