import ReactImageLightbox, {SlideImage} from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import {IImage} from "@skbkontur/hotel-data/image";

interface IGalleryModalProps {
    startIndex?: number;
    images: IImage[];
    onClose: () => void;
}

const GalleryLightbox = (props: IGalleryModalProps) => {
    const {startIndex, onClose, images} = props;

    const [imageIndex, setImageIndex] = React.useState(startIndex || 0);

    const sliderImages: SlideImage[] = images.map((el) => ({
        src: el.url,
    }));

    const closeReactImageLightbox = () => {
        setImageIndex(-1);
        onClose();
    };

    return (
        <ReactImageLightbox
            styles={{container: {backgroundColor: "rgba(0, 0, 0, .8)"}}}
            open={imageIndex >= 0}
            index={imageIndex}
            close={closeReactImageLightbox}
            slides={sliderImages}
        />
    );
};
GalleryLightbox.displayName = "GalleryLightbox";
export default GalleryLightbox;
