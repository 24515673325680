import {ApiSiteBase} from "./apiSiteBase";
import Fetcher from "../core/Fetcher";
import {ApiBase} from "./apiBase";
import {IAvailabilitiesHourlySearchParams, IAvailabilitiesDailySearchParams} from "../data/SearchParams";
import {AvailabilitiesDailyType, AvailabilitiesHourlyType} from "../data/Availability";
import {BookingKind} from "../data/BookingKind";

export class AvailabilitiesApi extends ApiSiteBase {
    getDailyAvailabilities = (params: IAvailabilitiesDailySearchParams): Promise<AvailabilitiesDailyType> => {
        const query = ApiBase.toQueryParams({
            ...params,
            fromDate: ApiBase.toQueryDate(params.fromDate),
            toDate: ApiBase.toQueryDate(params.toDate),
        });
        return Fetcher.get<AvailabilitiesDailyType>(this.buildRequestUrl([BookingKind.Daily, query]));
    };

    getHourlyAvailabilities = (params: IAvailabilitiesHourlySearchParams): Promise<AvailabilitiesHourlyType> => {
        const query = ApiBase.toQueryParams({
            ...params,
            fromDate: params.fromDate,
            toDate: params.toDate,
        });
        return Fetcher.get<AvailabilitiesHourlyType>(this.buildRequestUrl([BookingKind.Hourly, query]));
    };
}

export default new AvailabilitiesApi("availabilities");
