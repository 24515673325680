import {RequisitesLightboxContext} from "./RequisitesLightboxContext";
import {useSelector} from "react-redux";
import {Country} from "@skbkontur/i18n";
import {ForeignRequisitesLightbox} from "../ForeignRequisitesLightbox";
import {RussianRequisitesLightbox} from "../RussianRequisitesLightbox";
import {IForeignRequisites, IRussianRequisites} from "../../../data/Requisites";
import {IAppState} from "../../../store/AppState";
import { useMemoObject } from "../../../common/hooks/useMemoObject";

export const RequisitesLightboxProvider = ({children}: React.PropsWithChildren<object>) => {
    const {countryCode, requisites} = useSelector((state: IAppState) => state.hotelInfo.info);

    const [showRussianRequisitesLightbox, setShowRussianRequisitesLightbox] = React.useState(false);
    const [showForeignRequisitesLightbox, setShowForeignRequisitesLightbox] = React.useState(false);

    const openRussianRequisitesLightbox = React.useCallback(() => setShowRussianRequisitesLightbox(true), []);
    const openForeignRequisitesLightbox = React.useCallback(() => setShowForeignRequisitesLightbox(true), []);

    const closeRussianRequisitesLightbox = React.useCallback(() => setShowRussianRequisitesLightbox(false), []);
    const closeForeignRequisitesLightbox = React.useCallback(() => setShowForeignRequisitesLightbox(false), []);

    const openRequisitesLightbox = React.useCallback(() => {
        countryCode === Country.RUS
            ? openRussianRequisitesLightbox()
            : openForeignRequisitesLightbox();
    }, [countryCode]);

    return (
        <RequisitesLightboxContext.Provider value={useMemoObject({openRequisitesLightbox})}>
            {children}
            {showRussianRequisitesLightbox && (
                <RussianRequisitesLightbox
                    requisites={requisites as IRussianRequisites}
                    onClose={closeRussianRequisitesLightbox}
                />
            )}
            {showForeignRequisitesLightbox && (
                <ForeignRequisitesLightbox
                    requisites={requisites as IForeignRequisites}
                    onClose={closeForeignRequisitesLightbox}
                />
            )}
        </RequisitesLightboxContext.Provider>
    );
};
