import {Reducer} from "redux";
import {ActionTypes, KnownAction} from "./hotelInfoActionCreators";
import {Currencies} from "@skbkontur/i18n";
import {IHotelInfo} from "../../data/HotelInfo";

export interface IHotelInfoState {
    isLoading: boolean;
    info: IHotelInfo;

    // TODO AxiosResponseError
    error?: Error;
}

export const defaultState: IHotelInfoState = {
    isLoading: false,
    info: {
        organizationName: "",
        description: "",
        address: "",
        contacts: [],
        freeChildrenOn: false,
        images: [],
        currencyCode: Currencies.default,
        imagesThumbnail: [],
        countryCode: null,
        requisites: null,
        offerAgreementLink: "",
        autoSendConfirmationEmail: false,
        displayAvailabilityCalendar: false,
        timeZone: {
            offset: "",
            offsetInMinutes: 0
        }
    },
    error: null
};

export const hotelInfoReducer: Reducer<IHotelInfoState> = (state: IHotelInfoState = defaultState, action: KnownAction) => {
    switch (action.type) {
        case ActionTypes.GET_HOTEL_INFO_REQUEST: {
            return {
                ...state,
                isLoading: true
            };
        }

        case ActionTypes.GET_HOTEL_INFO_ERROR: {
            // TODO toast "ERROR"
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }

        case ActionTypes.GET_HOTEL_INFO_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                info: action.response
            };
        }

        default:
            return state;
    }
};
