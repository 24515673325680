import styles from "./BookingLightbox.scss";
import cn from "classnames";

const BookingLightboxContainer = (props: React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) => {
    const {children, className, ...divProps} = props;
    return (
        <div {...divProps} className={cn(styles.contentContainer, className)}>
            {children}
        </div>
    );
};
BookingLightboxContainer.displayName = "BookingLightboxContainer";
export default BookingLightboxContainer;
