import {Reducer} from "redux";
import {HourlyObjectsActionTypes, KnownHourlyObjectsAction} from "./hourlyObjectsActionCreators";
import {IHourlyObject} from "../../data/HourlyObjects";

export interface IHourlyObjectsState {
    isLoading: boolean;
    hourlyObjects: IHourlyObject[];
    error?: Error;
}

export const defaultState: IHourlyObjectsState = {
    isLoading: false,
    hourlyObjects: [],
    error: null,
};

export const hourlyObjectsReducer: Reducer<IHourlyObjectsState> = (
    state: IHourlyObjectsState = defaultState,
    action: KnownHourlyObjectsAction
) => {
    switch (action.type) {
        case HourlyObjectsActionTypes.GET_HOURLY_OBJECTS_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case HourlyObjectsActionTypes.GET_HOURLY_OBJECTS_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }

        case HourlyObjectsActionTypes.GET_HOURLY_OBJECTS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                hourlyObjects: action.response,
            };
        }

        default:
            return state;
    }
};
