import {IHotelFeature} from "../../data/HotelFeature";
import {CustomThunkAction} from "../ThunkAction";

export enum HotelFeaturesActionTypes {
    GET_REQUEST = "hourlyFeatures/GET_REQUEST",
    GET_SUCCESS = "hourlyFeatures/GET_SUCCESS",
    GET_ERROR = "hourlyFeatures/GET_ERROR",
}

interface IHourlyFeaturesRequestAction {
    type: HotelFeaturesActionTypes.GET_REQUEST;
}

interface IHourlyFeaturesSuccessAction {
    type: HotelFeaturesActionTypes.GET_SUCCESS;
    response: IHotelFeature[];
}

interface IHourlyFeaturesErrorAction {
    type: HotelFeaturesActionTypes.GET_ERROR;
    error: Error;
}

export type KnownHotelFeaturesAction =
    | IHourlyFeaturesRequestAction
    | IHourlyFeaturesSuccessAction
    | IHourlyFeaturesErrorAction;

export const getHourlyFeatures = (): CustomThunkAction<IHotelFeature[]> => (
    // @ts-expect-error Use new Redux instead of fixing this types
    (dispatch, _getState, {hourlyApi}) => (
        dispatch({
            type: {
                REQUEST: HotelFeaturesActionTypes.GET_REQUEST,
                SUCCESS: HotelFeaturesActionTypes.GET_SUCCESS,
                FAILURE: HotelFeaturesActionTypes.GET_ERROR,
            },
            asyncAction: hourlyApi.getFeatures(),
        })
    )
);
