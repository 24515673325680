// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cRlGHo{margin-top:20px}", "",{"version":3,"sources":["webpack://./src/providers/BookingOrder/Fields/OrderFormIsAgreed.scss"],"names":[],"mappings":"AAAA,QACI,eAAA","sourcesContent":[".agreement {\r\n    margin-top: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"agreement": "cRlGHo"
};
export default ___CSS_LOADER_EXPORT___;
