import {ApiSiteBase} from "./apiSiteBase";
import Fetcher from "../core/Fetcher";
import {IBookingPromoCodeApplyBackendResultInfo, IBookingPromoCodeApplyRequestInfo} from "../data/PromoCode";
import {ApiBase} from "./apiBase";

export class PromoCodeApi extends ApiSiteBase {
    isAnyPromoCodeAvailable(): Promise<boolean> {
        return Fetcher.get<boolean>(this.buildRequestUrl(["any"]));
    }

    tryApplyPromoCode(params: IBookingPromoCodeApplyRequestInfo): Promise<IBookingPromoCodeApplyBackendResultInfo> {
        const paramsWithQueryDates = {
            ...params,
            bookings: params.bookings.map(booking => ({
                ...booking,
                checkin: ApiBase.toQueryDate(booking.checkin),
                checkout: ApiBase.toQueryDate(booking.checkout)
            }))
        };
        return Fetcher.postJSON<IBookingPromoCodeApplyBackendResultInfo>(this.buildRequestUrl(), paramsWithQueryDates);
    }
}

export default new PromoCodeApi("promo-codes");
