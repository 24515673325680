import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import DateHelper from "../../helpers/DateHelper";
import {DateFormats} from "../../types/DateFormats";

interface IRateConditionsFreeCancellationProps {
    freeCancellationHoursCount: number;
    checkin: string;
}

export const RateConditionsFreeCancellation = (props: IRateConditionsFreeCancellationProps) => {
    const {freeCancellationHoursCount, checkin} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingConfirmation);

    const dateTime = freeCancellationHoursCount
        ? DateHelper.addHoursToDate(
              -freeCancellationHoursCount,
              checkin,
              DateFormats.FullDateDayFirstWithTime
          )
        : checkin;

    return (
        <div data-tid="cancellationPolicy">
            {t("cancellationPolicy", {dateTime, raw: true})}
        </div>
    );
};
RateConditionsFreeCancellation.displayName = "BookingConditionsFreeCancellation";
