import {CustomThunkAction} from "../ThunkAction";

export enum CashbackActionTypes {
    GET_CASHBACK_TERMINAL_IDS_REQUEST = "cashback/GET_CASHBACK_TERMINAL_IDS_REQUEST",
    GET_CASHBACK_TERMINAL_IDS_SUCCESS = "cashback/GET_CASHBACK_TERMINAL_IDS_SUCCESS",
    GET_CASHBACK_TERMINAL_IDS_ERROR = "cashback/GET_CASHBACK_TERMINAL_IDS_ERROR"
}

interface IGetCashbackTerminalIdsRequestAction {
    type: CashbackActionTypes.GET_CASHBACK_TERMINAL_IDS_REQUEST;
}

interface IGetCashbackTerminalIdsSuccessAction {
    type: CashbackActionTypes.GET_CASHBACK_TERMINAL_IDS_SUCCESS;
    response: string[];
}

interface IGetCashbackTerminalIdsErrorAction {
    type: CashbackActionTypes.GET_CASHBACK_TERMINAL_IDS_ERROR;
    error: Error;
}

export type KnownCashbackActions =
    IGetCashbackTerminalIdsErrorAction
    | IGetCashbackTerminalIdsRequestAction
    | IGetCashbackTerminalIdsSuccessAction;

export const getCashbackTerminalIds = (checkin: string, checkout: string): CustomThunkAction<string[]> => (
    // @ts-expect-error Use new Redux instead of fixing this types
    (dispatch, getState, {cashbackApi}) => dispatch({
        type: {
            REQUEST: CashbackActionTypes.GET_CASHBACK_TERMINAL_IDS_REQUEST,
            SUCCESS: CashbackActionTypes.GET_CASHBACK_TERMINAL_IDS_SUCCESS,
            FAILURE: CashbackActionTypes.GET_CASHBACK_TERMINAL_IDS_ERROR
        },
        asyncAction: cashbackApi.getCashbackTerminalIds(checkin, checkout)
    })
);
