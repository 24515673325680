import cn from "classnames";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import {useTranslation} from "@skbkontur/i18n";
import {WidgetClientGlobalStyles} from "../../../widget/WidgetClientGlobalStyles";
import WidgetStore from "../../../widget/WidgetStore";
import {BookingOrderContext} from "../../../providers/BookingOrder/BookingOrderContext";

interface IBookingOrderBookProps {
    isLoading?: boolean;
}

const BookingOrderBook = (props: IBookingOrderBookProps) => {
    const {isLoading} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModule);

    const {book} = React.useContext(BookingOrderContext);

    const buttonWidgetContainerClass = cn({
        [WidgetClientGlobalStyles.WidgetBookingFormButtonContainer]: WidgetStore.isWidget(),
    });

    const handleClick = () => book();

    return (
        <div className={cn(buttonWidgetContainerClass)}>
            <PrimaryButton onClick={handleClick} loading={isLoading} data-tid="BookButton">
                {t("bookWithoutPrepayment")}
            </PrimaryButton>
        </div>
    );
};
BookingOrderBook.displayName = "BookingOrderBook";
export default BookingOrderBook;
