import {IHourlyObject, IHourlyObjectItem} from "../../../data/HourlyObjects";
import {useSortedHourlyObjects} from "../../../hooks/useSortedHourlyObjects";
import {sortBy} from "lodash";

export interface IHourlyObjectEntity {
    key: string;
    hourlyObjectItemId?: string;
    hourlyObject: IHourlyObject;
}

export const useHourlyObjectsEntities = (): IHourlyObjectEntity[] => {
    const hourlyObjectsEntities = useSortedHourlyObjects();

    return React.useMemo(() => (
        hourlyObjectsEntities.reduce((acc: IHourlyObjectEntity[], hourlyObject: IHourlyObject) => {
            const {items, id} = hourlyObject;
            const itemsSorted = sortBy(items, (item) => item.order);

            if (items?.length) {
                itemsSorted.forEach((item: IHourlyObjectItem) => {
                    acc.push({
                        key: `${id}_${item.id}`,
                        hourlyObjectItemId: item.id,
                        hourlyObject,
                    });
                });
            } else {
                acc.push({
                    key: id,
                    hourlyObject,
                });
            }

            return acc;
        }, [])
    ), [hourlyObjectsEntities]);
};
