import {HotelFeaturesMap, IBookingAccommodation} from "../../../../data/Booking";
import {IRoomCategory, RoomCategoryHelper} from "@skbkontur/hotel-data/roomCategory";
import {getRenderKey} from "@skbkontur/hotel-data/renderKey";

export class BookingAccommodationsHelper {
    static getDefaultAccommodation = (roomCategory: IRoomCategory, mainOccupancy: number, additionalOccupancy: number, rateId?: string): IBookingAccommodation => {
        const {id} = roomCategory;
        const adultsCount = RoomCategoryHelper.getPlacesCountByOccupancy(mainOccupancy, roomCategory) + additionalOccupancy;
        return {
            roomCategoryId: id,
            adultsCount,
            mainAccommodation: adultsCount - additionalOccupancy,
            additionalAccommodation: additionalOccupancy,
            childrenCount: 0,
            rateId,
            renderKey: getRenderKey(),
            bookingFeatures: {} as HotelFeaturesMap
        };
    };
}
