import {Gapped, Select} from "@skbkontur/react-ui";
import DateHelper from "../../helpers/DateHelper";
import {DateFormats} from "../../types/DateFormats";
import {IHourlyObjectsItemAvailableTime} from "./HourlyObjectsListAvailableTime";
import HourlyObjectsTimeItem from "./HourlyObjectsTimeItem";
import styles from "./HourlyObjectsList.scss";
import {tType, useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {useSelector} from "react-redux";
import {IAppState} from "../../store/AppState";

interface IHourlyObjectsListSelectProps {
    items: IHourlyObjectsItemAvailableTime[];
    disabled?: boolean;
    value: IHourlyObjectsItemAvailableTime | null;
    error?: boolean;
    selectedDate: string;
    withoutPlaceholderPrice?: boolean;
    placeholder?: React.ReactNode;
    onValueChange: (value: IHourlyObjectsItemAvailableTime) => void;
}

declare type SelectItem =  IHourlyObjectsItemAvailableTime | React.ReactElement | (() => React.ReactElement);

const getNoAvailableOptions = (selectedDate: string, t: tType) => (
    Select.static(() => (
        <Select.Item>
            <div className={styles.nextDay}>
                <Gapped vertical gap={5}>
                    {selectedDate}
                    {t("noAvailableOptions")}
                </Gapped>
            </div>
        </Select.Item>
    ))
);

const addDateSeparators = (items: IHourlyObjectsItemAvailableTime[], selectedDate: string, t: tType, offsetInMinutes: number) => {
    let dayPrev = "";

    return items.reduce((result: SelectItem[], item: IHourlyObjectsItemAvailableTime, index: number) => {
        const day = DateHelper.convertWithTimezone(item.timestamp, {
            formatIn: DateFormats.UnixMsTimestamp,
            formatOut: DateFormats.FullDateDayFirst,
            offsetInMinutes,
        });
        const isNotBeforeOrSameDate = DateHelper.isBeforeDate(selectedDate, day, DateFormats.FullDateDayFirst);
        const isNotFirst = index !== 0;

        const isAnotherDay = day !== dayPrev;
        dayPrev = day;

        return [
            ...result,
            isNotBeforeOrSameDate && !isNotFirst && getNoAvailableOptions(selectedDate, t),
            isNotBeforeOrSameDate && !isNotFirst && Select.SEP,
            isAnotherDay && isNotFirst && Select.SEP,
            isAnotherDay &&
            Select.static(() => (
                <Select.Item>
                    <div className={styles.nextDay}>{day}</div>
                </Select.Item>
            )),
            item
        ].filter(Boolean);
    }, [!items.length && getNoAvailableOptions(selectedDate, t)] as SelectItem[]);
};

const HourlyObjectsListSelect = (props: IHourlyObjectsListSelectProps) => {
    const {items, value, disabled, error, selectedDate, withoutPlaceholderPrice, placeholder, onValueChange} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModule);

    const offsetInMinutes = useSelector((state: IAppState) => state.hotelInfo.info?.timeZone?.offsetInMinutes);

    return (
        <Select<IHourlyObjectsItemAvailableTime>
            error={error}
            width={93}
            placeholder={placeholder}
            menuWidth={110}
            size="medium"
            className={styles.select}
            items={addDateSeparators(items, selectedDate, t, offsetInMinutes)}
            disabled={disabled}
            renderValue={(value: IHourlyObjectsItemAvailableTime) => (
                <div>
                    {DateHelper.convertWithTimezone(value.timestamp, {
                        formatIn: DateFormats.UnixMsTimestamp,
                        formatOut: DateFormats.OnlyTime,
                        offsetInMinutes,
                    })}
                </div>
            )}
            renderItem={(value: IHourlyObjectsItemAvailableTime) => (
                <HourlyObjectsTimeItem
                    isNotAvailable={value.isUnavailable}
                    withoutPlaceholderPrice={withoutPlaceholderPrice}
                    {...value}
                />
            )}
            value={value}
            onValueChange={onValueChange}
        />
    );
};

HourlyObjectsListSelect.displayName = "HourlyObjectsListSelect";
export default HourlyObjectsListSelect;
