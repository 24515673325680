import {useSelector} from "react-redux";
import {IAppState} from "../../../../../store/AppState";
import {IRoomCategoryAccommodation} from "../../../../../data/Accommodation";
import {BookingLightboxDataHelper} from "../../BookingLightboxDataHelper";
import {sortBy} from "lodash";
import BookingAccommodationsResults from "./BookingAccommodationsResults";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../../../constants/TranslationNamespaces";
import {AccommodationsContext} from "../../../../Accommodations/AccommodationsContext";
import {AccommodationRestrictionsHelper} from "../../../../Accommodations/helpers/AccommodationRestrictionsHelper";
import {bookingLightboxRecommendedAnalyticsEvents} from "../../../../../analytics/bookingLightboxRecommendedAnalyticsEvents";

const BookingRecommendedResults = () => {
    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);
    const {roomCategories} = useSelector((state: IAppState) => state.roomCategories);
    const {allAccommodations} = React.useContext(AccommodationsContext);

    const recommendedAccommodations = React.useMemo(() => {
        const items = AccommodationRestrictionsHelper.extractAccommodationsWithRestrictions(allAccommodations);
        if (items.length) {
            bookingLightboxRecommendedAnalyticsEvents.trackShowRecommendedList(items.length);
        }
        return items;
    }, [allAccommodations]);

    if (!recommendedAccommodations.length)
        return null;

    const getRoomCategoryId = ({roomCategoryId}: IRoomCategoryAccommodation) => roomCategoryId;
    const sortByRoomCategoryOrder = BookingLightboxDataHelper.byRoomCategoryOrder(roomCategories, getRoomCategoryId);
    const sortedResults = sortBy(recommendedAccommodations, sortByRoomCategoryOrder);

    return (
        <BookingAccommodationsResults
            title={t("accommodations.captions.recommended")}
            accommodations={sortedResults}
        />
    );
};
BookingRecommendedResults.displayName = "BookingRecommendedResults";
export default BookingRecommendedResults;
