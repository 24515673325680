import {iframeEvent} from "../analytics/iframeEvent";
import {useSelector} from "react-redux";
import {IAppState} from "../store/AppState";
import WidgetStore from "../widget/WidgetStore";

export const useIframeEventAnalytics = () => {
    const organizationName = useSelector((state: IAppState) => state.hotelInfo.info?.organizationName);

    React.useEffect(() => {
        const {hotelId} = WidgetStore.settings || {};
        const isIframe = window?.parent !== window;

        if (!isIframe || !organizationName) return;

        hotelId
            ? iframeEvent.initializationWidget(organizationName, hotelId)
            : iframeEvent.initializationMOB(organizationName);
    }, [organizationName]);
};
