import {AccommodationsContext} from "../../../../Accommodations/AccommodationsContext";
import {useSelector} from "react-redux";
import {IAppState} from "../../../../../store/AppState";
import BookingAccommodationsResults from "./BookingAccommodationsResults";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../../../constants/TranslationNamespaces";
import {sortBy} from "lodash";
import {BookingLightboxDataHelper} from "../../BookingLightboxDataHelper";
import {RoomCategorySearchParamsContext} from "../../../../SearchParams/SearchParamsContext";

interface IBookingSmallerResultsProps {
    targetPlacesCount: number;
    isExceedMaxCapacity: boolean;
}

const BookingSmallerResults = (props: IBookingSmallerResultsProps) => {
    const {targetPlacesCount, isExceedMaxCapacity} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);
    const {roomCategories} = useSelector((state: IAppState) => state.roomCategories);
    const {accommodations, accommodationsPricesMap} = React.useContext(AccommodationsContext);
    const {params} = React.useContext(RoomCategorySearchParamsContext);
    const {roomCategoryId} = params;

    const smallerAccommodations = React.useMemo(() => (
        BookingLightboxDataHelper.getSmallerAccommodations(accommodations, targetPlacesCount, roomCategories)
    ), [accommodations, targetPlacesCount, roomCategories]);

    if (!smallerAccommodations.length)
        return null;

    const getSortParams = BookingLightboxDataHelper.getAccommodationsSortParams(targetPlacesCount);
    const sortByPlacesCountDESC = BookingLightboxDataHelper.byPlacesCountDESC(roomCategories, getSortParams);
    const sortByMinPrice = BookingLightboxDataHelper.byMinRatePrice(accommodationsPricesMap, getSortParams);
    const sortItemsByPlacesCountDESC = BookingLightboxDataHelper.getSortItemsByPlacesCountDESC(roomCategories);

    const targetSmallerAccommodations = isExceedMaxCapacity && roomCategoryId
        ? smallerAccommodations.filter(a => a.roomCategoryId !== roomCategoryId)
        : smallerAccommodations;

    const sortedSmallerAccommodations = sortBy(targetSmallerAccommodations, sortByPlacesCountDESC, sortByMinPrice);

    return (
        <BookingAccommodationsResults
            title={t("accommodations.captions.smaller")}
            accommodations={sortedSmallerAccommodations}
            sortItems={sortItemsByPlacesCountDESC}
        />
    );
};
BookingSmallerResults.displayName = "BookingSmallerResults";
export default BookingSmallerResults;
