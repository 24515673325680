import _dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";

_dayjs.extend(customParseFormat);
_dayjs.extend(utc);

export const dayjs = _dayjs;

export type FullDateDayFirstString = string;
export type MonthWithYearString = string;

export enum UnitOfTime {
    Year = "year",
    Month = "month",
    Week = "week",
    Day = "day",
    Hour = "hour",
    Minute = "minute",
    Second = "second",
    Millisecond = "millisecond"
}
