import {ApiSiteBase} from "./apiSiteBase";
import {ApiBase} from "./apiBase";
import Fetcher from "../core/Fetcher";

export class CashbackApi extends ApiSiteBase {
    getCashbackTerminalIds(checkin: string, checkout: string): Promise<string[]> {
        const query = ApiBase.toQueryParams({
            checkin: ApiBase.toQueryDate(checkin),
            checkout: ApiBase.toQueryDate(checkout)
        });
        return Fetcher.get(this.buildRequestUrl() + query);
    }
}

export default new CashbackApi("cashback");
