import Fetcher from "../core/Fetcher";
import {ImageApi} from "./image";
import {ApiSiteBase} from "./apiSiteBase";
import {Compare} from "../helpers/CompareHelper";
import {IRate} from "../data/Rate";
import {IHotelFeature} from "../data/HotelFeature";
import {IBaseSearchParams} from "../data/SearchParams";
import {IHourlyAccommodation} from "../data/HourlyBooking";
import {ApiBase} from "./apiBase";
import {IHourlyObject} from "../data/HourlyObjects";
import {BookingKind} from "../data/BookingKind";

export class HourlyApi extends ApiSiteBase {
    getEntities = async (): Promise<IHourlyObject[]> => {
        const roomCategories = await Fetcher.get<IHourlyObject[]>(this.buildRequestUrl(["entities"]));
        const roomCategoriesWithImages = roomCategories.map(rc => {
            const {imageMetas, ...hourlyObjects} = rc;
            ImageApi.sortMetas(imageMetas);
            return {
                ...hourlyObjects,
                imageMetas,
            };
        });
        return roomCategoriesWithImages.sort(Compare.byOrder);
    };

    getRates = (): Promise<IRate[]> => {
        return Fetcher.get<IRate[]>(this.buildRequestUrl(["rates"]));
    };

    getFeatures = async (): Promise<IHotelFeature[]> => (
       await Fetcher.get<IHotelFeature[]>(this.buildRequestUrl(["features"]))
    );

    getAccommodations = (
        params: Omit<IBaseSearchParams, "adultsCount" | "kidsCount">
    ): Promise<IHourlyAccommodation[]> => {
        const query = ApiBase.toQueryParams({
            ...params,
            fromDate: params.fromDate,
            toDate: params.toDate,
        });
        return Fetcher.get<IHourlyAccommodation[]>(this.buildRequestUrl(["accommodations", query]));
    };
}

export default new HourlyApi(BookingKind.Hourly);
