import {BookingLightboxStep, IBookingLightboxStepsParams} from "./Steps/BookingLightboxStepsContext";
import {getAccommodationId} from "./Accommodations/Results/BookingAccommodationsResults";
import {BOOKING_LIGHTBOX_ORDER_ID} from "./Order/BookingLightboxOrder";
import {IFlippedResizerScrollTopAnimationSettings} from "../../../common/components/Flipper/Resizer/FlippedResizerScrollTopAnimation";
import {DeviceMode, DeviceSize} from "../../Device/DeviceContext";
import {BOOKING_LIGHTBOX_PROMO_CODE_ID} from "./Rates/Panel/BookingRatesPanel";

const STICKY_HEADER_OFFSET = 56;
const ITEM_TOP_PADDING = 20;

/* This constants linked with parameters in BookingLightbox.scss */
const LIGHTBOX_MARGINS = 60;
const LIGHTBOX_MARGINS_MOBILE_LARGE = 40;
const LIGHTBOX_MARGINS_MOBILE = 20;
const LIGHTBOX_MAX_WIDTH = 970;

export const BOOKING_LIGHTBOX_CLASSNAME = "BookingLightbox";

export class BookingLightboxHelper {
    static getWidth = (deviceMode: DeviceMode) => {
        const width = window.innerWidth;
        switch (deviceMode) {
            case DeviceMode.MobileLarge:
                return Math.min(width - LIGHTBOX_MARGINS_MOBILE_LARGE, LIGHTBOX_MAX_WIDTH);
            case DeviceMode.Mobile:
                return Math.min(DeviceSize.Mobile - LIGHTBOX_MARGINS_MOBILE, LIGHTBOX_MAX_WIDTH);
            default:
                return Math.min(width - LIGHTBOX_MARGINS, LIGHTBOX_MAX_WIDTH);
        }
    };

    static getScrollElement = (): HTMLElement => (
        document.querySelector(`.${BOOKING_LIGHTBOX_CLASSNAME} [data-tid="modal-container"]`)
    );

    static getScrollTopToElement = (elementId: string, scrollContainer: Element, isStickyHeader: boolean) => {
        const elementTop = document.getElementById(elementId)?.getBoundingClientRect().top || 0;
        const scrollTop = scrollContainer.scrollTop;
        // TODO Унести в scroll top normalize
        const maxScrollTop = scrollContainer.scrollHeight - scrollContainer.clientHeight;
        const offset = isStickyHeader ? STICKY_HEADER_OFFSET : 0;
        return Math.min(maxScrollTop, Math.max(elementTop + scrollTop - offset, 0));
    };

    static isScrollWaitHeight = (step: BookingLightboxStep) => step === BookingLightboxStep.FinishOrder;

    static getScrollSettings = (
        step: BookingLightboxStep,
        params: IBookingLightboxStepsParams,
        isStickyHeader: boolean,
        isPromoCodeAvailable: boolean
    ): IFlippedResizerScrollTopAnimationSettings => {
        const scrollElement = BookingLightboxHelper.getScrollElement();
        switch (step) {
            case BookingLightboxStep.SelectAccommodations:
                if (params) {
                    const accommodationId = getAccommodationId(params.roomCategoryId);
                    const roomCategoryTop = BookingLightboxHelper.getScrollTopToElement(accommodationId, scrollElement, isStickyHeader);
                    return {
                        top: roomCategoryTop - ITEM_TOP_PADDING,
                        scrollElement
                    };
                }
                break;
            case BookingLightboxStep.SelectRates:
                return {
                    top: params?.scrollToStart ? 0 : null,
                    scrollElement
                };
            case BookingLightboxStep.FinishOrder:
                const elementId = isPromoCodeAvailable
                    ? BOOKING_LIGHTBOX_PROMO_CODE_ID
                    : BOOKING_LIGHTBOX_ORDER_ID;

                const orderTop = BookingLightboxHelper.getScrollTopToElement(elementId, scrollElement, isStickyHeader);
                return {
                    top: params?.scrollToStart ? 0 : orderTop - ITEM_TOP_PADDING,
                    speedRate: 1.5,
                    scrollElement
                };
            default:
                return {
                    top: 0,
                    scrollElement
                };
        }
    };
}
