import {KonturI18NContext} from "@skbkontur/i18n";
import TBankSbpRus from "../../images/SbpRus.svg";
import TBankSbpEng from "../../images/SbpRus.svg";
import styles from "../../providers/Booking/BookingLightbox/Order/BookingLightboxOrder.scss";
import WidgetStore from "../../widget/WidgetStore";

export const TBankSbpLogo = () => {
    const {locale} = React.useContext(KonturI18NContext);
    const imageSrc = locale === "ru" ? TBankSbpRus : TBankSbpEng;
    return (
        <img className={styles.icon} src={WidgetStore.getUrlWithBase(imageSrc)}/>
    );
};
