import {HourlyBookingContext} from "./HourlyBookingContext";
import HourlyBookingLightbox from "./HourlyBookingLightbox/HourlyBookingLightbox";
import {useMemoObject} from "../../common/hooks/useMemoObject";
import {createHourlyBooking} from "../../store/hourlyBooking/hourlyBookingActionCreators";
import {clearHourlyAccommodations} from "../../store/hourlyAccommodations/hourlyAccommodationsActionCreators";
import {useCommonBookingProvider} from "../../hooks/useCommonBookingProvider";
import { BookingKind } from "../../data/BookingKind";

export const HourlyBookingProvider = (props: React.PropsWithChildren<object>) => {
    const {children} = props;

    const {
        isBookingLightboxOpened,
        isBookingProcess,
        handleBooking,
        closeBookingLightbox,
        openBookingLightbox
    } = useCommonBookingProvider({
        kind: BookingKind.Hourly,
        clearAccommodations: clearHourlyAccommodations,
        createBooking: createHourlyBooking,
    });

    return (
        <HourlyBookingContext.Provider
            value={useMemoObject({
                isBookingProcess,
                openBookingLightbox,
            })}
        >
            {children}
            {isBookingLightboxOpened && (
                <HourlyBookingLightbox onBooking={handleBooking} onClose={closeBookingLightbox} />
            )}
        </HourlyBookingContext.Provider>
    );
};
