export enum TranslationNamespaces {
    Common = "common",
    Bookings = "bookings",
    BookingModule = "bookingModule",
    BookingModuleBooking = "bookingModule~Booking",
    BookingModuleOnlinePayment = "bookingModule~onlinePayment",
    BookingLightbox = "bookings~BookingLightbox",
    BookingConfirmation = "bookingConfirmation~confirmation",
    BookingModuleCalendar = "bookingModule~calendar",
    RoomCategories = "roomCategories"
}

export const getAllTranslationNamespaces = () => Object.values(TranslationNamespaces);
