import Counter, {ICounterProps} from "./Counter";
import styles from "./Counter.scss";

const CounterText = (props: ICounterProps) => (
    <Counter {...props}>
        {value => (
            <div className={styles.text}>
                {value}
            </div>
        )}
    </Counter>
);
CounterText.displayName = "CounterText";
export default CounterText;
