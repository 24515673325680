import Fetcher from "../core/Fetcher";
import {UrlHelper} from "../helpers/UrlHelper";
import {ApiBase} from "./apiBase";
import {IPayment, PaymentLinkStatus} from "../data/Payment";

export interface IPaymentsByLinkCreateResponse {
    confirmationUrl: string;
    payment: IPayment;
    linkStatus: PaymentLinkStatus;
}

export interface IPaymentsByLinkApi {
    getPaymentLink: (language: string, linkId: string) => Promise<IPaymentsByLinkCreateResponse>;
}

export class PaymentsByLinkApi extends ApiBase<IPaymentsByLinkApi> {
    getPaymentLink(language: string, linkId: string): Promise<IPaymentsByLinkCreateResponse> {
        const params = {
            returnUrl: UrlHelper.getSiteOriginAndPathname(),
            language,
            linkId
        };
        return Fetcher.postJSON(this.buildRequestUrl(), params);
    }
}

export default new PaymentsByLinkApi("payments-by-link");
