import styles from "./BookingDatesNotAvailableCalendar.scss";
import RoomAvailabilityCalendarWithFilter
    from "../../../../components/RoomAvailabilityCalendarWithFilter/RoomAvailabilityCalendarWithFilter";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../../constants/TranslationNamespaces";
import {CalendarMode} from "../../../../data/Calendar/Calendar";
import {useSelector} from "react-redux";
import {IAppState} from "../../../../store/AppState";
import {RoomCategorySearchParamsContext} from "../../../SearchParams/SearchParamsContext";
import RoomAvailabilityCalendar from "../../../../components/RoomAvailabilityCalendar/RoomAvailabilityCalendar";
import BookingLightboxContainer from "../BookingLightboxContainer";

const BookingDatesNotAvailableCalendar = () => {
    const {params} = React.useContext(RoomCategorySearchParamsContext);
    const {roomCategoryId} = params;

    const roomCategories = useSelector((state: IAppState) => state.roomCategories.roomCategories);
    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);

    const title = roomCategoryId ? (
        t("accommodations.warnings.forCategory", {
            reactParams: {
                CategoryName: roomCategories.find(rc => rc.id === roomCategoryId).name
            }
        })
    ) : (
        t("accommodations.warnings.forTheseDates")
    );

    return (
        <>
            <BookingLightboxContainer>
                <div className={styles.emptyTextTitle}>
                    {title}
                </div>
                <div className={styles.emptyText}>
                    {t("accommodations.warnings.forTheseDatesDescription", {raw: true})}
                </div>
            </BookingLightboxContainer>
            {roomCategoryId ? (
                <RoomAvailabilityCalendar
                    searchParams={params}
                    roomCategoryId={roomCategoryId}
                    calendarMode={CalendarMode.TwoMonthsRegular}
                />
            ) : (
                <RoomAvailabilityCalendarWithFilter
                    calendarMode={CalendarMode.TwoMonthsRegular}
                />
            )}
        </>
    );
};
BookingDatesNotAvailableCalendar.displayName = "BookingDatesNotAvailableCalendar";
export default BookingDatesNotAvailableCalendar;
