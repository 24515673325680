import {IHotelFeature} from "../../data/HotelFeature";
import {Reducer} from "redux";
import {HotelFeaturesActionTypes, KnownHotelFeaturesAction} from "./hotelFeaturesActionCreators";

export interface IHotelFeaturesState {
    error: Error;
    isLoading: boolean;
    hotelFeatures: IHotelFeature[];
}

const initialState: IHotelFeaturesState = {
    hotelFeatures: [],
    error: null,
    isLoading: false,
};

export const hotelFeaturesReducer: Reducer<IHotelFeaturesState> = (
    state: IHotelFeaturesState = initialState,
    action: KnownHotelFeaturesAction
) => {
    switch (action.type) {
        case HotelFeaturesActionTypes.GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case HotelFeaturesActionTypes.GET_SUCCESS:
            return {
                ...state,
                hotelFeatures: action.response,
                isLoading: false,
            };
        case HotelFeaturesActionTypes.GET_ERROR:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        default:
            return state;
    }
};
