import {IBookingAccommodation} from "../../../../../../data/Booking";
import {useRoomCategory} from "../../../../../../hooks/useRoomCategory";
import {RoomCategorySearchParamsContext} from "../../../../../SearchParams/SearchParamsContext";
import {FREE_CHILDREN_COUNT, MIN_ADULTS_COUNT} from "../../../../../../data/HotelInfo";
import {RoomCategoryHelper} from "@skbkontur/hotel-data/roomCategory";
import {IOccupancy} from "../../../../../../data/Accommodation";
import cn from "classnames";
import styles from "./BookingAccommodationsOccupancy.scss";
import {People1Icon16Light} from "@skbkontur/icons/People1Icon16Light";
import {ChildBabyIcon16Light} from "@skbkontur/icons/ChildBabyIcon16Light";
import {ArrowCDownIcon16Regular} from "@skbkontur/icons/ArrowCDownIcon16Regular";
import {Tooltip} from "@skbkontur/react-ui";
import {AccommodationsContext} from "../../../../../Accommodations/AccommodationsContext";
import {bookingLightboxOccupancyAnalyticsEvents} from "../../../../../../analytics/bookingLightboxOccupancyAnalyticsEvents";
import {OccupancyHelper} from "../../../../../../helpers/OccupancyHelper";
import GuestsAccommodationTooltip from "../../../../../../components/GuestsAccommodationTooltip/GuestsAccommodationTooltip";
import {ICurrency} from "../../../../../../types/Currency";

interface IBookingAccommodationsOccupancyItemProps extends IOccupancy {
    index: number;
    accommodation: IBookingAccommodation;
    cost: ICurrency;
    disabled: boolean;
}

const BookingAccommodationsOccupancyItem = (props: IBookingAccommodationsOccupancyItemProps) => {
    const {accommodation, index, mainOccupancy, additionalOccupancy, cost, disabled} = props;
    const {roomCategoryId, adultsCount, childrenCount} = accommodation;

    const {params: {kidsCount}} = React.useContext(RoomCategorySearchParamsContext);
    const [tooltipOpened, setTooltipOpened] = React.useState<boolean>(false);

    const roomCategory = useRoomCategory(roomCategoryId);
    const {updateAccommodation, accommodationsVariantsMap} = React.useContext(AccommodationsContext);

    const occupancyVariants = OccupancyHelper.findItemValue(accommodationsVariantsMap?.[roomCategoryId] || [], mainOccupancy, additionalOccupancy);

    const update = (accommodation: IBookingAccommodation) => {
        updateAccommodation({
            roomCategoryId,
            mainOccupancy,
            additionalOccupancy,
            index,
            accommodation
        });
    };

    const onGuestsCountChange = (newAdultsCount: number, newChildrenCount: number) => {
        newAdultsCount !== adultsCount && bookingLightboxOccupancyAnalyticsEvents.trackAdultsCountChange(adultsCount);
        newChildrenCount !== childrenCount && bookingLightboxOccupancyAnalyticsEvents.trackChildrenCountChange(childrenCount);
        update({
            ...accommodation,
            adultsCount: newAdultsCount,
            childrenCount: newChildrenCount
        });
        setTooltipOpened(false);
    };
    const isHostel = RoomCategoryHelper.isHostelCategory(roomCategory);
    const placesCount = RoomCategoryHelper.getGuestsCount(roomCategory);
    const maxChildrenCount = placesCount - MIN_ADULTS_COUNT + FREE_CHILDREN_COUNT;
    const showChildrenCount = maxChildrenCount > 0 && !!kidsCount && !isHostel;

    return (
        <Tooltip
            render={() => (
                <GuestsAccommodationTooltip
                    showChildren={showChildrenCount}
                    maxChildrenCount={maxChildrenCount}
                    occupancyVariants={occupancyVariants}
                    accommodation={accommodation}
                    cost={cost}
                    onSave={onGuestsCountChange}
                />
            )}
            pos="bottom left"
            closeButton={false}
            trigger={tooltipOpened ? "opened" : "closed"}
            onCloseRequest={() => setTooltipOpened(false)}
        >
            <div className={cn(styles.field, {[styles.disabled]: disabled})} onClick={() => !disabled && setTooltipOpened(true)}>
                <div className={styles.guests}>
                    <div className={styles.guestsItem}>
                        {adultsCount} <People1Icon16Light />
                    </div>
                    {showChildrenCount && (
                        <>
                            +
                            <div className={styles.guestsItem}>
                                {childrenCount} <ChildBabyIcon16Light />
                            </div>
                        </>
                    )}
                </div>
                <div className={styles.arrowIcon}>
                    <ArrowCDownIcon16Regular />
                </div>
            </div>
        </Tooltip>
    );
};
BookingAccommodationsOccupancyItem.displayName = "BookingAccommodationsOccupancyItem";
export default React.memo(BookingAccommodationsOccupancyItem);
