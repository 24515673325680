import cn from "classnames";
import {WidgetClientGlobalStyles} from "../../widget/WidgetClientGlobalStyles";
import WidgetStore from "../../widget/WidgetStore";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {IAppState} from "../../store/AppState";
import {useSelector} from "react-redux";
import {mainSearchAnalyticsEvents} from "../../analytics/mainSearchAnalyticsEvents";

interface IRoomCategoriesListCheckButtonProps {
    onClick: () => void;
}

const RoomCategoriesListCheckButton = (props: IRoomCategoriesListCheckButtonProps) => {
    const {onClick} = props;

    const {isLoading} = useSelector((state: IAppState) => state.accommodations);
    const {t} = useTranslation(TranslationNamespaces.BookingModule);

    const btnClasses = cn({
        [WidgetClientGlobalStyles.WidgetCardPriceButtonContainer]: WidgetStore.isWidget(),
    });

    const handleSearch = () => {
        mainSearchAnalyticsEvents.trackRoomCategoriesRunSearch();
        onClick();
    };

    return (
        <div className={btnClasses}>
            <PrimaryButton loading={isLoading} onClick={handleSearch}>
                {t("checkAvailability")}
            </PrimaryButton>
        </div>
    );
};
RoomCategoriesListCheckButton.displayName = "RoomCategoriesListCheckButton";
export default RoomCategoriesListCheckButton;
