import {useSelector} from "react-redux";
import DateHelper from "../../../../helpers/DateHelper";
import {IAppState} from "../../../../store/AppState";
import {DateFormats} from "../../../../types/DateFormats";
import {HourlyBookingLightboxHelper} from "../HourlyBookingLightboxHelper";

export const useHourlyPeriod = (timeFrom: number, timeTo: number) => {
    const offsetInMinutes = useSelector((state: IAppState) => state.hotelInfo.info.timeZone.offsetInMinutes);
    const date = DateHelper.convertWithTimezone(timeFrom, {
        formatIn: DateFormats.UnixMsTimestamp,
        formatOut: DateFormats.FullDateDayFirst,
        offsetInMinutes,
    });

    const {fromDate, toDate} = HourlyBookingLightboxHelper.getAccommodationsParams(timeFrom, timeTo, offsetInMinutes);
    const isSameDate = DateHelper.isSameDate(fromDate, toDate, DateFormats.FullDateYearFirstWithTime);

    return {
        date,
        hourFrom: DateHelper.convertWithTimezone(timeFrom, {
            formatIn: DateFormats.UnixMsTimestamp,
            formatOut: DateFormats.OnlyTime,
            offsetInMinutes,
        }),
        hourTo: DateHelper.convertWithTimezone(timeTo, {
            formatIn: DateFormats.UnixMsTimestamp,
            formatOut: isSameDate ? DateFormats.OnlyTime : DateFormats.FullDateDayFirstWithTime,
            offsetInMinutes,
        }),
    };
};
