import BookingSearchForm from "./BookingSearchForm";
import {RoomCategorySearchParamsContext} from "../../providers/SearchParams/SearchParamsContext";
import {BookingContext} from "../../providers/Booking/BookingContext";
import {IRoomCategorySearchParams} from "../../data/SearchParams";
import FixedButton from "../FixedButton/FixedButton";
import {DeviceContext} from "../../providers/Device/DeviceContext";

interface IHotelBookingSearchFormProps {
    inline?: boolean;
    showFixedButtonOnMobile?: boolean;
    onFrontPage?: boolean;
    noContainerPadding?: boolean;

}

const HotelBookingSearchForm = (props: IHotelBookingSearchFormProps) => {
    const {inline, showFixedButtonOnMobile, onFrontPage, noContainerPadding} = props;

    const {params, setParams} = React.useContext(RoomCategorySearchParamsContext);
    const {openBookingLightbox} = React.useContext(BookingContext);

    const {isMobileMode} = React.useContext(DeviceContext);

    if (showFixedButtonOnMobile && isMobileMode)
        return <FixedButton />;

    const handleParamsChange = (params: IRoomCategorySearchParams) => {
        setParams(currentParams => ({
            ...currentParams,
            ...params,
            roomCategoryId: null
        }));
    };

    const handleOpenBookingLightbox = () => {
        handleParamsChange(params);
        openBookingLightbox();
    };

    return (
        <BookingSearchForm
            alwaysActiveButton
            withoutBorder
            inline={inline}
            params={params}
            onSearch={handleOpenBookingLightbox}
            onChange={handleParamsChange}
            onFrontPage={onFrontPage}
            noContainerPadding={noContainerPadding}
        />
    );
};
HotelBookingSearchForm.displayName = "HotelBookingSearchForm";
export default HotelBookingSearchForm;
