export default class HtmlHelper {

    static getAbsolutePosition(elm: HTMLElement): {
        left: number,
        top: number,
        right: number,
        bottom: number
    } {
        const position = elm.getBoundingClientRect();
        const body = document.body,
              html = document.documentElement;
        const width = Math.max(body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth);
        const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        return {
            left: position.left,
            top: position.top,
            right: width - position.right,
            bottom: height - position.bottom
        };
    }
}
