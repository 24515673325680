import {Modal} from "@skbkontur/react-ui";
import {ZIndex} from "@skbkontur/react-ui/internal/ZIndex";
import {useTranslation} from "@skbkontur/i18n";
import PrimaryButton from "../PrimaryButton/PrimaryButton";

interface IWarningModalProps {
    title: React.ReactNode;
    message: React.ReactNode;
    onClose: () => void;
    selector?: string;
}

export const WarningModal = (props: IWarningModalProps) => {
    const {title, message, onClose, selector = "WarningModal"} = props;
    const {tcn} = useTranslation([]);

    return (
        <ZIndex delta={999999}>
            <Modal
                disableFocusLock
                onClose={onClose}
                width={500}
                ignoreBackgroundClick
                data-tid={selector}
            >
                <Modal.Header>{title}</Modal.Header>
                <Modal.Body>
                    <div style={{paddingTop: 10}}>{message}</div>
                </Modal.Body>
                <Modal.Footer>
                    <PrimaryButton use="default" onClick={onClose} data-tid="CloseButton">
                        {tcn("buttons.close")}
                    </PrimaryButton>
                </Modal.Footer>
            </Modal>
        </ZIndex>
    );
};
