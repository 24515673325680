export interface IFlipperContext {
    parentFlipId: string;
    delayedFlipId?: string;
}

export const FlipperContext = React.createContext({} as IFlipperContext);

export const getFlipId = (...parts: (string|number)[]) => (
    parts.map(String).filter(Boolean).join("_")
);
