import {Reducer} from "redux";
import {PaymentTerminalActionTypes} from "./paymentSourceActionCreators";
import {KnownPaymentTerminalAction} from "./paymentSourceActionCreators";
import {IPaymentTerminal} from "../../data/Payment";

export interface IPaymentTerminalState {
    isLoading: boolean;
    error?: Error;
    terminals: IPaymentTerminal[];
}

const initialState = {
    isLoading: false,
    error: null,
    terminals: []
} as IPaymentTerminalState;

export const paymentTerminalReducer: Reducer<IPaymentTerminalState> = (
    state: IPaymentTerminalState = initialState, action: KnownPaymentTerminalAction
) => {
    switch (action.type) {
        case (PaymentTerminalActionTypes.GET_SETTINGS_REQUEST):
            return {
                ...state,
                isLoading: true
            };

        case (PaymentTerminalActionTypes.GET_SETTINGS_ERROR):
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };

        case (PaymentTerminalActionTypes.GET_SETTINGS_SUCCESS):
            return {
                ...state,
                isLoading: false,
                terminals: action.response
            };

        default:
            return state;
    }
};
