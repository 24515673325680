import {IHourlyObjectSearchParams} from "../../../data/SearchParams";
import DateHelper from "../../../helpers/DateHelper";
import {
    RoomCategorySearchParamsContext,
    HourlyObjectsSearchParamsContext,
} from "../../../providers/SearchParams/SearchParamsContext";
import {DateFormats} from "../../../types/DateFormats";
import {useSelector} from "react-redux";
import {IAppState} from "../../../store/AppState";

interface IUseGetSearchParamsHourlyObjects {
    dateFromSearchParams: string;
    params: IHourlyObjectSearchParams;
    setParams: (action: (params: IHourlyObjectSearchParams) => IHourlyObjectSearchParams) => void
}

export const useGetSearchParamsHourlyObjects = (): IUseGetSearchParamsHourlyObjects => {
    const offsetInMinutes = useSelector((state: IAppState) => state.hotelInfo.info?.timeZone?.offsetInMinutes);

    const {params: paramsRoom} = React.useContext(RoomCategorySearchParamsContext);
    const {params, setParams} = React.useContext(HourlyObjectsSearchParamsContext);

    const {fromDate: fromDateFromParams, timeFrom} = params;

    const fromDate = fromDateFromParams || paramsRoom.fromDate;

    const dateFromSearchParams = timeFrom
        ? DateHelper.convertWithTimezone(timeFrom, {
            formatIn: DateFormats.UnixMsTimestamp,
            formatOut: DateFormats.FullDateDayFirst,
            offsetInMinutes
        })
        : fromDate;

    return {dateFromSearchParams, params, setParams};
};
