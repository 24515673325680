// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".POZeep{display:flex;justify-content:space-between}.OGUOHF{margin:0 3px}.LbrfB3{align-self:center;flex:1;font-size:14px;margin:0 5px}.SbQHoy{position:relative}.Rn1doW,.PQtN64{flex-grow:1;width:126px}.RFGh7z .Rn1doW{margin-right:4px}.RFGh7z .PQtN64{margin-left:4px}.RFGh7z .LbrfB3{display:none}.Azte92{color:rgba(0,0,0,.37);cursor:text;left:10px;position:absolute;top:50%;transform:translateY(-50%);z-index:10}.swh2T8>span{font-size:14px}", "",{"version":3,"sources":["webpack://./src/components/DatesPeriodControl/DatesPeriodControl.scss"],"names":[],"mappings":"AAEA,QACI,YAAA,CACA,6BAAA,CAGJ,QACI,YAAA,CAGJ,QAEI,iBAAA,CADA,MAAA,CAEA,cAAA,CACA,YAAA,CAGJ,QACI,iBAAA,CAGJ,gBACI,WAAA,CACA,WAAA,CAGJ,gBACI,gBAAA,CAGJ,gBACI,eAAA,CAGJ,gBACI,YAAA,CAGJ,QAEI,qBAAA,CAKA,WAAA,CADA,SAAA,CALA,iBAAA,CAIA,OAAA,CADA,0BAAA,CADA,UAIA,CAIA,aACI,cAAA","sourcesContent":["@import \"@skbkontur/hotel-styles/adaptive\";\r\n\r\n.control {\r\n    display: flex;\r\n    justify-content: space-between;\r\n}\r\n\r\n.input {\r\n    margin: 0 3px;\r\n}\r\n\r\n.dash {\r\n    flex: 1;\r\n    align-self: center;\r\n    font-size: 14px;\r\n    margin: 0 5px;\r\n}\r\n\r\n.datePickerWrapper {\r\n    position: relative;\r\n}\r\n\r\n.dateFrom, .dateTo {\r\n    flex-grow: 1;\r\n    width: 126px;\r\n}\r\n\r\n.withoutDash .dateFrom {\r\n    margin-right: 4px;\r\n}\r\n\r\n.withoutDash .dateTo {\r\n    margin-left: 4px;\r\n}\r\n\r\n.withoutDash .dash {\r\n    display: none;\r\n}\r\n\r\n.placeholder {\r\n    position: absolute;\r\n    color: rgb(0 0 0 / 37%);\r\n    z-index: 10;\r\n    transform: translateY(-50%);\r\n    top: 50%;\r\n    left: 10px;\r\n    cursor: text;\r\n}\r\n\r\n.datePicker {\r\n    > span {\r\n        font-size: 14px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"control": "POZeep",
	"input": "OGUOHF",
	"dash": "LbrfB3",
	"datePickerWrapper": "SbQHoy",
	"dateFrom": "Rn1doW",
	"dateTo": "PQtN64",
	"withoutDash": "RFGh7z",
	"placeholder": "Azte92",
	"datePicker": "swh2T8"
};
export default ___CSS_LOADER_EXPORT___;
