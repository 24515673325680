import WidgetStore from "../widget/WidgetStore";

export enum SentryMOBTag {
    MOBType = "MOBtype",
}

export enum SentryMOBType {
    Widget = "widget",
    Site = "site"
}

export const getSentryMOBType = (): SentryMOBType => (
    WidgetStore.isWidget()
        ? SentryMOBType.Widget
        : SentryMOBType.Site
);