const MIN_DURATION = 300;
const MAX_DURATION = 2000;

export interface IFlippedResizerAnimationSettings {
    /** Explicitly setup scrollTop speed, default values is speed of height */
    speedRate?: number;
}

const DEFAULT_SPEED_RATE = 1;

export abstract class FlippedResizerAnimation<TSettings extends IFlippedResizerAnimationSettings> {
    protected startValue: number;
    protected endValue: number;
    protected timeout: NodeJS.Timeout = null;

    protected abstract getSettings: () => TSettings;

    protected get settings(): TSettings {
        return this.getSettings?.() || {} as TSettings;
    }

    protected abstract animationProcess: (duration: number) => boolean;
    protected abstract updateStartValue: () => void;
    protected abstract updateEndValue: () => void;

    protected abstract setFinishValues: () => void;
    protected abstract stop?: () => void;

    public updateGetSettings = (getSettings: () => TSettings) => {
        this.getSettings = getSettings;
    };

    public animate = (duration?: number): Promise<void> => (
        new Promise(resolve => {
            duration = duration || this.getDuration();
            const isAnimationHappen = this.animationProcess(duration);

            if (isAnimationHappen) {
                this.timeout = setTimeout(() => {
                    this.finish();
                    resolve();
                }, duration);
            } else {
                resolve();
            }
        })
    );

    public prepareAnimation = () => {
        if (this.timeout !== null) {
            clearTimeout(this.timeout);
            this.stop?.();
            this.finish();
        }
        this.updateEndValue();
    };

    protected finish = () => {
        this.updateStartValue();
        this.setFinishValues();
        this.timeout = null;
    };

    public getDuration = (): number => {
        const delta = this.endValue - this.startValue;
        const speedRate = this.settings?.speedRate || DEFAULT_SPEED_RATE;
        const speedDuration = speedRate * Math.min(Math.abs(delta), this.endValue);
        return Math.min(Math.max(speedDuration, MIN_DURATION), MAX_DURATION);
    };

    protected px = (value: number) => `${Math.ceil(value)}px`;
}
