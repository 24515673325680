// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".adraOQ{height:100%;object-fit:cover;width:100%}", "",{"version":3,"sources":["webpack://./../packages/components/image/Image.scss"],"names":[],"mappings":"AAAA,QAEI,WAAA,CACA,gBAAA,CAFA,UAEA","sourcesContent":[".image {\r\n    width: 100%;\r\n    height: 100%;\r\n    object-fit: cover;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "adraOQ"
};
export default ___CSS_LOADER_EXPORT___;
