import cn from "classnames";
import styles from "../Slider.scss";
import {ArrowCRightIcon16Light} from "@skbkontur/icons/ArrowCRightIcon16Light";
import {ArrowCLeftIcon16Light} from "@skbkontur/icons/ArrowCLeftIcon16Light";

interface ISliderArrowProps {
    changeSlide: (direction: number) => void;
}

const SliderArrows = (props: ISliderArrowProps) => {
    const {changeSlide} = props;

    const handleClick = (event: React.MouseEvent<HTMLDivElement>, direction: number) => {
        event.preventDefault();
        event.stopPropagation();

        changeSlide(direction);
    };

    return (
        <div className={styles.arrows}>
            <div
                className={cn(styles.arrow, styles.left)}
                onClick={(event: React.MouseEvent<HTMLDivElement>) => handleClick(event, -1)}
            >
                <ArrowCLeftIcon16Light />
            </div>
            <div
                className={cn(styles.arrow, styles.right)}
                onClick={(event: React.MouseEvent<HTMLDivElement>) => handleClick(event, 1)}
            >
                <ArrowCRightIcon16Light />
            </div>
        </div>
    );
};

SliderArrows.displayName = "SliderArrows";
export default SliderArrows;
