import {HourlyObjectsSearchParamsContext} from "../../../providers/SearchParams/SearchParamsContext";
import {IHourlyObjectsItemAvailableTime, IHourlyObjectsListAvailableTimeState} from "../HourlyObjectsListAvailableTime";

interface IUseHourlyStateUpdateBySearchParamsProps {
    from: IHourlyObjectsItemAvailableTime;
    to: IHourlyObjectsItemAvailableTime;
    setState: React.Dispatch<React.SetStateAction<IHourlyObjectsListAvailableTimeState>>;
    hourlyObjectIdFromProps: string;
    hourlyObjectItemIdFromProps?: string;
    fromItems: IHourlyObjectsItemAvailableTime[];
    toItems: IHourlyObjectsItemAvailableTime[];
}

export const useHourlyStateUpdateBySearchParams = (props: IUseHourlyStateUpdateBySearchParamsProps) => {
    const {from, to, hourlyObjectIdFromProps, hourlyObjectItemIdFromProps, fromItems, toItems, setState} = props;
    const {params} = React.useContext(HourlyObjectsSearchParamsContext);

    React.useEffect(() => {
        const {
            hourlyObjectId: hourlyObjectIdFromParams,
            hourlyObjectItemId: hourlyObjectItemIdFromParams,
            timeFrom,
            timeTo,
        } = params;

        const isNotSameHourlyObject =
            hourlyObjectIdFromProps !== hourlyObjectIdFromParams ||
            hourlyObjectItemIdFromParams !== hourlyObjectItemIdFromProps;

        if (isNotSameHourlyObject || !hourlyObjectIdFromParams || from || to) {
            return;
        }

        const newFrom = (timeFrom && fromItems.find(el => el.timestamp === timeFrom)) || null;
        const newTo = toItems.find(el => el.timestamp === timeTo) || null;

        if (to?.timestamp !== timeTo || from?.timestamp !== timeFrom) {
            setState((prev: IHourlyObjectsListAvailableTimeState) => ({...prev, from: newFrom, to: newTo}));
        }
    }, [
        from,
        to,
        params,
        hourlyObjectIdFromProps,
        hourlyObjectItemIdFromProps,
        fromItems,
        toItems,
    ]);
};
