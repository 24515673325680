import Widget from "./components/Widget/Widget";
import WidgetStore from "./WidgetStore";
import AppRoot from "../components/AppRoot";
import {Router} from "react-router-dom";
import GlobalErrorLightbox from "../components/GlobalErrorLightbox/GlobalErrorLightbox";
import {createBrowserHistory} from "history";

interface IWidgetAppState {
    hasError: boolean;
    error: Error;
    refreshCount: number;
}

const history = createBrowserHistory();

export default class WidgetApp extends React.Component<object, IWidgetAppState> {
    state: IWidgetAppState = {
        hasError: false,
        error: null as Error,
        refreshCount: 1
    };

    static getDerivedStateFromError = (error: Error) => (
        {error, hasError: true}
    );

    componentDidMount() {
        WidgetStore.settings.hooks.onInit();

        /* eslint-disable-next-line no-console */
        console.log("Widget initialization is OK");
    }

    componentDidUpdate() {
        const {hasError, error} = this.state;
        if (hasError) {
            WidgetStore.settings.hooks.onError(error);
            throw error;
        }
    }

    refreshApp() {
        this.setState(state => ({...state, refreshCount: state.refreshCount + 1}));
    }

    render() {
        const {hasError} = this.state;
        return hasError === false && (
            <Router history={history}>
                <AppRoot>
                    <GlobalErrorLightbox />
                    <Widget />
                </AppRoot>
            </Router>
        );
    }
}
