import {WidgetSettingsNormalizer} from "./WidgetSettingsNormalizer";
import {IWidgetSettingsContainer, WidgetInsertType, WidgetType} from "./WidgetSettingsTypes";

export class WidgetSettingsRoots {
    static createAppRoot = (widgetId: string): HTMLElement => {
        const appRoot = document.createElement("div");
        appRoot.id = this.getAppRootId(widgetId);
        return document.body.insertBefore(appRoot, null);
    };

    static createWidgetRoot = (widgetId: string, type: WidgetType, appearanceSettings: IWidgetSettingsContainer): HTMLElement => (
        appearanceSettings?.container
            ? this.createRootAndRenderInContainer(appearanceSettings, `${widgetId}_${type}`)
            : null
    );

    static getAppRootId = (widgetId: string): string => `${widgetId}_AppRoot`;

    private static createRootAndRenderInContainer(render: IWidgetSettingsContainer, elementId: string): HTMLElement {
        const currentRoot = document.getElementById(elementId);
        if (currentRoot) return currentRoot;

        const container = WidgetSettingsNormalizer.normalizeContainer(render.container);
        const root = document.createElement("div");
        root.id = elementId;

        switch (render.insert) {
            case WidgetInsertType.Before:
                return container.insertBefore(root, container.firstChild || null);
            case WidgetInsertType.Replace:
                container.replaceChildren(root);
                return root;
            default:
                return container.insertBefore(root, null);
        }
    }
}
