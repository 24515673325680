import {FlippedResizerAnimation, IFlippedResizerAnimationSettings} from "./FlippedResizerAnimation";
import styles from "./FlipperResizer.scss";

export interface IFlippedResizerHeightAnimationSettings extends IFlippedResizerAnimationSettings {
    withOverflow?: boolean;
}

export class FlippedResizerHeightAnimation extends FlippedResizerAnimation<IFlippedResizerHeightAnimationSettings> {
    protected stop?: () => void;

    public getSettings: () => IFlippedResizerHeightAnimationSettings;

    constructor(private readonly container: HTMLDivElement) {
        super();
        this.updateStartValue();
    }

    protected animationProcess = (duration: number): boolean => {
        if (this.settings?.withOverflow) {
            this.container.classList.add(styles.withOverflow);
        }
        this.container.style.transition = `height ${duration}ms ease`;
        this.container.style.height = this.px(this.startValue);
        /** Wait next frame to apply height transition */
        requestAnimationFrame(() => {
            this.container.style.height = this.px(this.endValue);
        });
        return true;
    };

    protected setFinishValues = (): void => {
        this.container.style.transition = "none";
        this.container.style.height = "auto";
        this.container.classList.remove(styles.withOverflow);
    };

    protected updateStartValue = (): void => {
        this.startValue = this.getHeight();
    };

    protected updateEndValue = (): void => {
        this.endValue = this.getHeight();
    };

    private getHeight = () => this.container.offsetHeight;
}
