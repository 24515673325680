import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../../../constants/TranslationNamespaces";
import {useSelector} from "react-redux";
import {IAppState} from "../../../../../store/AppState";
import {AccommodationsContext} from "../../../../Accommodations/AccommodationsContext";
import {BookingLightboxDataHelper} from "../../BookingLightboxDataHelper";
import BookingDatesNotAvailableCalendar from "../../DatesNotAvailableCalendar/BookingDatesNotAvailableCalendar";
import {sortBy} from "lodash";
import BookingAccommodationsResults from "./BookingAccommodationsResults";
import BookingExceedCapacityResult from "./BookingExceedCapacityResult";

interface IBookingMainResultsProps {
    targetPlacesCount: number;
    isExceedMaxCapacity: boolean;
}

const BookingMainResults = (props: IBookingMainResultsProps) => {
    const {targetPlacesCount, isExceedMaxCapacity} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);
    const {roomCategories} = useSelector((state: IAppState) => state.roomCategories);
    const {accommodations, accommodationsPricesMap} = React.useContext(AccommodationsContext);

    const mainAccommodations = React.useMemo(() => (
        BookingLightboxDataHelper.getEqualOrBiggerAccommodations(accommodations, targetPlacesCount, roomCategories)
    ), [accommodations, targetPlacesCount, roomCategories]);

    if (!mainAccommodations.length)
        return isExceedMaxCapacity
            ? <BookingExceedCapacityResult />
            : <BookingDatesNotAvailableCalendar />;

    const getSortParams = BookingLightboxDataHelper.getAccommodationsSortParams(targetPlacesCount);
    const sortByPlacesCountASC = BookingLightboxDataHelper.byPlacesCountASC(roomCategories, getSortParams);
    const sortByMinPrice = BookingLightboxDataHelper.byMinRatePrice(accommodationsPricesMap, getSortParams);
    const sortItemsByPlacesCountASC = BookingLightboxDataHelper.getSortItemsByPlacesCountASC(roomCategories);

    const sortedMainAccommodations = sortBy(mainAccommodations, sortByPlacesCountASC, sortByMinPrice);

    return (
        <BookingAccommodationsResults
            title={t("accommodations.captions.main")}
            accommodations={sortedMainAccommodations}
            sortItems={sortItemsByPlacesCountASC}
        />
    );
};
BookingMainResults.displayName = "BookingMainResults";
export default BookingMainResults;
