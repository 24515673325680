import {Modal} from "@skbkontur/react-ui";
import styles from "./HourlyBookingLightbox.scss";
import {
    HourlyBookingLightboxHelper,
    HOURLY_BOOKING_LIGHTBOX_CLASSNAME,
} from "./HourlyBookingLightboxHelper";
import {useLightboxResize} from "../../../hooks/useLightboxResize";

interface IBookingLightboxProps {
    caption: JSX.Element;
    width?: number;
    onClose: () => void;
}

const HourlyBookingLightboxModal = (props: React.PropsWithChildren<IBookingLightboxProps>) => {
    const {caption, children, width: withProps, onClose} = props;

    const width = useLightboxResize(HourlyBookingLightboxHelper.getWidth);

    return (
        <Modal
            className={HOURLY_BOOKING_LIGHTBOX_CLASSNAME}
            width={(withProps && Math.min(withProps, width)) || width}
            onClose={onClose}
            ignoreBackgroundClick
            disableFocusLock
        >
            <Modal.Header sticky={false} className={styles.header}>
                <div className={styles.caption}>{caption}</div>
            </Modal.Header>
            <Modal.Body className={styles.modalBody}>
                <div>{children}</div>
            </Modal.Body>
        </Modal>
    );
};

HourlyBookingLightboxModal.displayName = "HourlyBookingLightboxModal";
export default HourlyBookingLightboxModal;
