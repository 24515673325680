import H from "history";

export class UrlHelper {
    static getSiteOriginAndPathname() {
        const origin = window.location.origin;
        const pathname = window.location.pathname;
        return origin + pathname;
    }

    static getSiteName() {
        const pathname = window.location.pathname;
        const result = pathname.split("/", 2);
        return result && result.length > 1 ? result[1] : "";
    }

    static getSearchParams(location: H.Location) {
        if (!location || !location.search) return null;
        return location.search.replace(/^\?/, "");
    }
}
