import {combineReducers} from "redux";
import {bookingReducer as booking} from "./booking/bookingReducer";
import {hotelInfoReducer as hotelInfo} from "./hotelInfo/hotelInfoReducer";
import {accommodationsReducer as accommodations} from "./accommodations/accommodationsReducer";
import {ratesReducer as rates} from "./rates/ratesReducer";
import {roomCategoriesReducer as roomCategories} from "./roomCategories/roomCategoriesReducer";
import {reducer as hotelsSearch} from "./hotelsSearch/reducer";
import {paymentReducer as payment} from "./payment/paymentReducer";
import {reducer as globalError} from "./globalError/reducer";
import {paymentTerminalReducer as paymentTerminals} from "./paymentSource/paymentTerminalReducer";
import {cashbackReducer as cashbackInfo} from "./cashback/cashbackReducer";
import {promoCodeReducer as promoCode} from "./promoCode/promoCodeReducer";
import {hotelFeaturesReducer as hotelFeatures} from "./hotelFeatures/hotelFeaturesReducer";
import {availabilitiesReducer as availabilities} from "./availabilities/availabilities";
import {availabilitiesHourlyReducer as availabilitiesHourly} from "./availabilitiesHourly/availabilitiesHourly";
import {hourlyObjectsReducer as hourlyObjects} from "./hourlyObjects/hourlyObjectsReducer";
import {hourlyFeaturesReducer as hourlyFeatures} from "./hourlyFeatures/hourlyFeaturesReducer";
import {hourlyRatesReducer as hourlyRates} from "./hourlyRates/hourlyRatesReducer";
import {hourlyObjectsAccommodationsReducer as hourlyObjectsAccommodations} from "./hourlyAccommodations/hourlyAccommodationsReducer";
import {hourlyBookingReducer as hourlyBooking} from "./hourlyBooking/hourlyBookingReducer";

export default combineReducers({
    booking,
    hotelInfo,
    roomCategories,
    rates,
    accommodations,
    hotelsSearch,
    payment,
    paymentTerminals,
    globalError,
    cashbackInfo,
    promoCode,
    hotelFeatures,
    availabilities,
    availabilitiesHourly,
    hourlyObjects,
    hourlyRates,
    hourlyFeatures,
    hourlyObjectsAccommodations,
    hourlyBooking,
});
