import {WidgetContainer} from "./WidgetSettingsTypes";

export class WidgetSettingsNormalizer {

    static normalizeContainer = (container: WidgetContainer): HTMLElement => (
        typeof container === "string" ? document.getElementById(container) : container
    );

    static normalizeEnum = <T>(enumValue: T) => enumValue ? String(enumValue).toLowerCase() : !!enumValue;
}
