import {IRate} from "../rate";
import {
    AdditionalBedType,
    BedType, IBedStructure,
    IRoomCategory,
    IRoomStructure,
    KnownBedTypes,
    RoomCategoryType,
    RoomType
} from "./RoomCategory";
import {withRenderKey} from "../renderKey";
import {getNotDeletedItems} from "../utils";

const BEDS_CAPACITY: Record<KnownBedTypes, number> = {
    [BedType.Single]: 1,
    [BedType.Double]: 2,
    [BedType.DoubleKing]: 2,
    [BedType.DoubleSplit]: 2,
    [BedType.Bunk]: 2,
    [AdditionalBedType.DoubleSofa]: 2,
    [AdditionalBedType.Sofa]: 1,
    [AdditionalBedType.Chair]: 1,
    [AdditionalBedType.Baby]: 1,
    [AdditionalBedType.Cot]: 1
};

export class RoomCategoryHelper {
    static isHostelCategory = (category: IRoomCategory): boolean => (
        !!category && this.isHostel(category.roomCategoryType)
    );

    static isCategoryWithAdditionalPlaces = (category: IRoomCategory): boolean => (
        category?.rooms?.some(room => room?.additionalPlacesCount)
    );

    static isHostel = (type: RoomCategoryType): boolean => (
        type === RoomCategoryType.Hostel
    );

    static getGuestsCount = (category: IRoomCategory): number => (
        this.isHostelCategory(category) ? 1 : (category.placesMin + category.additionalPlaces)
    );

    static getPlacesCountByOccupancy = (occupancyIndex: number, roomCategory: IRoomCategory): number => (
        this.isHostelCategory(roomCategory)
            ? 1
            : occupancyIndex === 0
                ? roomCategory.placesMin
                : occupancyIndex
    );

    static getDefaultRoomCategory = (roomCategory?: Partial<IRoomCategory>): IRoomCategory => ({
        id: null,
        name: "",
        description: "",
        imageSources: [],
        roomCategoryType: null,
        area: null,
        hasKitchen: false,
        bathroomType: null,
        placesMin: 1,
        roomsCount: 1,
        rooms: [withRenderKey({
            placesCount: 1,
            beds: [withRenderKey({
                type: null
            })],
            type: RoomType.LivingOrBedroom,
            additionalPlacesCount: 0,
            additionalBeds: [withRenderKey({
                type: null
            })]
        })],
        ...roomCategory
    });

    static hasRoomCategoryRate = (id: string, rates: IRate[]): boolean => {
        const notDeletedRates = getNotDeletedItems(rates || []);
        return notDeletedRates.some(rate =>
            rate.roomCategoriesOccupancies.some(occupancy => occupancy.roomCategoryId === id)
        );
    };

    static calculatePlacesMin = (rooms: IRoomStructure[]): number => (
        rooms && rooms.reduce((sum, room) => sum + room.placesCount, 0)
    );

    static roomWithRenderKeys = (room: IRoomStructure): IRoomStructure => withRenderKey({
        ...room,
        beds: room.beds?.map(withRenderKey),
        additionalBeds: room?.additionalBeds.map(withRenderKey)
    });

    static calculateRoomBedsCapacity = (beds: IBedStructure<KnownBedTypes>[]): number => (
        beds?.reduce((sum, bed) => sum + (bed.type ? BEDS_CAPACITY[bed.type] : 0), 0)
    );

    static getTranslationKeyByType = (type: RoomCategoryType) => (
        this.isHostel(type) ? "Hostel" : "WholeRoom"
    );

    static calculateAdditionalPlaces = (rooms: IRoomStructure[]): number => (
        rooms?.reduce((sum, room) => sum + room?.additionalPlacesCount, 0)
    );
}
