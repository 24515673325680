import {HourlyBookingLightboxStep} from "../Steps/HourlyBookingLightboxStepsContext";
import {IHourlyBookingAccommodation} from "../../../../data/HourlyBooking";

export interface IBookingLightboxFlipperState extends IHourlyBookingAccommodation {
    step: HourlyBookingLightboxStep;
}

export type ShouldFlipFunc = (prevValue: IBookingLightboxFlipperState, value: IBookingLightboxFlipperState) => boolean;

export class HourlyBookingLightboxFlipperHelper {
    static shouldFlipAccommodationItem = (
        (renderKey: string): ShouldFlipFunc => (
        (_prev: IBookingLightboxFlipperState, accommodation: IBookingLightboxFlipperState) =>
            accommodation.renderKey === renderKey
        )
    );

    static getShouldFlipStepsChange =
        (isAnyHotelFeaturesSelling: boolean): ShouldFlipFunc => (
        ({step: prevStep}: IBookingLightboxFlipperState, {step}: IBookingLightboxFlipperState) => (
            step === HourlyBookingLightboxStep.FinishOrder
                ? prevStep !== step && !isAnyHotelFeaturesSelling
                : prevStep !== step
        )
    );
}
