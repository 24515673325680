import {useTranslation} from "@skbkontur/i18n";
import {ToolPencilIcon16Light} from "@skbkontur/icons/ToolPencilIcon16Light";
import {Gapped} from "@skbkontur/react-ui";
import {TranslationNamespaces} from "../../../../constants/TranslationNamespaces";
import {
    HourlyBookingLightboxStep,
    HourlyBookingLightboxStepsContext,
    isReadyToOrder,
} from "../Steps/HourlyBookingLightboxStepsContext";
import styles from "./HourlyBookingLightboxCaption.scss";
import {DeviceContext} from "../../../Device/DeviceContext";

const HourlyBookingLightboxCaption = () => {
    const {t} = useTranslation(TranslationNamespaces.BookingModule);

    const {step, goToStep} = React.useContext(HourlyBookingLightboxStepsContext);
    const {isMobileMode} = React.useContext(DeviceContext);

    const handleChangeBooking = () => goToStep(HourlyBookingLightboxStep.SelectRates);

    if (isReadyToOrder(step)) {
        return (
            <Gapped vertical={isMobileMode} gap={isMobileMode ? 2 : 16}>
                {t("HourlyBookingModal.cart")}
                <div className={styles.changeBooking} onClick={handleChangeBooking}>
                    <ToolPencilIcon16Light /> {t("HourlyBookingModal.changeBooking")}
                </div>
            </Gapped>
        );
    }

    return (
        <span>
            {t("HourlyBookingModal.caption")}
        </span>
    );
};

HourlyBookingLightboxCaption.displayName = "HourlyBookingLightboxCaption";
export default HourlyBookingLightboxCaption;
