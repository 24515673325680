import {CustomThunkAction} from "../ThunkAction";
import {IPaymentTerminal} from "../../data/Payment";

export enum PaymentTerminalActionTypes {
    GET_SETTINGS_REQUEST = "payment-source/GET_SETTINGS_REQUEST",
    GET_SETTINGS_SUCCESS = "payment-source/GET_SETTINGS_SUCCESS",
    GET_SETTINGS_ERROR = "payment-source/GET_SETTINGS_ERROR",
}

interface IGetPaymentTerminalsRequestAction {
    type: PaymentTerminalActionTypes.GET_SETTINGS_REQUEST;
}

export interface IGetPaymentTerminalsSuccessAction {
    type: PaymentTerminalActionTypes.GET_SETTINGS_SUCCESS;
    response: IPaymentTerminal[];
}

export interface IGetPaymentTerminalsErrorAction {
    type: PaymentTerminalActionTypes.GET_SETTINGS_ERROR;
    error: Error;
}

export type KnownPaymentTerminalAction =
    IGetPaymentTerminalsRequestAction
    | IGetPaymentTerminalsSuccessAction
    | IGetPaymentTerminalsErrorAction;

export const getPaymentTerminals = (): CustomThunkAction<IPaymentTerminal[]> => (
    // @ts-expect-error Use new Redux instead of fixing this types
    (dispatch, getState, {paymentTerminalApi}) => dispatch({
        type: {
            REQUEST: PaymentTerminalActionTypes.GET_SETTINGS_REQUEST,
            SUCCESS: PaymentTerminalActionTypes.GET_SETTINGS_SUCCESS,
            FAILURE: PaymentTerminalActionTypes.GET_SETTINGS_ERROR
        },
        asyncAction: paymentTerminalApi.getTerminals()
    })
);
