import {IRoomCategoryAccommodation} from "../../../../data/Accommodation";
import {IAccommodationsMapParams} from "../../../Accommodations/AccommodationsMaps";
import {isEqual} from "lodash";
import {AccommodationsAvailabilityHelper} from "../../../Accommodations/helpers/AccommodationsAvailabilityHelper";
import {createArrayMapBy} from "../../../../common/helpers/arrayUtils";
import {AccommodationsPricesHelper} from "../../../Accommodations/helpers/AccommodationsPricesHelper";
import {OccupancyHelper} from "../../../../helpers/OccupancyHelper";

// TODO Написать тесты

export class BookingOverbookingHelper {
    static isOverbooking = (
        accommodations: IRoomCategoryAccommodation[],
        newAccommodations: IRoomCategoryAccommodation[],
        selectedParams: IAccommodationsMapParams[]
    ) => {
        if (!accommodations)
            return false;

        /** Если нет выбранных, то ищем по всем */
        if (!selectedParams.length)
            return !isEqual(accommodations, newAccommodations);

        /** Проверяем хватает ли новых доступностей для уже выбранных */
        const newAvailabilityMap = AccommodationsAvailabilityHelper.createAvailabilityMap(newAccommodations);
        const selectedParamsMap = createArrayMapBy(selectedParams, p => p.roomCategoryId);

        const isAvailabilityOverbooking = Object.keys(selectedParamsMap).some(roomCategoryId => {
            const selectedRoomCategoryCount = selectedParamsMap[roomCategoryId].length;
            const newAvailableCount = newAvailabilityMap[roomCategoryId]?.availableCount || 0;
            return selectedRoomCategoryCount > newAvailableCount;
        });

        if (isAvailabilityOverbooking)
            return isAvailabilityOverbooking;

        /** Проверяем изменились ли цены уже выбранных */
        const pricesMap = AccommodationsPricesHelper.createPricesMap(accommodations);
        const newPricesMap = AccommodationsPricesHelper.createPricesMap(newAccommodations);

        return selectedParams.some(({roomCategoryId, mainOccupancy, additionalOccupancy}) => (
            !isEqual(
                OccupancyHelper.findItemValue(pricesMap[roomCategoryId], mainOccupancy, additionalOccupancy),
                OccupancyHelper.findItemValue(newPricesMap[roomCategoryId], mainOccupancy, additionalOccupancy)
            )
        ));
    };
}
