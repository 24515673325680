import {IWidget, IWidgetSettings, IWidgetSettingsContainer, WidgetType} from "./settings/WidgetSettingsTypes";
import {WidgetValidation} from "./validation/WidgetValidation";
import {Widget} from "../data/Widget";

export default class WidgetStore {
    static settings: IWidgetSettings = null;
    static baseUrl: string = null;

    static getUrlWithBase = (url: string) => (
        WidgetStore.baseUrl
            ? WidgetStore.baseUrl + `/${url}`.replace(/\/+/g, "/")
            : url
    );

    static isWidget = () => !!WidgetStore?.settings?.hotelId;

    static addWidgetSettings = (settings: IWidgetSettings) => {
        this.settings = settings;
    }

    static addWidget = (type: WidgetType, appearance: IWidgetSettingsContainer): string => {
        const widget = new Widget(type, appearance);
        this.settings.widgets.push(widget);

        return widget.id;
    }

    static isWidgetTypeExists = (type: WidgetType): boolean => (
        this.settings.widgets.some(widget => WidgetValidation.areEqualEnums<WidgetType>(type, widget.type))
    );

    static isWidgetIdExists = (id: string): boolean => (
        this.settings.widgets.some(widget => widget.id === id)
    );

    static removeElement = (widgetId: string): IWidget[] => (
        this.settings.widgets = this.settings.widgets.filter(widget => widget.id !== widgetId)
    );

    static getWidgetById = (widgetId: string): IWidget => (
        this.settings.widgets.find(widget => widget.id === widgetId)
    );
}
