// eslint-disable-next-line @typescript-eslint/naming-convention
type DebounceActionType<T, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10> =
    (a1?: A1, a2?: A2, a3?: A3, a4?: A4, a5?: A5, a6?: A6, a7?: A7, a8?: A8, a9?: A9, a10?: A10) => Promise<T>;

// TODO Sync with Hotel Async helper

// eslint-disable-next-line @typescript-eslint/naming-convention
export function DebouncePromise<T, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10>(
    action: DebounceActionType<T, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10>,
    step: number
): DebounceActionType<T, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10> {

    let timeout: NodeJS.Timeout;
    let revision = 0;

    return (...args) => {
        const callRevision = revision;
        revision++;
        return new Promise<T>(resolve => {
            clearTimeout(timeout);
            timeout = setTimeout(() => (
                callRevision === (revision - 1) && resolve(action(...args))
            ), step);
        });
    };
}

export const delay = (time = 2000) => (
    new Promise<void>((resolve) => {
        setTimeout(resolve, time);
    })
);
