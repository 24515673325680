import {Reducer} from "redux";
import {CashbackActionTypes, KnownCashbackActions} from "./cashbackActionCreators";

export interface ICashbackInfoState {
    isLoading: boolean;
    error?: Error;
    availableCashbackTerminals: string[];
}

const initialState = {
    isLoading: false,
    error: null,
    availableCashbackTerminals: null
} as ICashbackInfoState;

export const cashbackReducer: Reducer<ICashbackInfoState> = (
    state: ICashbackInfoState = initialState, action: KnownCashbackActions
) => {
    switch (action.type) {
        case CashbackActionTypes.GET_CASHBACK_TERMINAL_IDS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case CashbackActionTypes.GET_CASHBACK_TERMINAL_IDS_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case CashbackActionTypes.GET_CASHBACK_TERMINAL_IDS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                availableCashbackTerminals: action.response
            };
        default:
            return state;
    }
};
