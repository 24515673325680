import {Reducer} from "redux";
import {
    HourlyBookingActionTypes,
    KnownHourlyAccommodationsAction,
} from "./hourlyBookingActionCreators";

export interface IHourlyBookingState {
    isLoading: boolean;
    createdBookings: string[] | null;
    error?: Error;
}

export const defaultState: IHourlyBookingState = {
    isLoading: false,
    createdBookings: null,
    error: null,
};

export const hourlyBookingReducer: Reducer<IHourlyBookingState> = (
    state: IHourlyBookingState = defaultState,
    action: KnownHourlyAccommodationsAction
) => {
    switch (action.type) {
        case HourlyBookingActionTypes.CREATE_HOURLY_BOOKING_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case HourlyBookingActionTypes.CREATE_HOURLY_BOOKING_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }

        case HourlyBookingActionTypes.CREATE_HOURLY_BOOKING_SUCCESS: {
            return {
                ...state,
                createdBookings: action.response,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
