import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router";
import {IAppState} from "../../store/AppState";
import {WarningModal} from "../../components/WarningModal/WarningModal";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {UrlParamsExtractor} from "../../helpers/UrlParamsExtractor";
import {IPayment, IPaymentData, PaymentResult, PaymentSystem} from "../../data/Payment";
import {PaymentContext} from "./PaymentContext";
import {useLocalStoragePaymentResults} from "./useLocalStoragePaymentResults";
import {DeviceContext} from "../Device/DeviceContext";
import {clearPayment, createPayment, getPaymentResult, ICreatePaymentResult} from "../../store/payment/paymentActionCreators";
import {useMemoObject} from "../../common/hooks/useMemoObject";
import PaymentCheckingLightbox from "./PaymentCheckingLightbox";
import {IPaymentsCreateResponse} from "../../api/paymentsApi";
import {bookingLightboxPaymentAnalyticsEvents} from "../../analytics/bookingLightboxPaymentAnalyticsEvents";
import {useMount} from "@skbkontur/hotel-hooks/react";

const noOp = () => {
};

export interface IPaymentState {
    onFinishPayment: (result: PaymentResult) => void;
    paymentId: string;
    paymentUrl: string;
}

const PaymentProvider = ({children}: React.PropsWithChildren<object>) => {
    const {t, lng} = useTranslation(TranslationNamespaces.BookingModule);

    const {result} = useSelector((state: IAppState) => state.payment);
    const {isDesktopMode} = React.useContext(DeviceContext);

    const dispatch = useDispatch();
    const location = useLocation();

    const [state, setState] = React.useState<IPaymentState>({
        paymentId: UrlParamsExtractor.getPaymentId(location),
        onFinishPayment: noOp,
        paymentUrl: null
    });
    const {paymentId, onFinishPayment, paymentUrl} = state;

    const [showCancelledModal, setShowCancelledModal] = React.useState(false);
    const [showFailModal, setShowFailModal] = React.useState(false);
    const [showPaymentCheckingModal, setShowPaymentCheckingModal] = React.useState(false);
    const [showSuccessModal, setShowSuccessModal] = React.useState(false);

    const {paymentResults, setResult} = useLocalStoragePaymentResults(paymentId);

    const openCancelledModal = React.useCallback(() => setShowCancelledModal(true), []);
    const openFailModal = React.useCallback(() => setShowFailModal(true), []);
    const openPaymentCheckingModal = React.useCallback(() => setShowPaymentCheckingModal(true), []);
    const openSuccessModal = React.useCallback(() => setShowSuccessModal(true), []);

    const closeCancelledModal = React.useCallback(() => setShowCancelledModal(false), []);
    const closeFailModal = React.useCallback(() => setShowFailModal(false), []);
    const closeLoadingModal = React.useCallback(() => setShowPaymentCheckingModal(false), []);
    const closeSuccessModal = React.useCallback(() => setShowSuccessModal(false), []);

    const checkPaymentResult = async () => {
        openPaymentCheckingModal();
        /* eslint-disable @typescript-eslint/await-thenable */
        await dispatch(getPaymentResult(paymentId, 10));
        closeLoadingModal();
    };

    const isAutoSendConfirmation = useSelector((state: IAppState) => state.hotelInfo.info.autoSendConfirmationEmail);
    const successPaymentCaption = isAutoSendConfirmation
        ? t("successPaymentWithConfirmation")
        : t("successPaymentNoConfirmation");

    useMount(() => {
        if (paymentId && !paymentResults[PaymentResult.succeeded]) {
            checkPaymentResult();
        }
    });

    React.useEffect(() => {
        const areResultAlreadyTaken = !!paymentResults[result];
        if (areResultAlreadyTaken)
            return;

        onFinishPayment(result);
        setResult(result);

        switch (result) {
            case PaymentResult.succeeded:
                bookingLightboxPaymentAnalyticsEvents.trackSuccessfulPayment();
                openSuccessModal();
                break;
            case PaymentResult.canceled:
                bookingLightboxPaymentAnalyticsEvents.trackCanceledPayment();
                openCancelledModal();
                break;
            case PaymentResult.failed:
                bookingLightboxPaymentAnalyticsEvents.trackPaymentFailedButBooked();
                openFailModal();
                break;
        }

        return () => {
            dispatch(clearPayment());
        };
    }, [result]);

    const processPayment = React.useCallback((paymentId: string, paymentUrl: string, paymentSystem: PaymentSystem) => {
        if (paymentSystem === PaymentSystem.TBankSbp && isDesktopMode) {
            dispatch(getPaymentResult(paymentId));
            return;
        }

        if (paymentUrl) {
            // при оплате через Юкассу, если ввели невалидный телефон приходит paymentUrl === null.
            // todo вывести ЛБ что проверьте данные https://yt.skbkontur.ru/issue/FMS-9869/Otel.-MOB.-Dobavit-validaciyu-na-telefon-email-v-forme-bronirovaniya
            document.location.href = paymentUrl;
        }
    }, [isDesktopMode]);

    const handleCreatePayment = React.useCallback(async (
        paymentData: IPaymentData,
        onFinishPayment: (result: PaymentResult) => void
    ): Promise<IPayment> => {
        const {paymentSystem} = paymentData;
        /* eslint-disable @typescript-eslint/await-thenable */
        // @ts-expect-error Need to use new Redux
        const {response}: ICreatePaymentResult = await dispatch(createPayment(paymentData, lng));
        const {payment, confirmationUrl: paymentUrl} = response as IPaymentsCreateResponse;
        const {id: paymentId} = payment;

        processPayment(paymentId, paymentUrl, paymentSystem);
        setState({paymentId, paymentUrl, onFinishPayment});

        return payment;
    }, [lng, processPayment]);

    const isPaymentProcess = paymentId && !result;

    return (
        <PaymentContext.Provider
            value={useMemoObject({createPayment: handleCreatePayment, isPaymentProcess, paymentUrl})}>
            {children}
            {showPaymentCheckingModal && <PaymentCheckingLightbox onClose={closeLoadingModal}/>}
            {showSuccessModal && (
                <WarningModal
                    title={t("thanksForBooking")}
                    message={successPaymentCaption}
                    onClose={closeSuccessModal}
                    selector="PaymentSuccessModal"
                />
            )}
            {showFailModal && (
                <WarningModal
                    title={t("thanksForBooking")}
                    message={t("failPayment")}
                    onClose={closeFailModal}
                    selector="PaymentFailModal"
                />
            )}
            {showCancelledModal && (
                <WarningModal
                    title={t("failBooking")}
                    message={t("failPaymentAndCancelBooking")}
                    onClose={closeCancelledModal}
                    selector="PaymentCancelledModal"
                />
            )}
        </PaymentContext.Provider>
    );
};
PaymentProvider.displayName = "PaymentProvider";
export default PaymentProvider;
