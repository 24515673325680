import styles from "./RoundButton.scss";
import cn from "classnames";
import color from "color";

interface IRoundButtonProps {
    icon: React.ReactElement;
    onClick?: () => void;
    backgroundColor?: string;
    backgroundAlways?: boolean;
    fontColor?: string;
    disabled?: boolean;
    dataTID?: string;
}

// TODO компонент из Отеля
const RoundButton = (props: IRoundButtonProps) => {
    const {
        icon,
        onClick,
        backgroundColor = "#fff",
        backgroundAlways = false,
        disabled = false,
        dataTID,
        fontColor
    } = props;

    const className = cn(styles.content, {
        [styles.disabled]: disabled
    });

    const rootRef = React.useRef<HTMLDivElement>();
    const setRootRef = React.useCallback((el: HTMLDivElement) => rootRef.current = el, []);

    const bgColor = color(backgroundColor).darken(0.08).hex();
    const finalFontColor = fontColor || color(backgroundColor).darken(0.5).hex();
    const disabledFontColor = color(backgroundColor).darken(0.372).hex();
    const hoverColor = "#3072C4";

    React.useEffect(() => {
        rootRef.current.style.backgroundColor = backgroundAlways ? bgColor : "transparent";
    }, [bgColor, backgroundAlways]);

    const onMouseEnter = React.useCallback(() => {
        rootRef.current.style.backgroundColor = bgColor;
        if (!disabled) rootRef.current.style.color = hoverColor;
    }, [bgColor, disabled]);

    const onMouseLeave = React.useCallback(() => {
        rootRef.current.style.backgroundColor = backgroundAlways ? bgColor : "transparent";
        rootRef.current.style.color = disabled ? disabledFontColor : finalFontColor;
    }, [backgroundAlways, bgColor, disabled, disabledFontColor, finalFontColor]);

    React.useEffect(() => {
        rootRef.current.style.color = disabled ? disabledFontColor : finalFontColor;
    }, [disabled]);

    const handleClick = React.useCallback(() => {
        if (!disabled && onClick) onClick();
    }, [onClick, disabled]);

    return (
        <div className={styles.root}>
            <div
                data-tid={dataTID}
                data-tid-props={JSON.stringify({disabled})}
                className={className}
                ref={setRootRef}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onClick={handleClick}
            >
                {icon}
            </div>
        </div>
    );
};

RoundButton.displayName = "RoundButton";
export default RoundButton;
