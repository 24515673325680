export enum AnalyticsCategory {
    MainSearch = "MainSearch",
    BookingLightboxSearchPanel = "BookingLightboxSearchPanel",
    BookingLightboxOccupancy = "BookingLightboxOccupancy",
    BookingLightboxRates = "BookingLightboxRates",
    BookingLightboxRecommended = "BookingLightboxRecommended",
    BookingLightboxOrder = "BookingLightboxOrder",
    BookingLightboxPayment = "BookingLightboxPayment",
    BookingLightboxPromoCode = "BookingLightboxPromoCode",
    VkMiniApps = "vkMiniApps",
    IframeEvent = "iframeEvent"
}

// TODO@jilyaev: in case of adding mode actions, think of how we can separate this to multiple files
export enum MainSearchAnalyticsAction {
    ChooseDatePeriodInSearchForm = "main_searchForm_datePeriod",
    ChangeGuestsInSearchForm = "main_searchForm_guests",
    RunSearchInSearchForm = "main_searchForm_runSearch",
    RunSearchInRoomCategories = "main_roomCategories_runSearch",
    RunSearchWithFixedButton = "main_fixedButton_runSearch"
}

export enum BookingLightboxSearchPanelAnalyticsAction {
    ChangeDatePeriod = "bookingLightbox_searchPanel_datePeriod",
    ChangeGuests = "bookingLightbox_searchPanel_guests",
    RestartSearch = "bookingLightbox_searchPanel_runSearch"
}

export enum BookingLightboxOccupancyAnalyticsAction {
    ChangeOccupancyCount = "bookingLightbox_occupancy_count",
    ChangeAdultsCount = "bookingLightbox_occupancy_adultsCount",
    ChangeChildrenCount = "bookingLightbox_occupancy_childrenCount",
    GoToRates = "bookingLightbox_goToRates"
}

export enum BookingLightboxRecommendedAnalyticsAction {
    ShowRecommendedList = "bookingLightbox_recommended_showList",
    ApplyRecommendedFilter = "bookingLightbox_recommended_applyFilter"
}

export enum BookingLightboxRatesAnalyticsAction {
    GoToOccupancies = "bookingLightbox_goToOccupancies",
    SelectRate = "bookingLightbox_rates_selectRate",
    ChangeRate = "bookingLightbox_rates_changeRate",
    GoToPayment = "bookingLightbox_goToPayment"
}

export enum BookingLightboxOrderAnalyticsAction {
    FillFormField = "bookingLightbox_order_formField",
    ChoosePaymentSystem = "bookingLightbox_order_paymentSystem",
    ChooseCashback = "bookingLightbox_order_cashback",
    ClickOnOurRequisitesLink = "bookingLightbox_order_ourRequisites",
    ShowPaymentSystemInfo = "bookingLightbox_order_paymentSystemInfo"
}

export enum BookingLightboxPaymentAnalyticsAction {
    BookingWithoutPrepayment = "bookingLightbox_payment_withoutPrepayment",
    PaymentStart = "bookingLightbox_payment_start",
    PaymentSuccessful = "bookingLightbox_payment_succeeded",
    PaymentCanceled = "bookingLightbox_payment_canceled",
    PaymentFailedButBooked = "bookingLightbox_payment_failed"
}

export enum BookingLightboxPromoCodeAnalyticsAction {
    ShowPromoCode = "bookingLightbox_promoCode_show",
    CheckPromoCode = "bookingLightbox_promoCode_check",
    AcceptedPromoCode = "bookingLightbox_promoCode_accepted",
    IncorrectPromoCode = "bookingLightbox_promoCode_incorrect",
    NotActingPromoCode = "bookingLightbox_promoCode_notActing"
}

export enum VkMiniAppsAnalyticsAction {
    InitializationMOB = "vkMiniApps_initialization_mob",
    InitializationWidget = "vkMiniApps_initialization_widget",
}

export enum IframeEventAnalyticsAction {
    InitializationMOB = "iframe_initialization_mob",
    InitializationWidget = "iframe_initialization_widget",
}

export type AnalyticsAction = MainSearchAnalyticsAction
    | BookingLightboxSearchPanelAnalyticsAction
    | BookingLightboxOccupancyAnalyticsAction
    | BookingLightboxRatesAnalyticsAction
    | BookingLightboxRecommendedAnalyticsAction
    | BookingLightboxOrderAnalyticsAction
    | BookingLightboxPaymentAnalyticsAction
    | BookingLightboxPromoCodeAnalyticsAction
    | VkMiniAppsAnalyticsAction
    | IframeEventAnalyticsAction;

export interface IAnalyticsAction {
    category: AnalyticsCategory;
    action: AnalyticsAction;
    label?: string;
    customVariables?: CustomAnalyticsActionVariables;
}

export type CustomAnalyticsActionVariables = object | string | number | boolean;
