// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nXfwMe{margin-top:30px}.dqX6nC{font-size:22px;line-height:24px}.B5mYvw{font-size:26px;font-weight:400}", "",{"version":3,"sources":["webpack://./src/components/BookingPaymentSum/BookingPaymentSum.scss"],"names":[],"mappings":"AAAA,QACI,eAAA,CAEA,QACI,cAAA,CACA,gBAAA,CAGJ,QAEI,cAAA,CADA,eACA","sourcesContent":[".prepayment {\r\n    margin-top: 30px;\r\n\r\n    &-label {\r\n        font-size: 22px;\r\n        line-height: 24px;\r\n    }\r\n\r\n    &-price {\r\n        font-weight: 400;\r\n        font-size: 26px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"prepayment": "nXfwMe",
	"prepaymentLabel": "dqX6nC",
	"prepaymentPrice": "B5mYvw"
};
export default ___CSS_LOADER_EXPORT___;
