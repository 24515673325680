import {DateFormats} from "../types/DateFormats";
import {dayjs, UnitOfTime} from "../data/Date";
import {ConfigType} from "dayjs";

export default class DateHelper {
    static isBeforeDate = (
        firstDate: ConfigType,
        secondDate: ConfigType,
        formatFist: DateFormats = DateFormats.FullDateDayFirst,
        formatSecond: DateFormats = formatFist
    ): boolean => dayjs(firstDate, formatFist).isBefore(dayjs(secondDate, formatSecond));

    static isBeforeDateByDay = (
        firstDate: ConfigType,
        secondDate: ConfigType,
        format: DateFormats = DateFormats.FullDateDayFirst
    ): boolean => (
        dayjs(firstDate, format).isBefore(dayjs(secondDate, format), UnitOfTime.Day)
    );

    static isSameDate = (
        firstDate: ConfigType,
        secondDate: ConfigType,
        format: DateFormats = DateFormats.FullDateDayFirst
    ): boolean => dayjs(firstDate, format).isSame(dayjs(secondDate, format), UnitOfTime.Day);

    static isSameMonth = (firstDate: string, secondDate: string, format: DateFormats): boolean => (
        dayjs(firstDate, format).isSame(dayjs(secondDate, format), UnitOfTime.Month)
    );

    static getDiffByDays = (firstDate: ConfigType, secondDate: ConfigType) => (
        dayjs(secondDate, DateFormats.FullDateDayFirst).diff(
            dayjs(firstDate, DateFormats.FullDateDayFirst),
            UnitOfTime.Day
        )
    );

    static getDiffByHours = (
        firstDate: ConfigType,
        secondDate: ConfigType,
        format = DateFormats.FullDateYearFirstWithTimeWithSecondsWithTimeZone
    ) => dayjs(secondDate, format).diff(dayjs(firstDate, format), UnitOfTime.Hour);

    static getToday = (format: DateFormats = DateFormats.FullDateDayFirst) => dayjs().format(format);

    static getTodayWithTimezone = (format: DateFormats, offsetInMinutes: number = DateHelper.getOffsetInMinutes()) => (
        dayjs().utcOffset(offsetInMinutes).format(format)
    );

    static getTomorrow = (diff = 1) => DateHelper.addDaysToDate(diff);

    static getEndOfDay = (
        date: string,
        formatIn: DateFormats = DateFormats.FullDateYearFirstWithTimeWithSecondsWithTimeZone,
        formatOut: DateFormats = formatIn
    ): string => (
        dayjs(date, formatIn).endOf(UnitOfTime.Day).format(formatOut)
    );

    static addDaysToDate = (
        days = 1,
        date: string = null,
        formatIn: DateFormats = DateFormats.FullDateDayFirst,
        formatOut: DateFormats = formatIn
    ) => {
        const fromDateDayJs = date ? dayjs(date, formatIn) : dayjs();
        return fromDateDayJs.add(days, UnitOfTime.Day).format(formatOut);
    };

    static addHoursToDate = (
        hours = 1,
        date: ConfigType = null,
        formatIn: DateFormats = DateFormats.FullDateDayFirst,
        formatOut: DateFormats = formatIn
    ) => {
        const fromDateDayJs = date ? dayjs(date, formatIn) : dayjs();
        return fromDateDayJs.add(hours, UnitOfTime.Hour).format(formatOut);
    };

    static addSecondsToDate = (
        seconds: number = 1,
        date: ConfigType = null,
        formatIn: DateFormats = DateFormats.FullDateDayFirst,
        formatOut: DateFormats = formatIn
    ) => {
        const fromDateDayJs = date ? dayjs(date, formatIn) : dayjs();
        return fromDateDayJs.add(seconds, UnitOfTime.Second).format(formatOut);
    };

    static addMonthsToDate = (monthsCount: number, date: ConfigType, format: DateFormats) => (
        dayjs(date, format).add(monthsCount, UnitOfTime.Month).format(format)
    );

    static addYearsToDate = (yearsCount: number, date: ConfigType, format: DateFormats) => (
        dayjs(date, format).add(yearsCount, UnitOfTime.Year).format(format)
    );

    static getFirstMonthDate = (date: ConfigType, format: DateFormats): string => (
        dayjs(date, format).startOf(UnitOfTime.Month).format(format)
    );

    static getLastMonthDate = (date: ConfigType, format: DateFormats): string => (
        dayjs(date, format).endOf(UnitOfTime.Month).format(format)
    );

    static getDaysCountInMonth = (date: ConfigType, format: DateFormats): number => dayjs(date, format).daysInMonth();

    static getWeekDay = (date: ConfigType, format: DateFormats): number => dayjs(date, format).day();

    static getDayNumber = (date: ConfigType, format: DateFormats): number => dayjs(date, format).date();

    static getOffsetInMinutes = () => dayjs().utcOffset();

    static convert = (date: ConfigType, formatIn: DateFormats, formatOut: DateFormats): string => (
        dayjs(date, formatIn).format(formatOut)
    );

    static convertWithTimezone = (
        date: ConfigType,
        options: {offsetInMinutes?: number; formatIn: DateFormats; formatOut: DateFormats}
    ): string => {
        const {offsetInMinutes, formatIn, formatOut} = options;
        const defaultOffsetInMinutes = offsetInMinutes || DateHelper.getOffsetInMinutes();

        return dayjs(date, formatIn).utcOffset(defaultOffsetInMinutes).format(formatOut);
    };

    static getMonth = (date: ConfigType, format: DateFormats): number => dayjs(date, format).month();

    static getYear = (date: ConfigType, format: DateFormats): number => dayjs(date, format).year();

    static getDate = (year: number, monthFromZero: number, day: number, format: DateFormats): string => (
        dayjs(new Date(year, monthFromZero, day)).format(format)
    );
}
