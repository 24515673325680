import {Select} from "@skbkontur/react-ui";
import {IBookingAccommodationsSelectProps} from "./BookingAccommodationsSelect";
import {orderBy} from "lodash";
import {People1Icon16Light} from "@skbkontur/icons/People1Icon16Light";

const renderValue = (v: number) => <>{v} <People1Icon16Light /></>;

const BookingAccommodationsAdultsCountSelect = (props: IBookingAccommodationsSelectProps) => {
    const {onChange, value, disabled, availableVariants} = props;

    const sortedVariants = orderBy(availableVariants, x => x, ["asc"]);

    return (
        <Select<number>
            onValueChange={onChange}
            value={value}
            disabled={disabled}
            items={sortedVariants}
            renderItem={renderValue}
            renderValue={renderValue}
            width={90}
            size={"medium"}
        />
    );
};
BookingAccommodationsAdultsCountSelect.displayName = "BookingAccommodationsAdultsCountSelect";
export default BookingAccommodationsAdultsCountSelect;
