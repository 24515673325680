import {useTranslation} from "@skbkontur/i18n";
import {Modal} from "@skbkontur/react-ui";
import {ZIndex} from "@skbkontur/react-ui/internal/ZIndex";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import styles from "./RequisitesLightbox.scss";
import {IForeignRequisites} from "../../data/Requisites";
import PrimaryButton from "../PrimaryButton/PrimaryButton";

interface IForeignRequisitesLightboxProps {
    requisites: IForeignRequisites;
    onClose: () => void;
}

export const ForeignRequisitesLightbox = (props: IForeignRequisitesLightboxProps) => {
    const {requisites, onClose} = props;
    const {foreignOrganizationId, swift, lawAddress, bankName, bankAccountNumber, legalName} = requisites;
    const hasAnyBankRequisite = bankName || swift || bankAccountNumber;

    const {t, tcn} = useTranslation(TranslationNamespaces.BookingModule);

    return (
        <ZIndex delta={999999}>
            <Modal
                disableFocusLock
                width={560}
                onClose={onClose}
                ignoreBackgroundClick
            >
                <Modal.Header>
                    {t("requisites.name")}
                </Modal.Header>
                <Modal.Body>
                    <div className={styles.headerHotelInfo}>{legalName}</div>
                    <div>
                        {foreignOrganizationId &&
                            <div>{t("requisites.foreignOrganizationId")}: {foreignOrganizationId}</div>}
                        {lawAddress && <div>{t("requisites.lawAddress")}: {lawAddress}</div>}
                        {hasAnyBankRequisite && (
                            <div>
                                <div className={styles.headerBankAccount}>{t("bankAccount.name")}</div>
                                {bankName && <div>{t("bankAccount.bankName")}: {bankName}</div>}
                                {swift && <div>{t("bankAccount.swift")} {swift}</div>}
                                {bankAccountNumber && (
                                    <div>{t("bankAccount.bankAccountNumber")}: {bankAccountNumber}</div>
                                )}
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <PrimaryButton use="default" onClick={onClose}>
                        {tcn("buttons.close")}
                    </PrimaryButton>
                </Modal.Footer>
            </Modal>
        </ZIndex>
    );
};
