import {BedType, IBedStructure, IRoomCategory, RoomCategoryHelper} from "@skbkontur/hotel-data/roomCategory";
import {useTranslation} from "@skbkontur/i18n";
import styles from "../RoomCategoryCharacteristics.scss";
import {People1Icon16Light} from "@skbkontur/icons/People1Icon16Light";
import {People3Icon16Light} from "@skbkontur/icons/People3Icon16Light";
import {People1PlusIcon16Light} from "@skbkontur/icons/People1PlusIcon16Light";
import {SvgIcon, SvgIconType} from "@skbkontur/hotel-components/svgIcon";
import {withRenderKey} from "@skbkontur/hotel-data/renderKey";
import {TranslationNamespaces} from "@skbkontur/hotel-constants/TranslationNamespaces";
import {AdditionalBedType} from "@skbkontur/hotel-data/roomCategory/RoomCategory";
import cn from "classnames";

interface IRoomCategoryCharacteristicsIconsProps {
    category: IRoomCategory;
    hideArea: boolean;
    beds?: IBedStructure<BedType>[];
    additionalBeds?: IBedStructure<AdditionalBedType>[];
    small?: boolean;
    modifyImageUrl?: (url: string) => string;
    textMode?: boolean;
}

export const BED_ICONS: Record<BedType, SvgIconType> = {
    [BedType.Single]: SvgIconType.SingleBed,
    [BedType.Double]: SvgIconType.DoubleBed,
    [BedType.DoubleKing]: SvgIconType.DoubleBedKingSize,
    [BedType.DoubleSplit]: SvgIconType.DoubleSplitBed,
    [BedType.Bunk]: SvgIconType.BunkBed,
};

export const ADDITIONAL_BED_ICONS: Record<AdditionalBedType, SvgIconType> = {
    [AdditionalBedType.DoubleSofa]: SvgIconType.SofaBed,
    [AdditionalBedType.Sofa]: SvgIconType.SofaBed,
    [AdditionalBedType.Chair]: SvgIconType.SofaBed,
    [AdditionalBedType.Baby]: SvgIconType.BabyBed,
    [AdditionalBedType.Cot]: SvgIconType.Cot,
};

const RoomCategoryCharacteristicsIcons = (props: IRoomCategoryCharacteristicsIconsProps) => {
    const {
        category: {area , placesMin, placesMax, roomCategoryType, rooms, additionalPlaces},
        hideArea,
        beds = [],
        additionalBeds = [],
        small,
        modifyImageUrl,
        textMode
    } = props;
    const {t} = useTranslation(TranslationNamespaces.RoomCategories);

    const isHostel = RoomCategoryHelper.isHostel(roomCategoryType);
    const hostelPlaces = placesMax === placesMin ? placesMin : `${placesMin}−${placesMax}`;
    const isHasNotHiddenArea = !!area && !hideArea;
    const isHasPlaces = !!placesMin;

    const isHasAdditionalPlaces = !!additionalPlaces;

    const bedsWithKeys = beds?.map(withRenderKey) || [];
    const additionalBedsWithKeys = additionalBeds?.map(withRenderKey) || [];

    const isAnyTypeBeds = !!(bedsWithKeys.length || additionalBedsWithKeys.length);
    const isAllTypeBeds = !!(bedsWithKeys.length && additionalBedsWithKeys.length);

    if (!(isHasNotHiddenArea || isHasPlaces || rooms?.length)) {
        return null;
    }

    const iconStyles = cn(
        styles.icons,
        {[styles.small]: small},
        {[styles.textMode]: textMode}
    )

    return (
        <div className={iconStyles}>
            {isHasNotHiddenArea && (
                <div>
                    <span data-tid="Area">{area}</span>&nbsp;
                    <span>{t("squareMetersCaption")}</span>
                </div>
            )}
            {!isHostel && isHasPlaces && (
                <div className={styles.iconBlock}>
                    <span data-tid="PlacesCount">{placesMin}</span>
                    <People1Icon16Light />
                    {isHasAdditionalPlaces && (
                        <>
                            +<span data-tid="AdditionalPlacesCount">{additionalPlaces}</span>
                            <People1PlusIcon16Light />
                        </>
                    )}
                </div>
            )}
            {isHostel && !!hostelPlaces && (
                <div className={styles.iconBlock}>
                    <span data-tid="HostelPlacesCount" className={styles.hostelPlaces}>{hostelPlaces}</span>
                    <People3Icon16Light />
                </div>
            )}
            {isAnyTypeBeds && (
                <div className={styles.iconBlock}>
                    {bedsWithKeys.map(bed => (
                        <SvgIcon key={bed.renderKey} type={BED_ICONS[bed.type]} modifyImageUrl={modifyImageUrl} />
                    ))}
                    {isAllTypeBeds && "+"}
                    {additionalBedsWithKeys.map(bed => (
                        <SvgIcon key={bed.renderKey} type={ADDITIONAL_BED_ICONS[bed.type]} modifyImageUrl={modifyImageUrl} />
                    ))}
                </div>
            )}
            {isHostel && !hideArea && <div>{t("hostelRoom")}</div>}
        </div>
    );
};
RoomCategoryCharacteristicsIcons.displayName = "RoomCategoryCharacteristicsIcons";
export default RoomCategoryCharacteristicsIcons;