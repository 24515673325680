import {WidgetValidationResult} from "../validation/WidgetValidationResult";
import {IWidgetSettingsContainer, WidgetType} from "./WidgetSettingsTypes";
import {WidgetValidation} from "../validation/WidgetValidation";

export class WidgetSettingsValidator {
    private validationResults: WidgetValidationResult[] = [];

    isValidType(initialType: WidgetType) {
        const result = WidgetValidation.validateWidgetType(initialType);
        this.validationResults.push(result);
        return this.isValidationOk([result]);
    }

    isValidContainer(
        initialContainer: IWidgetSettingsContainer,
        type: WidgetType
    ) {
        const settingsContainerResult = WidgetValidation.validateSettingsContainer(initialContainer, type);

        const insertResult = initialContainer.insert ? WidgetValidation.validateInsertType(
            initialContainer.insert,
            type
        ) : WidgetValidationResult.Ok;

        const containerDomResult = WidgetValidation.validateContainerDomElement(initialContainer, type);

        const results = [settingsContainerResult, insertResult, containerDomResult];
        this.validationResults = this.validationResults.concat(results);
        return this.isValidationOk(results);
    }

    resetResults = () => this.validationResults = [] as WidgetValidationResult[];

    isValidationOk = (results: WidgetValidationResult[] = this.validationResults) => (
        results.every(result => result === WidgetValidationResult.Ok)
    );
}
