import styles from "./RoomAvailabilityCalendarSelect.scss";
import {Select} from "@skbkontur/react-ui";
import cn from "classnames";
import {CalendarContext} from "../../providers/Calendar/CalendarContext";
import {IRoomCategory} from "@skbkontur/hotel-data/roomCategory";
import {createMapBy} from "../../common/helpers/arrayUtils";
import {RoomCategoryCharacteristics} from "@skbkontur/hotel-components/roomCategoryCharacteristics";
import WidgetStore from "../../widget/WidgetStore";

interface IRoomAvailabilityCalendarSelectProps {
    categories: IRoomCategory[];
    roomCategoryId: string;
    onValueChange: (newRoomCategoryId: string) => void;
}

const RoomAvailabilityCalendarSelect = (props: IRoomAvailabilityCalendarSelectProps) => {
    const {categories, roomCategoryId, onValueChange} = props;

    const {isAlwaysOneMonth} = React.useContext(CalendarContext);
    const categoriesMap = createMapBy(categories, rc => rc.id);

    const items = categories.map(rc => rc.id);

    const renderValue = (roomCategoryId: string) => (
        categoriesMap[roomCategoryId].name
    );

    const renderSelectItem = (roomCategoryId: string) => (
        <div className={styles.selectItem}>
            <div className={styles.selectItemTitle}>{renderValue(roomCategoryId)}</div>
            <RoomCategoryCharacteristics
                category={categoriesMap[roomCategoryId]}
                modifyImageUrl={WidgetStore.getUrlWithBase}
                small
                withoutTooltip
            />
            <div className={styles.selectItemDescription}>{categoriesMap[roomCategoryId]?.description}</div>
        </div>
    );

    return (
    <Select<string>
        className={cn(styles.select, {[styles.fullWidth]: isAlwaysOneMonth})}
        onValueChange={onValueChange}
        value={roomCategoryId}
        items={items}
        renderItem={renderSelectItem}
        renderValue={renderValue}
        size={"medium"}
    />
    );
};
RoomAvailabilityCalendarSelect.displayName = "RoomAvailabilityCalendarSelect";
export default RoomAvailabilityCalendarSelect;
