import {ValidationInfo} from "@skbkontur/react-ui-validations";

export enum ValidationInfoType {
    Submit = "submit",
    Immediate = "immediate",
    Lostfocus = "lostfocus"
}

export const createValidationInfo = (
    message: React.ReactNode,
    type: ValidationInfoType = ValidationInfoType.Submit
): ValidationInfo => ({
    type, message
});
