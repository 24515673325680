// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".p9mVSV{display:inline-block}", "",{"version":3,"sources":["webpack://./src/components/Currency/CurrencySymbol.scss"],"names":[],"mappings":"AAAA,QACI,oBAAA","sourcesContent":[".root {\r\n    display: inline-block;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "p9mVSV"
};
export default ___CSS_LOADER_EXPORT___;
