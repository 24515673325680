export interface IRate {
    id: string;
    name: string;
    description: string;
    isNoneRefundable?: boolean;
    prepayment?: number;
    prepaymentType?: PrepaymentType;
    freeCancellationHoursCount?: number;
    cancellationPenalty?: number;
    cancellationPenaltyType?: CancellationPenaltyType;
    rateFeatures: string[];
}

export enum CancellationPenaltyType {
    Currency = "currency",
    PercentOfFirstDayCost = "percentOfFirstDayCost",
    PercentOfReservationCost = "percentOfReservationCost",
    PercentOfFirstHourCost = "PercentOfFirstHourCost"
}

export enum PrepaymentType {
    Currency = "currency",
    PercentOfFirstDayCost = "percentOfFirstDayCost",
    PercentOfReservationCost = "percentOfReservationCost",
    PercentOfFirstHourCost = "PercentOfFirstHourCost"
}
