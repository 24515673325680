import {IBookingAccommodationsSelectProps} from "./BookingAccommodationsSelect";
import {Hint, Select} from "@skbkontur/react-ui";
import styles from "./BookingAccommodationsSelect.scss";
import {tooltip, ValidationInfo, ValidationWrapper} from "@skbkontur/react-ui-validations";
import {TranslationNamespaces} from "../../../../../constants/TranslationNamespaces";
import {useTranslation} from "@skbkontur/i18n";
import cn from "classnames";
import {ChildBabyIcon16Light} from "@skbkontur/icons/ChildBabyIcon16Light";

interface IBookingAccommodationsChildrenCountSelectProps extends IBookingAccommodationsSelectProps {
    maxCount: number;
}

const renderValue = (v: number) => <>{v} <ChildBabyIcon16Light /></>;

const BookingAccommodationsChildrenCountSelect = (props: IBookingAccommodationsChildrenCountSelectProps) => {
    const {onChange, value, disabled, maxCount, availableVariants} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);

    const items = Array.from({length: maxCount + 1}).map((_, i) => {
        if (availableVariants.includes(i)) {
            return i;
        } else {
            return Select.static(() => (
                <Hint
                    text={<div className={styles.hint}>{t("validations.notAvailableVariant")}</div>}
                    pos="left middle"
                >
                    <div className={cn(styles.disabledItem, {[styles.selected]: i === value})}>
                        <Select.Item>
                            <div className={styles.label}>
                                {renderValue(i)}
                            </div>
                        </Select.Item>
                    </div>
                </Hint>
            ));
        }
    }) as number[];

    const validationInfo: ValidationInfo = !availableVariants.includes(value)
        ? {
            type: "immediate",
            level: "error",
            message: <div className={cn(styles.validation, "childrenCountValidation")}>{t("validations.notAvailable")}</div>
        }
        : null;

    return (
        <div className={"validationClass"}>
            <ValidationWrapper validationInfo={validationInfo} renderMessage={tooltip("top left")}>
                <Select<number>
                    onValueChange={onChange}
                    value={value}
                    disabled={disabled}
                    items={items}
                    renderValue={renderValue}
                    renderItem={renderValue}
                    width={90}
                    size={"medium"}
                />
            </ValidationWrapper>
        </div>

    );
};
BookingAccommodationsChildrenCountSelect.displayName = "BookingAccommodationsChildrenCountSelect";
export default BookingAccommodationsChildrenCountSelect;
