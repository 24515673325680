// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sw7TEd{margin:16px 0}.pxqvKC{color:gray;display:inline-block;line-height:20px}.pxqvKC .z57atp{color:#ce0014;line-height:20px}.dZPK1w{margin-top:7px}", "",{"version":3,"sources":["webpack://./src/providers/BookingOrder/Row/OrderFormRow.scss"],"names":[],"mappings":"AAAA,QACI,aAAA,CAGJ,QAEI,UAAA,CADA,oBAAA,CAEA,gBAAA,CAEA,gBACI,aAAA,CACA,gBAAA,CAIR,QACI,cAAA","sourcesContent":[".container {\r\n    margin: 16px 0;\r\n}\r\n\r\n.caption {\r\n    display: inline-block;\r\n    color: #808080;\r\n    line-height: 20px;\r\n\r\n    .required {\r\n        color: #ce0014;\r\n        line-height: 20px;\r\n    }\r\n}\r\n\r\n.wrapper {\r\n    margin-top: 7px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Sw7TEd",
	"caption": "pxqvKC",
	"required": "z57atp",
	"wrapper": "dZPK1w"
};
export default ___CSS_LOADER_EXPORT___;
