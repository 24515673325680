import {CustomThunkAction} from "../ThunkAction";
import {
    IBookingPromoCodeApplyBackendResultInfo,
    IBookingPromoCodeApplyRequestInfo,
    IBookingPromoCodeApplyResultInfo,
    PromoCodeBackendResultType, PromoCodeResult
} from "../../data/PromoCode";

export enum PromoCodeActionTypes {
    GET_IS_ANY_AVAILABLE_REQUEST = "promoCode/GET_IS_ANY_AVAILABLE_REQUEST",
    GET_IS_ANY_AVAILABLE_SUCCESS = "promoCode/GET_IS_ANY_AVAILABLE_SUCCESS",
    GET_IS_ANY_AVAILABLE_ERROR = "promoCode/GET_IS_ANY_AVAILABLE_ERROR",

    CHECK_REQUEST = "promoCode/CHECK_REQUEST",
    CHECK_SUCCESS = "promoCode/CHECK_SUCCESS",
    CHECK_ERROR = "promoCode/CHECK_ERROR",

    CLEAR_REQUEST = "promoCode/CLEAR_REQUEST",
    CLEAR_SUCCESS = "promoCode/CLEAR_SUCCESS",
    CLEAR_ERROR = "promoCode/CLEAR_ERROR"
}

interface IGetIsAnyPromoCodeAvailableRequestAction {
    type: PromoCodeActionTypes.GET_IS_ANY_AVAILABLE_REQUEST;
}

interface IGetIsAnyPromoCodeAvailableSuccessAction {
    type: PromoCodeActionTypes.GET_IS_ANY_AVAILABLE_SUCCESS;
    response: boolean;
}

interface IGetIsAnyPromoCodeAvailableErrorAction {
    type: PromoCodeActionTypes.GET_IS_ANY_AVAILABLE_ERROR;
    error: Error;
}

interface ICheckPromoCodeRequestAction {
    type: PromoCodeActionTypes.CHECK_REQUEST;
}

interface ICheckPromoCodeSuccessAction {
    type: PromoCodeActionTypes.CHECK_SUCCESS;
    response: IBookingPromoCodeApplyResultInfo;
}

interface ICheckPromoCodeErrorAction {
    type: PromoCodeActionTypes.CHECK_ERROR;
    error: Error;
}

interface IClearPromoCodeInfoRequestAction {
    type: PromoCodeActionTypes.CLEAR_REQUEST;
}

interface IClearPromoCodeInfoSuccessAction {
    type: PromoCodeActionTypes.CLEAR_SUCCESS;
    response: null;
}

interface IClearPromoCodeInfoErrorAction {
    type: PromoCodeActionTypes.CLEAR_ERROR;
    error: Error;
}

export type KnownPromoCodeActions =
    IGetIsAnyPromoCodeAvailableRequestAction |
    IGetIsAnyPromoCodeAvailableSuccessAction |
    IGetIsAnyPromoCodeAvailableErrorAction |
    ICheckPromoCodeRequestAction |
    ICheckPromoCodeSuccessAction |
    ICheckPromoCodeErrorAction |
    IClearPromoCodeInfoRequestAction |
    IClearPromoCodeInfoSuccessAction |
    IClearPromoCodeInfoErrorAction;

const promoCodeResultTypeMap = {
    [PromoCodeBackendResultType.Success]: PromoCodeResult.Accepted,
    [PromoCodeBackendResultType.NotFound]: PromoCodeResult.Incorrect,
    [PromoCodeBackendResultType.Inactive]: PromoCodeResult.NotActing,
    [PromoCodeBackendResultType.NotApplicable]: PromoCodeResult.NotActing
};

const getFrontPromoCodeApplyResult = (params: IBookingPromoCodeApplyBackendResultInfo): IBookingPromoCodeApplyResultInfo => ({
    ...params,
    result: promoCodeResultTypeMap[params.searchResultType]
});

export const getIsAnyPromoCodeAvailable = (): CustomThunkAction<boolean> => (
    // @ts-expect-error Use new Redux instead of fixing this types
    (dispatch, getState, {promoCodeApi}) => dispatch({
        type: {
            REQUEST: PromoCodeActionTypes.GET_IS_ANY_AVAILABLE_REQUEST,
            SUCCESS: PromoCodeActionTypes.GET_IS_ANY_AVAILABLE_SUCCESS,
            FAILURE: PromoCodeActionTypes.GET_IS_ANY_AVAILABLE_ERROR
        },
        asyncAction: promoCodeApi.isAnyPromoCodeAvailable()
    })
);

export const tryApplyPromoCode = (params: IBookingPromoCodeApplyRequestInfo): CustomThunkAction<IBookingPromoCodeApplyResultInfo> => (
    // @ts-expect-error Use new Redux instead of fixing this types
    (dispatch, getState, {promoCodeApi}) => dispatch({
        type: {
            REQUEST: PromoCodeActionTypes.CHECK_REQUEST,
            SUCCESS: PromoCodeActionTypes.CHECK_SUCCESS,
            FAILURE: PromoCodeActionTypes.CHECK_ERROR
        },
        asyncAction: async () => getFrontPromoCodeApplyResult(await promoCodeApi.tryApplyPromoCode(params))
    })
);

export const clearPromoCodeInfo = (): CustomThunkAction<void> => (
    // @ts-expect-error Use new Redux instead of fixing this types
    (dispatch) => dispatch({
        type: {
            REQUEST: PromoCodeActionTypes.CLEAR_REQUEST,
            SUCCESS: PromoCodeActionTypes.CLEAR_SUCCESS,
            FAILURE: PromoCodeActionTypes.CLEAR_ERROR
        },
        asyncAction: () => {}
    })
);
