import {Button, ButtonProps} from "@skbkontur/react-ui";

const PrimaryButton = (props: React.PropsWithChildren<ButtonProps>) => {
    const {children, className, ...rest} = props;

    return (
        <Button
            className={className}
            use="pay"
            size="medium"
            {...rest}
        >
            {children}
        </Button>
    );
};
PrimaryButton.displayName = "PrimaryButton";
export default PrimaryButton;
