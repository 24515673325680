import {IHourlyAccommodation} from "../../data/HourlyBooking";
import {Reducer} from "redux";
import {
    HourlyAccommodationsActionTypes,
    KnownHourlyAccommodationsAction,
} from "./hourlyAccommodationsActionCreators";

export interface IHourlyAccommodationsState {
    isLoading: boolean;
    accommodations: IHourlyAccommodation[];
    error?: Error;
}

export const defaultState: IHourlyAccommodationsState = {
    isLoading: false,
    accommodations: [],
    error: null,
};

export const hourlyObjectsAccommodationsReducer: Reducer<IHourlyAccommodationsState> = (
    state: IHourlyAccommodationsState = defaultState,
    action: KnownHourlyAccommodationsAction
) => {
    switch (action.type) {
        case HourlyAccommodationsActionTypes.GET_HOURLY_OBJECTS_ACCOMMODATIONS_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case HourlyAccommodationsActionTypes.GET_HOURLY_OBJECTS_ACCOMMODATIONS_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }

        case HourlyAccommodationsActionTypes.GET_HOURLY_OBJECTS_ACCOMMODATIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                accommodations: action.response,
            };
        }

        case HourlyAccommodationsActionTypes.CLEAR_HOURLY_OBJECTS_ACCOMMODATIONS: {
            return {
                ...state,
                accommodations: [],
            };
        }

        default:
            return state;
    }
};
