import {ICurrency} from "../../types/Currency";
import CurrencySymbol from "../Currency/CurrencySymbol";
import {useSumWithLocale} from "./useSumWithLocale";

interface ISumWithLocaleProps {
    sum: ICurrency | number;
}

export const SumWithLocale = (props: ISumWithLocaleProps) => {
    const {sum} = props;
    const localizedFloatSum = useSumWithLocale(sum);

    return (
        <span>
            {localizedFloatSum}&nbsp;<CurrencySymbol />
        </span>
    );
};
SumWithLocale.displayName = "SumWithLocale";
