import {CustomThunkAction} from "../ThunkAction";
import {IRoomCategory} from "@skbkontur/hotel-data/roomCategory";

export enum ActionTypes {
    GET_ROOM_CATEGORIES_REQUEST = "roomCategories/GET_ROOM_CATEGORIES_REQUEST",
    GET_ROOM_CATEGORIES_SUCCESS = "roomCategories/GET_ROOM_CATEGORIES_SUCCESS",
    GET_ROOM_CATEGORIES_ERROR = "roomCategories/GET_ROOM_CATEGORIES_ERROR"
}

interface IRoomCategoriesRequestAction {
    type: ActionTypes.GET_ROOM_CATEGORIES_REQUEST;
}

interface IRoomCategoriesSuccessAction {
    type: ActionTypes.GET_ROOM_CATEGORIES_SUCCESS;
    response: IRoomCategory[];
}

interface IRoomCategoriesErrorAction {
    type: ActionTypes.GET_ROOM_CATEGORIES_ERROR;
    error: Error;
}

export type KnownRoomCategoriesAction = IRoomCategoriesRequestAction | IRoomCategoriesSuccessAction | IRoomCategoriesErrorAction;

export const getRoomCategories = (): CustomThunkAction<IRoomCategory[]> => (
    // @ts-expect-error Use new Redux instead of fixing this types
    (dispatch, getState, {dailyApi}) => dispatch({
        type: {
            REQUEST: ActionTypes.GET_ROOM_CATEGORIES_REQUEST,
            SUCCESS: ActionTypes.GET_ROOM_CATEGORIES_SUCCESS,
            FAILURE: ActionTypes.GET_ROOM_CATEGORIES_ERROR
        },
        asyncAction: dailyApi.getEntities()
    })
);
