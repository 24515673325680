import {CustomThunkAction} from "../ThunkAction";
import {AvailabilitiesDailyType} from "../../data/Availability";
import {IAvailabilitiesDailySearchParams} from "../../data/SearchParams";

export enum ActionTypes {
    GET_AVAILABILITIES_REQUEST = "availabilities/GET_AVAILABILITIES_REQUEST",
    GET_AVAILABILITIES_SUCCESS = "availabilities/GET_AVAILABILITIES_SUCCESS",
    GET_AVAILABILITIES_ERROR = "availabilities/GET_AVAILABILITIES_ERROR",
}

interface IAvailabilitiesRequestAction {
    type: ActionTypes.GET_AVAILABILITIES_REQUEST;
}

interface IAvailabilitiesSuccessAction {
    type: ActionTypes.GET_AVAILABILITIES_SUCCESS;
    response: AvailabilitiesDailyType;
}

interface IAvailabilitiesErrorAction {
    type: ActionTypes.GET_AVAILABILITIES_ERROR;
    error: Error;
}

export type KnownAvailabilitiesAction =
    | IAvailabilitiesRequestAction
    | IAvailabilitiesSuccessAction
    | IAvailabilitiesErrorAction;

export const getAvailabilities = (params: IAvailabilitiesDailySearchParams): CustomThunkAction<AvailabilitiesDailyType> => (
    // @ts-expect-error Use new Redux instead of fixing this types
    (dispatch, getState, {availabilitiesApi}) => dispatch({
        type: {
            REQUEST: ActionTypes.GET_AVAILABILITIES_REQUEST,
            SUCCESS: ActionTypes.GET_AVAILABILITIES_SUCCESS,
            FAILURE: ActionTypes.GET_AVAILABILITIES_ERROR
        },
        asyncAction: availabilitiesApi.getDailyAvailabilities(params)
    })
);
