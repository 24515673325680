import styles from "./BookingRatesHotelFeatures.scss";
import {SumWithLocale} from "../SumWithLocale/SumWithLocale";
import MeasureControl from "../MeasureControl/MeasureControl";
import CounterText from "../Counter/CounterText";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import {Currency} from "../../common/helpers/Currency";
import {HotelFeatureAdditionType, IHotelFeature} from "../../data/HotelFeature";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {HotelFeaturesMap} from "../../data/Booking";

interface IBookingRatesHotelFeatureCostProps {
    hotelFeature: IHotelFeature;
    hotelFeaturesMap: HotelFeaturesMap;
    guestsCount?: number;
    updateHotelFeature: (hotelFeatureId: string, newCount: number) => void;
}

const BookingRatesHotelFeatureCost = (props: IBookingRatesHotelFeatureCostProps) => {
    const {hotelFeature, hotelFeaturesMap, guestsCount = 1, updateHotelFeature} = props;
    const {cost, type, measure, id} = hotelFeature;

    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);

    const chosenCount = hotelFeaturesMap?.[id] || 0;
    const costByGuestsCount = Currency.multiply(
        cost,
        type === HotelFeatureAdditionType.ByDayAndGuest ? guestsCount : 1
    );
    const count =  chosenCount || 1;
    const finalCost = Currency.multiply(costByGuestsCount, count);

    const handleAddChosenCount = () => {
        updateHotelFeature(id, 1);
    };

    const handleChangeCounter = (newCount: number) => {
        updateHotelFeature(id, newCount);
    };

    return (
        <div className={styles.rightBlock}>
            <div className={styles.paymentBlock}>
                <div className={styles.cost}>
                    <SumWithLocale sum={finalCost} />
                </div>
                <div className={styles.additionalInfo}>
                    {t(`hotelFeatures.additionTypes.${type}`, {
                        count,
                        guestsCount,
                        reactParams: {
                            MeasureControl: <MeasureControl measure={measure} count={count} />,
                        },
                    })}
                </div>
            </div>
            {chosenCount > 0 ? (
                <CounterText
                    minValue={0}
                    value={chosenCount}
                    onChange={handleChangeCounter}
                />
            ) : (
                <div className={styles.addButton}>
                    <PrimaryButton
                        _noPadding
                        width={145}
                        onClick={handleAddChosenCount}>
                        {t("hotelFeatures.button.add")}
                    </PrimaryButton>
                </div>
            )}
        </div>
    );
};

BookingRatesHotelFeatureCost.displayName = "BookingRatesHotelFeatureCost";
export default BookingRatesHotelFeatureCost;
