import {CustomThunkAction} from "../ThunkAction";
import {AvailabilitiesHourlyType} from "../../data/Availability";
import {IAvailabilitiesHourlySearchParams} from "../../data/SearchParams";

export enum ActionTypes {
    GET_AVAILABILITIES_HOURLY_REQUEST = "availabilities/GET_AVAILABILITIES_HOURLY_REQUEST",
    GET_AVAILABILITIES_HOURLY_SUCCESS = "availabilities/GET_AVAILABILITIES_HOURLY_SUCCESS",
    GET_AVAILABILITIES_HOURLY_ERROR = "availabilities/GET_AVAILABILITIES_HOURLY_ERROR",

    GET_AVAILABILITIES_HOURLY_TO_REQUEST = "availabilities/GET_AVAILABILITIES_HOURLY_TO_REQUEST",
    GET_AVAILABILITIES_HOURLY_TO_SUCCESS = "availabilities/GET_AVAILABILITIES_HOURLY_TO_SUCCESS",
    GET_AVAILABILITIES_HOURLY_TO_ERROR = "availabilities/GET_AVAILABILITIES_HOURLY_TO_ERROR",

    CLEAR_AVAILABILITIES_HOURLY = "availabilities/CLEAR_AVAILABILITIES_HOURLY",
}

interface IAvailabilitiesHourlyRequestAction {
    type: ActionTypes.GET_AVAILABILITIES_HOURLY_REQUEST;
}

interface IAvailabilitiesHourlyToRequestAction {
    type: ActionTypes.GET_AVAILABILITIES_HOURLY_TO_REQUEST;
}

interface IAvailabilitiesHourlySuccessAction {
    type: ActionTypes.GET_AVAILABILITIES_HOURLY_SUCCESS;
    response: AvailabilitiesHourlyType;
}

interface IAvailabilitiesHourlyToSuccessAction {
    type: ActionTypes.GET_AVAILABILITIES_HOURLY_TO_SUCCESS;
    response: AvailabilitiesHourlyType;
}

interface IAvailabilitiesHourlyErrorAction {
    type: ActionTypes.GET_AVAILABILITIES_HOURLY_ERROR;
    error: Error;
}

interface IAvailabilitiesHourlyToErrorAction {
    type: ActionTypes.GET_AVAILABILITIES_HOURLY_TO_ERROR;
    error: Error;
}

interface IAvailabilitiesHourlyClearAction {
    type: ActionTypes.CLEAR_AVAILABILITIES_HOURLY;
}

export type KnownAvailabilitiesHourlyAction =
    | IAvailabilitiesHourlyRequestAction
    | IAvailabilitiesHourlySuccessAction
    | IAvailabilitiesHourlyErrorAction
    | IAvailabilitiesHourlyClearAction
    | IAvailabilitiesHourlyToRequestAction
    | IAvailabilitiesHourlyToSuccessAction
    | IAvailabilitiesHourlyToErrorAction;

export const getAvailabilitiesHourly =
    (params: IAvailabilitiesHourlySearchParams): CustomThunkAction<AvailabilitiesHourlyType> => (
    // @ts-expect-error Use new Redux instead of fixing this types
    (dispatch, getState, {availabilitiesApi}) => (
         dispatch({
            type: {
                REQUEST: ActionTypes.GET_AVAILABILITIES_HOURLY_REQUEST,
                SUCCESS: ActionTypes.GET_AVAILABILITIES_HOURLY_SUCCESS,
                FAILURE: ActionTypes.GET_AVAILABILITIES_HOURLY_ERROR,
            },
            asyncAction: availabilitiesApi.getHourlyAvailabilities(params),
        })
    )
);

export const getAvailabilitiesHourlyTo =
    (params: IAvailabilitiesHourlySearchParams): CustomThunkAction<AvailabilitiesHourlyType> => (
    // @ts-expect-error Use new Redux instead of fixing this types
    (dispatch, getState, {availabilitiesApi}) => (
         dispatch({
            type: {
                REQUEST: ActionTypes.GET_AVAILABILITIES_HOURLY_TO_REQUEST,
                SUCCESS: ActionTypes.GET_AVAILABILITIES_HOURLY_TO_SUCCESS,
                FAILURE: ActionTypes.GET_AVAILABILITIES_HOURLY_TO_ERROR,
            },
            asyncAction: availabilitiesApi.getHourlyAvailabilities(params),
        })
    )
);

export const clearAvailabilitiesHourly = () => ({
    type: ActionTypes.CLEAR_AVAILABILITIES_HOURLY,
})
