import {BedType, IBedStructure, IRoomCategory, RoomCategoryHelper} from "@skbkontur/hotel-data/roomCategory";
import RoomCategoryCharacteristicsIcons from "./components/RoomCategoryCharacteristicsIcons";
import {Tooltip} from "@skbkontur/react-ui";
import RoomCategoryCharacteristicsHint from "./components/RoomCategoryCharacteristicsHint";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "@skbkontur/hotel-constants/TranslationNamespaces";
import {AdditionalBedType} from "@skbkontur/hotel-data/roomCategory/RoomCategory";
import styles from "./RoomCategoryCharacteristics.scss";

interface IRoomCategoryCharacteristicsProps {
    category: IRoomCategory;
    hideArea?: boolean;
    modifyImageUrl?: (url: string) => string;
    small?: boolean;
    withoutTooltip?: boolean;
}

const RoomCategoryCharacteristics = (props: IRoomCategoryCharacteristicsProps) => {
    const {category, hideArea, small, withoutTooltip, modifyImageUrl} = props;
    const {rooms, roomCategoryType, placesMin, placesMax} = category;

    const allBeds = rooms?.reduce((beds, room) => [
        ...beds,
        ...(room.beds?.filter(bed => bed.type) || [])
    ], [] as IBedStructure<BedType>[]);

    const allAdditionalBeds = rooms?.reduce((beds, room) => [
        ...beds,
        ...(room.additionalBeds?.filter(bed => bed.type) || [])
    ], [] as IBedStructure<AdditionalBedType>[]);

    const {t} = useTranslation(TranslationNamespaces.RoomCategories);

    const renderTooltip = () => (
        RoomCategoryHelper.isHostel(roomCategoryType) ? (
            <div>
                {placesMax === placesMin
                    ? t("hostelPlacesCount", {count: placesMin})
                    : t("hostelPlacesCountRange", {min: placesMin, max: placesMax})}
            </div>
        ) : (
            <RoomCategoryCharacteristicsHint
                category={category}
                beds={allBeds}
                additionalBeds={allAdditionalBeds}
                modifyImageUrl={modifyImageUrl}
            />
)
    );

    const hasSomeBeds = !!allBeds?.length || !!allAdditionalBeds?.length;
    const isHostelWithPlaces = RoomCategoryHelper.isHostel(roomCategoryType) && placesMin;

    return (
        !withoutTooltip && (hasSomeBeds || isHostelWithPlaces) ? (
            <Tooltip
                useWrapper
                pos="top left"
                render={renderTooltip}
                className={styles.tooltip}
            >
                <RoomCategoryCharacteristicsIcons
                    category={category}
                    hideArea={hideArea}
                    beds={allBeds}
                    additionalBeds={allAdditionalBeds}
                    modifyImageUrl={modifyImageUrl}
                    small={small}
                />
            </Tooltip>
        ) : (
            <RoomCategoryCharacteristicsIcons
                category={category}
                hideArea={hideArea}
                beds={allBeds}
                additionalBeds={allAdditionalBeds}
                small={small}
                modifyImageUrl={modifyImageUrl}
            />
        )
    );
};
RoomCategoryCharacteristics.displayName = "RoomCategoryCharacteristics";
export default RoomCategoryCharacteristics;
