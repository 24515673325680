import {ApiBase} from "./apiBase";
import Fetcher from "../core/Fetcher";
import {ApiSiteBase} from "./apiSiteBase";
import {IBookingCancellationInfo} from "../data/BookingCancellation";
import {IBookingAccommodation, ICreatedBooking} from "../data/Booking";

export class BookingApi extends ApiSiteBase {
    createBooking = (bookings: IBookingAccommodation[]): Promise<ICreatedBooking[]> => {
        const bookingsToSend = bookings.map(b => ({
            ...b,
            checkin: ApiBase.toQueryDate(b.checkin),
            checkout: ApiBase.toQueryDate(b.checkout)
        }));
        return Fetcher.postJSON<ICreatedBooking[]>(this.buildRequestUrl(), bookingsToSend);
    };

    getBookingCancellationInfos(bookingIds: string[]): Promise<IBookingCancellationInfo[]> {
        const queryParams = bookingIds.reduce((acc, id, i) => (
            acc + ApiBase.toQueryParams({bookingIds: id}, i !== 0)
        ), "");
        return Fetcher.get(this.buildRequestUrl([queryParams]));
    }
}

export default new BookingApi("booking");
