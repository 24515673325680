import cn from "classnames";
import styles from "./ItemWithBorder.scss";

interface IItemWithBorderProps {
    withoutBorder?: boolean;
}

const ItemWithBorder = (props: React.PropsWithChildren<IItemWithBorderProps>) => {
    const {children, withoutBorder} = props;

    return (
        <div className={cn(styles.item, {[styles.withoutBorder]: withoutBorder})}>
            {children}
        </div>
    );
};
ItemWithBorder.displayName = "ItemWithBorder";
export default ItemWithBorder;
