import styles from "./CurrencySymbol.scss";
import {useCurrencySymbol} from "./useCurrencySymbol";

const CurrencySymbol = () => {
    const currencySymbol = useCurrencySymbol();
    return (
        <div className={styles.root}>
            {currencySymbol}
        </div>
    );
};
CurrencySymbol.displayName = "CurrencySymbol";
export default CurrencySymbol;
