import {BaseAnalyticsEvents} from "./BaseAnalyticsEvents";
import {AnalyticsCategory, BookingLightboxPromoCodeAnalyticsAction} from "./Analytics";

/* eslint-disable i18next/no-literal-string */
class BookingLightboxPromoCodeAnalyticsEvents extends BaseAnalyticsEvents {
    trackShowPromoCode = () => (
        this.trackEvent(
            BookingLightboxPromoCodeAnalyticsAction.ShowPromoCode,
            "показ поля промокода"
        )
    );

    trackCheckPromoCode = () => (
        this.trackEvent(
            BookingLightboxPromoCodeAnalyticsAction.CheckPromoCode,
            "запуск проверки промокода"
        )
    );

    trackAcceptedPromoCode = () => (
        this.trackEvent(
            BookingLightboxPromoCodeAnalyticsAction.AcceptedPromoCode,
            "промокод успешно применен"
        )
    );

    trackIncorrectPromoCode = () => (
        this.trackEvent(
            BookingLightboxPromoCodeAnalyticsAction.IncorrectPromoCode,
            "промокод указан неверно"
        )
    );

    trackNotActingPromoCode = () => (
        this.trackEvent(
            BookingLightboxPromoCodeAnalyticsAction.NotActingPromoCode,
            "промокод не подходит"
        )
    );
}

export const bookingLightboxPromoCodeAnalyticsEvents = new BookingLightboxPromoCodeAnalyticsEvents(AnalyticsCategory.BookingLightboxPromoCode);
