import {IHourlyBookingAccommodation, IHourlyCreatedBooking} from "../data/HourlyBooking";
import {ApiBase} from "./apiBase";
import Fetcher from "../core/Fetcher";
import {ApiSiteBase} from "./apiSiteBase";
import {IBookingCancellationInfo} from "../data/BookingCancellation";

export class HourlyBookingApi extends ApiSiteBase {
    createHourlyBooking = (bookings: IHourlyBookingAccommodation[]): Promise<IHourlyCreatedBooking[]> => {
        return Fetcher.postJSON<IHourlyCreatedBooking[]>(this.buildRequestUrl(), bookings);
    };

    getHourlyBookingCancellationInfos(bookingIds: string[]): Promise<IBookingCancellationInfo[]> {
        const queryParams = bookingIds.reduce(
            (acc, id, i) => acc + ApiBase.toQueryParams({bookingIds: id}, i !== 0),
            ""
        );
        return Fetcher.get(this.buildRequestUrl([queryParams]));
    }
}

export default new HourlyBookingApi("hourly-booking");
