export enum BookingLightboxStep {
    Initial = "initial",
    NotAvailable = "notAvailable",
    SelectAccommodations = "selectAccommodations",
    SelectRates = "selectRates",
    FinishOrder = "finishOrder"
}

export const isNotAvailableStep = (step: BookingLightboxStep) => (
    step === BookingLightboxStep.NotAvailable
);

export const isSelectAccommodationsStep = (step: BookingLightboxStep) => (
    step === BookingLightboxStep.SelectAccommodations
);

export const isShowRatesStep = (step: BookingLightboxStep) => (
    step === BookingLightboxStep.FinishOrder || step === BookingLightboxStep.SelectRates
);

export const isReadyToOrder = (step: BookingLightboxStep) => (
    step === BookingLightboxStep.FinishOrder
);

export interface IBookingLightboxStepsParams {
    roomCategoryId?: string;
    scrollToStart?: boolean;
}

export interface IBookingLightboxStepsContext {
    goToStep: (step: BookingLightboxStep, params?: IBookingLightboxStepsParams) => void;
    step: BookingLightboxStep;
    params?: IBookingLightboxStepsParams;
}

export const BookingLightboxStepsContext = React.createContext({} as IBookingLightboxStepsContext);
