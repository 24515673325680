import {Reducer} from "redux";
import {ActionTypes, KnownRatesAction} from "./hourlyRatesActionCreators";
import {IRate} from "../../data/Rate";

export interface IHourlyRatesState {
    isLoading: boolean;
    hourlyRates: IRate[];
    error?: Error;
}

export const defaultState: IHourlyRatesState = {
    isLoading: false,
    hourlyRates: [],
    error: null,
};

export const hourlyRatesReducer: Reducer<IHourlyRatesState> = (
    state: IHourlyRatesState = defaultState,
    action: KnownRatesAction
) => {
    switch (action.type) {
        case ActionTypes.GET_HOURLY_RATES_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case ActionTypes.GET_HOURLY_RATES_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }

        case ActionTypes.GET_HOURLY_RATES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                hourlyRates: action.response,
            };
        }

        default:
            return state;
    }
};
