import {IHotelInfo} from "../../data/HotelInfo";
import {CustomThunkAction} from "../ThunkAction";

export enum ActionTypes {
    GET_HOTEL_INFO_REQUEST = "hotelInfoStore/GET_HOTEL_INFO_REQUEST",
    GET_HOTEL_INFO_SUCCESS = "hotelInfoStore/GET_HOTEL_INFO_SUCCESS",
    GET_HOTEL_INFO_ERROR = "hotelInfoStore/GET_HOTEL_INFO_ERROR"
}

interface IGetInfoRequestAction {
    type: ActionTypes.GET_HOTEL_INFO_REQUEST;
}

interface IGetInfoSuccessAction {
    type: ActionTypes.GET_HOTEL_INFO_SUCCESS;
    response: IHotelInfo;
}

interface IGetInfoErrorAction {
    type: ActionTypes.GET_HOTEL_INFO_ERROR;
    error: Error;
}

export type KnownAction = IGetInfoRequestAction | IGetInfoSuccessAction | IGetInfoErrorAction;

export const getHotelInfo = (): CustomThunkAction<IHotelInfo> => (
    // @ts-expect-error Use new Redux instead of fixing this types
    (dispatch, getState, {hotelInfoApi}) => dispatch({
        type: {
            REQUEST: ActionTypes.GET_HOTEL_INFO_REQUEST,
            SUCCESS: ActionTypes.GET_HOTEL_INFO_SUCCESS,
            FAILURE: ActionTypes.GET_HOTEL_INFO_ERROR
        },
        asyncAction: hotelInfoApi.getHotelInfo()
    })
);
