import {ICalendarDatesPeriod} from "../Calendar/Calendar";
import DateHelper from "../../helpers/DateHelper";
import {FullDateDayFirstString} from "../Date";
import {DateFormats} from "../../types/DateFormats";

export class RoomAvailabilityCalendarHelper {
    private static getMonthFirstDate = (date: FullDateDayFirstString, offsetInMinutes?: number): FullDateDayFirstString => {
        const currentDate = DateHelper.getTodayWithTimezone(DateFormats.FullDateDayFirst, offsetInMinutes);
        const monthIsCurrent = DateHelper.isSameMonth(date, currentDate, DateFormats.FullDateDayFirst);

        return monthIsCurrent ? currentDate : DateHelper.getFirstMonthDate(date, DateFormats.FullDateDayFirst);
    };

    static getDatesForSearch = (startDate: FullDateDayFirstString, offsetInMinutes?: number): ICalendarDatesPeriod => {
        const calculatedFromDate = this.getMonthFirstDate(startDate, offsetInMinutes);
        const nextMonthDate = DateHelper.addMonthsToDate(1, calculatedFromDate, DateFormats.FullDateDayFirst);
        const calculatedToDate = DateHelper.getLastMonthDate(nextMonthDate, DateFormats.FullDateDayFirst);

        return {
            fromDate: calculatedFromDate,
            toDate: calculatedToDate
        };
    };
}
