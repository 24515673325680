import {IBookingAccommodation} from "../../../data/Booking";
import {IAccommodationUpdateParams} from "../AccommodationsContext";
import {
    IAccommodationsMapParams,
    IAccommodationsPricesMap,
    ISelectedAccommodationBooking,
    ISelectedAccommodationsMap,
} from "../AccommodationsMaps";
import {ICurrency} from "../../../types/Currency";
import {Currency} from "../../../common/helpers/Currency";
import {AccommodationsPricesHelper} from "./AccommodationsPricesHelper";
import {HotelFeatureAdditionType, IHotelFeature} from "../../../data/HotelFeature";
import {createMapBy} from "../../../common/helpers/arrayUtils";
import {OccupancyHelper} from "../../../helpers/OccupancyHelper";

// TODO Написать тесты

export class SelectedAccommodationsHelper {
    static toBookingAccommodations = (selectedAccommodations: ISelectedAccommodationsMap): IBookingAccommodation[] =>
        Object.keys(selectedAccommodations).reduce((acc, roomCategoryId) => {
            selectedAccommodations[roomCategoryId].forEach(occupancyItem => {
                occupancyItem.value.forEach((accommodation: IBookingAccommodation) => {
                    acc.push({
                        ...accommodation,
                        roomCategoryId,
                    });
                });
            }, acc);
            return acc;
        }, [] as IBookingAccommodation[]);

    static toSelectedAccommodationBookings = (selectedAccommodations: ISelectedAccommodationsMap): ISelectedAccommodationBooking[] =>
        Object.keys(selectedAccommodations).reduce((acc, roomCategoryId) => {
            selectedAccommodations[roomCategoryId].forEach(occupancyItem => {
                occupancyItem.value.forEach((accommodation: IBookingAccommodation, index: number) => {
                    acc.push({
                        accommodation,
                        params: {
                            roomCategoryId,
                            mainOccupancy: occupancyItem.mainOccupancy,
                            additionalOccupancy: occupancyItem.additionalOccupancy,
                            index,
                        },
                    });
                });
            });
            return acc;
        }, [] as ISelectedAccommodationBooking[]);

    static addAccommodation = (
        selectedAccommodations: ISelectedAccommodationsMap,
        params: IAccommodationUpdateParams
    ): ISelectedAccommodationsMap => {
        const {roomCategoryId, mainOccupancy, additionalOccupancy, accommodation} = params;
        const roomCategoryOccupancies = [...(selectedAccommodations[roomCategoryId] || [])];
        const occupancyAccommodations = OccupancyHelper.findItemValue(roomCategoryOccupancies, mainOccupancy, additionalOccupancy);

        let updatedRoomCategoryOccupancies;
        if (occupancyAccommodations) {
            updatedRoomCategoryOccupancies = roomCategoryOccupancies.map(item => ({
                ...item,
                value: [
                    ...item.value,
                    ...(mainOccupancy === item.mainOccupancy && additionalOccupancy === item.additionalOccupancy ? [accommodation] : [])
                ]
            }))
        } else {
            updatedRoomCategoryOccupancies = [
                ...roomCategoryOccupancies,
                {
                    mainOccupancy,
                    additionalOccupancy,
                    value: [accommodation]
                }
            ]
        }

        return {
            ...selectedAccommodations,
            [roomCategoryId]: updatedRoomCategoryOccupancies,
        };
    };

    static removeAccommodation = (
        selectedAccommodations: ISelectedAccommodationsMap,
        params: IAccommodationsMapParams
    ): ISelectedAccommodationsMap => {
        const {roomCategoryId, mainOccupancy, additionalOccupancy, index} = params;
        const roomCategoryOccupancies = selectedAccommodations[roomCategoryId] || [];
        return {
            ...selectedAccommodations,
            [roomCategoryId]: roomCategoryOccupancies.map(occupancyItem => ({
                ...occupancyItem,
                value: occupancyItem.mainOccupancy === mainOccupancy && occupancyItem.additionalOccupancy === additionalOccupancy
                    ? occupancyItem.value.filter((_, accommodationIndex) => accommodationIndex !== index)
                    : occupancyItem.value
            })),
        };
    };

    static updateAccommodation = (
        selectedAccommodations: ISelectedAccommodationsMap,
        params: IAccommodationUpdateParams
    ): ISelectedAccommodationsMap => {
        const {roomCategoryId, mainOccupancy, additionalOccupancy, index, accommodation: newAccommodation} = params;
        const roomCategoryOccupancies = selectedAccommodations[roomCategoryId] || [];

        return {
            ...selectedAccommodations,
            [roomCategoryId]: roomCategoryOccupancies.map(occupancyItem => ({
                ...occupancyItem,
                value: occupancyItem.mainOccupancy === mainOccupancy && occupancyItem.additionalOccupancy === additionalOccupancy
                    ? occupancyItem.value.map((accommodation, accommodationIndex) => accommodationIndex === index ? newAccommodation : accommodation)
                    : occupancyItem.value
            })),
        };
    };

    static getBusyAccommodationsCount = (selectedAccommodations: ISelectedAccommodationsMap, roomCategoryId: string): number => {
        const roomCategoryOccupancies = selectedAccommodations[roomCategoryId];
        if (!roomCategoryOccupancies) {
            return 0;
        }
        return roomCategoryOccupancies.reduce((acc, {mainOccupancy, additionalOccupancy}) => {
            const occupancyAccommodations = OccupancyHelper.findItemValue(roomCategoryOccupancies, mainOccupancy, additionalOccupancy) || [];
            return acc + occupancyAccommodations.length;
        }, 0);
    };

    static getMinRatesSum = (selectedAccommodations: ISelectedAccommodationsMap, pricesMap: IAccommodationsPricesMap): ICurrency =>
        Object.keys(selectedAccommodations).reduce(
            (sum, roomCategoryId) =>
                selectedAccommodations[roomCategoryId].reduce((sum, {mainOccupancy, additionalOccupancy}) => {
                    const accommodations = OccupancyHelper.findItemValue(selectedAccommodations[roomCategoryId], mainOccupancy, additionalOccupancy);
                    const accommodationsCount = accommodations.length;
                    if (!accommodationsCount) return sum;

                    const ratesMap = OccupancyHelper.findItemValue(pricesMap?.[roomCategoryId] || [], mainOccupancy, additionalOccupancy);
                    const minRatesSum = AccommodationsPricesHelper.getMinRatesPrice(ratesMap);
                    return Currency.sum([sum, Currency.multiply(minRatesSum, accommodationsCount)]);
                }, sum),
            Currency.zero()
        );

    static getTotalPrice = (
        bookings: ISelectedAccommodationBooking[],
        pricesMap: IAccommodationsPricesMap
    ): ICurrency =>
        Currency.sum(
            bookings.map(({params, accommodation}) => {
                const {rateId} = accommodation;
                const {roomCategoryId, mainOccupancy, additionalOccupancy} = params;
                return OccupancyHelper.findItemValue(pricesMap[roomCategoryId], mainOccupancy, additionalOccupancy)[rateId].price;
            })
        );

    static getHotelFeaturesPrice = (
        selectedBookings: ISelectedAccommodationBooking[],
        hotelFeatures: IHotelFeature[]
    ): ICurrency => {
        const hotelFeaturesMap = createMapBy(hotelFeatures, hf => hf.id);
        return selectedBookings.reduce((acc, booking) => {
            const bookingFeaturesIds = Object.keys(booking.accommodation.bookingFeatures);
            const bookingFeaturesCost = bookingFeaturesIds.map(bookingFeatureId => {
                const hotelFeatureCount = booking.accommodation.bookingFeatures[bookingFeatureId];
                const {cost, type} = hotelFeaturesMap[bookingFeatureId];

                const guestsCount = booking.accommodation.adultsCount + (booking.accommodation.childrenCount || 0);
                const costByGuestsCount = Currency.multiply(
                    cost,
                    type === HotelFeatureAdditionType.ByDayAndGuest ? guestsCount : 1
                );

                return Currency.multiply(costByGuestsCount, hotelFeatureCount);
            });

            return Currency.sum([acc, ...bookingFeaturesCost]);
        }, Currency.zero());
    };

    static getTotalPrepayment = (
        bookings: ISelectedAccommodationBooking[],
        pricesMap: IAccommodationsPricesMap
    ): ICurrency =>
        Currency.sum(
            bookings.map(({params, accommodation}) => {
                const {rateId} = accommodation;
                const {roomCategoryId, mainOccupancy, additionalOccupancy} = params;
                return OccupancyHelper.findItemValue(pricesMap[roomCategoryId], mainOccupancy, additionalOccupancy)[rateId].prepaymentSum;
            })
        );

    static isRatesFilled = (bookings: IBookingAccommodation[]) => {
        return bookings.every(b => !!b.rateId);
    };
}
