import {ValidationContainer} from "@skbkontur/react-ui-validations";
import BookingAccommodationsPanel from "./Panel/BookingAccommodationsPanel";
import {Gapped} from "@skbkontur/react-ui";
import BookingRecommendedResults from "./Results/BookingRecommendedResults";
import BookingSmallerResults from "./Results/BookingSmallerResults";
import BookingMainResults from "./Results/BookingMainResults";
import {FREE_CHILDREN_COUNT} from "../../../../data/HotelInfo";
import {RoomCategorySearchParamsContext} from "../../../SearchParams/SearchParamsContext";
import {useSelector} from "react-redux";
import {IAppState} from "../../../../store/AppState";
import BookingRoomCategoryResults from "./Results/BookingRoomCategoryResults";
import BookingLightboxContainer from "../BookingLightboxContainer";
import {useRoomCategory} from "../../../../hooks/useRoomCategory";
import {RoomCategoryHelper} from "@skbkontur/hotel-data/roomCategory";
import {useMount} from "@skbkontur/hotel-hooks/react";

const BookingAccommodations = () => {
    const {roomCategories} = useSelector((state: IAppState) => state.roomCategories);
    const {params} = React.useContext(RoomCategorySearchParamsContext);
    const {adultsCount, kidsCount, roomCategoryId} = params;

    const roomCategory = useRoomCategory(roomCategoryId);

    const maxCapacity = roomCategoryId
        ? RoomCategoryHelper.getGuestsCount(roomCategory)
        : Math.max(...roomCategories.map(RoomCategoryHelper.getGuestsCount));

    const requestedPlacesCount = adultsCount + Math.max(kidsCount - FREE_CHILDREN_COUNT, 0);
    const isExceedMaxCapacity = maxCapacity < requestedPlacesCount;
    const targetPlacesCount = isExceedMaxCapacity && roomCategoryId
        ? maxCapacity
        : requestedPlacesCount;

    const [isValid, setIsValid] = React.useState<boolean>();
    const validationRef = React.useRef<ValidationContainer>();

    useMount(() => {
        validationRef.current.validate();
    });

    return (
        <div>
            <ValidationContainer onValidationUpdated={setIsValid} ref={validationRef}>
                <Gapped gap={20} vertical>
                    {roomCategoryId ? (
                        <BookingRoomCategoryResults
                            targetPlacesCount={targetPlacesCount}
                            isExceedMaxCapacity={isExceedMaxCapacity}
                        />
                    ) : (
                        <BookingMainResults
                            targetPlacesCount={targetPlacesCount}
                            isExceedMaxCapacity={isExceedMaxCapacity}
                        />
                    )}
                    <BookingSmallerResults
                        targetPlacesCount={targetPlacesCount}
                        isExceedMaxCapacity={isExceedMaxCapacity}
                    />
                    <BookingRecommendedResults />
                </Gapped>
            </ValidationContainer>
            <BookingLightboxContainer>
                <BookingAccommodationsPanel isValid={isValid} />
            </BookingLightboxContainer>
        </div>
    );
};
BookingAccommodations.displayName = "BookingAccommodations";
export default React.memo(BookingAccommodations);
