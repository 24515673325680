import {createMapBy} from "../../../common/helpers/arrayUtils";
import {IAccommodationsPricesMap} from "../../Accommodations/AccommodationsMaps";
import {AccommodationsPricesHelper} from "../../Accommodations/helpers/AccommodationsPricesHelper";
import {Currency} from "../../../common/helpers/Currency";
import {IAccommodation, IOccupancy, IRoomCategoryAccommodation} from "../../../data/Accommodation";
import {IRoomCategory, RoomCategoryHelper} from "@skbkontur/hotel-data/roomCategory";
import {OccupancyHelper} from "../../../helpers/OccupancyHelper";
import {last} from "lodash";
import {Compare} from "../../../helpers/CompareHelper";

export interface IAccommodationSortParams extends IOccupancy {
    roomCategoryId: string;
}

export class BookingLightboxDataHelper {
    static byRoomCategoryOrder = <TItem>(
        roomCategories: IRoomCategory[],
        getRoomCategoryId: (item: TItem) => string
    ) => {
        const roomCategoriesByIdMap = createMapBy(roomCategories, rc => rc.id);
        return (item: TItem) => {
            const roomCategoryId = getRoomCategoryId(item);
            return roomCategoriesByIdMap[roomCategoryId]?.order;
        };
    };

    static getSmallerAccommodations = (
        accommodations: IRoomCategoryAccommodation[],
        requestedPlacesCount: number,
        roomCategories: IRoomCategory[]
    ): IRoomCategoryAccommodation[] => (
        this.getAccommodationsByPlacesFilter(
            accommodations,
            requestedPlacesCount,
            roomCategories,
            (occupancyPlaces, requestedPlaces) => occupancyPlaces < requestedPlaces
        )
    );

    static getEqualOrBiggerAccommodations = (
        accommodations: IRoomCategoryAccommodation[],
        requestedPlacesCount: number,
        roomCategories: IRoomCategory[]
    ): IRoomCategoryAccommodation[] => (
        this.getAccommodationsByPlacesFilter(
            accommodations,
            requestedPlacesCount,
            roomCategories,
            (occupancyPlaces, requestedPlaces) => occupancyPlaces >= requestedPlaces
        )
    );

    private static getAccommodationsByPlacesFilter = (
        accommodations: IRoomCategoryAccommodation[],
        requestedPlacesCount: number, roomCategories: IRoomCategory[],
        placesFilter: (occupancyPlaces: number, requestedPlaces: number) => boolean
    ): IRoomCategoryAccommodation[] => {

        const roomCategoryMap = createMapBy(roomCategories, rc => rc.id);
        return accommodations.reduce((acc, item) => {
            const {accommodations, roomCategoryId} = item;
            const roomCategory = roomCategoryMap[roomCategoryId];

            const smallerItemAccommodations = accommodations.filter(({mainOccupancy, additionalOccupancy}) => {
                const placesCount = RoomCategoryHelper.getPlacesCountByOccupancy(mainOccupancy, roomCategory) + additionalOccupancy;
                return placesFilter(placesCount, requestedPlacesCount);
            })

            if (smallerItemAccommodations.length)
                acc.push({...item, accommodations: smallerItemAccommodations});

            return acc;
        }, [] as IRoomCategoryAccommodation[])
    };

    static getSortItemsByPlacesCountASC = (roomCategories: IRoomCategory[]): AccommodationItemsSortType => (
        this.getSortItemsByPlacesCount(roomCategories, 1)
    );

    static getSortItemsByPlacesCountDESC = (roomCategories: IRoomCategory[]): AccommodationItemsSortType => (
        this.getSortItemsByPlacesCount(roomCategories, -1)
    );

    private static getSortItemsByPlacesCount = (
        roomCategories: IRoomCategory[],
        direction: -1 | 1
    ): AccommodationItemsSortType => {
        const roomCategoryMap = createMapBy(roomCategories, rc => rc.id);
        return (
            (roomCategoryId: string) => ({mainOccupancy, additionalOccupancy}: IAccommodation) => (
                direction * (RoomCategoryHelper.getPlacesCountByOccupancy(mainOccupancy, roomCategoryMap[roomCategoryId]) + additionalOccupancy)
            )
        );
    };

    static getAccommodationsSortParams = (targetPlacesCount: number) => (
        (accommodation: IRoomCategoryAccommodation): IAccommodationSortParams => {
            const {roomCategoryId, accommodations} = accommodation;

            const occupanciesFromSmall = [...accommodations].sort(Compare.occupancies);
            const fullOccupancy = last(occupanciesFromSmall);

            const targetOccupancy =  occupanciesFromSmall.find(aWP => aWP.mainOccupancy + aWP.additionalOccupancy === targetPlacesCount)
                || occupanciesFromSmall.find(aWP => aWP.mainOccupancy + aWP.additionalOccupancy > targetPlacesCount)
                || fullOccupancy;
            return {
                roomCategoryId,
                mainOccupancy: targetOccupancy.mainOccupancy,
                additionalOccupancy: targetOccupancy.additionalOccupancy,
            };
        }
    );

    static byPlacesCountASC = <TItem>(
        roomCategories: IRoomCategory[],
        getParams: (item: TItem) => IAccommodationSortParams
    ) => (
        this.byPlacesCount(roomCategories, getParams, 1)
    );

    static byPlacesCountDESC = <TItem>(
        roomCategories: IRoomCategory[],
        getParams: (item: TItem) => IAccommodationSortParams
    ) => (
        this.byPlacesCount(roomCategories, getParams, -1)
    );

    private static byPlacesCount = <TItem>(
        roomCategories: IRoomCategory[],
        getParams: (item: TItem) => IAccommodationSortParams,
        direction: 1 | -1
    ) => {
        const roomCategoriesByIdMap = createMapBy(roomCategories, rc => rc.id);
        return (item: TItem) => {
            const {roomCategoryId, mainOccupancy, additionalOccupancy} = getParams(item);
            const roomCategory = roomCategoriesByIdMap[roomCategoryId];
            const placesCount = RoomCategoryHelper.getPlacesCountByOccupancy(mainOccupancy, roomCategory) + additionalOccupancy;
            return direction * placesCount;
        };
    };

    static byMinRatePrice = <TItem>(
        accommodationsPricesMap: IAccommodationsPricesMap,
        getParams: (item: TItem) => IAccommodationSortParams
    ) => (
        (item: TItem): number => {
            const {roomCategoryId, mainOccupancy, additionalOccupancy} = getParams(item);
            const ratesMap = OccupancyHelper.findItemValue(accommodationsPricesMap?.[roomCategoryId] || [], mainOccupancy, additionalOccupancy) || {};
            const minRatePrice = AccommodationsPricesHelper.getMinRatesPrice(ratesMap);
            return Currency.getFloatValue(minRatePrice);
        }
    );
}

export type AccommodationItemsSortType = (roomCategoryId: string) => (item: IAccommodation) => number