import {BaseAnalyticsEvents} from "./BaseAnalyticsEvents";
import {AnalyticsCategory, BookingLightboxOccupancyAnalyticsAction} from "./Analytics";

/* eslint-disable i18next/no-literal-string */
class BookingLightboxOccupancyAnalyticsEvents extends BaseAnalyticsEvents {
    trackOccupancyCountChange = (count: number) => (
        this.trackEvent(
            BookingLightboxOccupancyAnalyticsAction.ChangeOccupancyCount,
            "изменение количества номеров", {count}
        )
    );

    trackAdultsCountChange = (adultsCount: number) => (
        this.trackEvent(
            BookingLightboxOccupancyAnalyticsAction.ChangeAdultsCount,
            "изменение количества взрослых", {adultsCount}
        )
    );

    trackChildrenCountChange = (childrenCount: number) => (
        this.trackEvent(
            BookingLightboxOccupancyAnalyticsAction.ChangeChildrenCount,
            "изменение количества детей", {childrenCount}
        )
    );

    trackGoToRates = (occupanciesCount: number) => (
        this.trackEvent(
            BookingLightboxOccupancyAnalyticsAction.GoToRates,
            "переход к этапу выбора тарифа", {occupanciesCount}
        )
    );
}

export const bookingLightboxOccupancyAnalyticsEvents = new BookingLightboxOccupancyAnalyticsEvents(AnalyticsCategory.BookingLightboxOccupancy);
