import {Reducer} from "redux";
import {ActionTypes, KnownRatesAction} from "./ratesActionCreators";
import {IRate} from "../../data/Rate";

export interface IRatesState {
    isLoading: boolean;
    rates: IRate[];
    error?: Error;
}

export const defaultState: IRatesState = {
    isLoading: false,
    rates: [],
    error: null
};

export const ratesReducer: Reducer<IRatesState> = (state: IRatesState = defaultState, action: KnownRatesAction) => {
    switch (action.type) {
        case ActionTypes.GET_RATES_REQUEST: {
            return {
                ...state,
                isLoading: true
            };
        }

        case ActionTypes.GET_RATES_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }

        case ActionTypes.GET_RATES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                rates: action.response
            };
        }

        default:
            return state;
    }
};
