import {BaseAnalyticsEvents} from "./BaseAnalyticsEvents";
import {AnalyticsCategory, BookingLightboxOrderAnalyticsAction} from "./Analytics";
import {PaymentSystem} from "../data/Payment";

/* eslint-disable i18next/no-literal-string */
class BookingLightboxOrderAnalyticsEvents extends BaseAnalyticsEvents {
    trackFormFieldFill = (fieldName: string, fieldValue: string) => (
        this.trackEvent(
            BookingLightboxOrderAnalyticsAction.FillFormField,
            `заполнение поля ${fieldName}`, {
                fieldName, fieldValue
            }
        )
    );

    trackPaymentSystemChoose = (paymentSystem: PaymentSystem) => (
        this.trackEvent(
            BookingLightboxOrderAnalyticsAction.ChoosePaymentSystem,
            "выбор способа оплаты", {
                paymentSystem
            }
        )
    );

    trackCashbackChoose = (isChecked: boolean) => (
        this.trackEvent(
            BookingLightboxOrderAnalyticsAction.ChooseCashback,
            "клик по галке оплаты по кэшбеку", {
                isChecked
            }
        )
    );

    trackOurRequisitesLinkClick = () => (
        this.trackEvent(
            BookingLightboxOrderAnalyticsAction.ClickOnOurRequisitesLink,
            "клик на ссылку \"Наши реквизиты\""
        )
    );

    trackShowPaymentSystemInfo = () => (
        this.trackEvent(
            BookingLightboxOrderAnalyticsAction.ShowPaymentSystemInfo,
            "наведение мышки на вопрос рядом с лого оплаты"
        )
    );
}

export const bookingLightboxOrderAnalyticsEvents = new BookingLightboxOrderAnalyticsEvents(AnalyticsCategory.BookingLightboxOrder);
