import {RadioGroup} from "@skbkontur/react-ui";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import {PaymentSystem} from "../../../data/Payment";
import {DeviceContext} from "../../../providers/Device/DeviceContext";
import {bookingLightboxOrderAnalyticsEvents} from "../../../analytics/bookingLightboxOrderAnalyticsEvents";

interface IBookingPaymentSystemRadioGroupProps {
    isLoading: boolean;
    onChange: (paymentSystem: PaymentSystem) => void;
    paymentSystem: PaymentSystem;
}

export const BookingPaymentSystemRadioGroup = (props: IBookingPaymentSystemRadioGroupProps) => {
    const {isLoading, onChange, paymentSystem} = props;

    const {isMobileMode} = React.useContext(DeviceContext);
    const {t} = useTranslation(TranslationNamespaces.BookingModule);

    const paymentSystemItems: [PaymentSystem, React.ReactNode][] = [
        [
            PaymentSystem.TBankSbp,
            isMobileMode ? t("payWithDetailed.tBankSbpMobile") : t("payWithDetailed.tBankSbp")
        ],
        [
            PaymentSystem.YandexCheckout,
            t("payWithDetailed.yandexCheckout")
        ]
    ];

    const handleChange = (paymentSystem: PaymentSystem) => {
        bookingLightboxOrderAnalyticsEvents.trackPaymentSystemChoose(paymentSystem);
        onChange(paymentSystem);
    };

    return (
        <RadioGroup
            items={paymentSystemItems}
            defaultValue={paymentSystem}
            onValueChange={handleChange}
            disabled={isLoading}
        />
    );
};

BookingPaymentSystemRadioGroup.displayName = "BookingPaymentSystemRadioGroup";
