import {Input} from "@skbkontur/react-ui";
import OrderFormRow from "../Row/OrderFormRow";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import {useTranslation} from "@skbkontur/i18n";
import {BookingOrderContext} from "../../BookingOrder/BookingOrderContext";
import {createValidationInfo} from "../../../forms/ValidationInfo";
import {validateEmail} from "../../../helpers/ValidateEmail";
import {IBookingOrderState} from "../../../data/BookingOrder";
import {FormFieldName, useTrackFormFieldFill} from "../../../hooks/useTrackFormFieldFill";

const OrderFormEmail = () => {
    const {t, tcn} = useTranslation(TranslationNamespaces.BookingLightbox);

    const {
        setForm,
        form: {customer},
    } = React.useContext(BookingOrderContext);
    const {email} = customer;

    const getValidation = () => {
        if (!email) return createValidationInfo(tcn("enterEmail"));
        if (!validateEmail(email)) return createValidationInfo(tcn("checkEmail"));
    };

    const handleChange = (email: string) => {
        setForm((value: IBookingOrderState) => ({
            ...value,
            customer: {...value.customer, email},
        }));
    };

    const onBlur = useTrackFormFieldFill(FormFieldName.Email);

    return (
        <OrderFormRow caption={t("form.Other.email")} validationInfo={getValidation()} required>
            <Input width="100%" size="medium" onValueChange={handleChange} onBlur={onBlur} />
        </OrderFormRow>
    );
};
OrderFormEmail.displayName = "OrderFormEmail";
export default OrderFormEmail;
